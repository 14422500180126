import React from "react";
import {
  //
  AutocompleteInput,
  Datagrid,
  DateField,
  DateInput,
  Filter,
  List,
  ReferenceInput,
  ReferenceField,
  SelectInput,
  TextField,
  TextInput,
} from "react-admin";
import BookmarksOutlinedIcon from "@material-ui/icons/BookmarksOutlined";
// Local
import { clearFilterButton } from "../../components";

export const AppraisalIcon = BookmarksOutlinedIcon;

const Filters = props => (
  <Filter {...props}>
    <ReferenceInput source="dealerId" reference="dealers" alwaysOn>
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      source="appraisalTypeId"
      reference="lists/appraisal_types"
      alwaysOn
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      source="campaignId"
      reference="dealer_campaigns"
      filterToQuery={searchText => ({ name: searchText })}
      alwaysOn
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <TextInput source="vin" alwaysOn />
    <DateInput source="createdAt_gte" label="From date" />
    <DateInput source="createdAt_lte" label="To date" />
    <TextInput source="firstName" />
    <TextInput source="lastName" />
    <TextInput source="email" />
    {clearFilterButton()}
  </Filter>
);

export const AppraisalList = props => (
  <List
    {...props}
    filters={<Filters />}
    perPage={25}
    sort={{ field: "id", order: "DESC" }}
  >
    <Datagrid rowClick="show">
      <TextField source="id" />
      <ReferenceField
        source="appraisalTypeId"
        reference="lists/appraisal_types"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="vin" />
      <TextField source="year" />
      <TextField source="make" />
      <TextField source="model" />
      <TextField source="trim" />
      <TextField source="email" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <DateField source="createdAt" showTime />
      {/* <ReferenceField source="statusId" reference="lists/saas_lead_statuses">
        <TextField source="name" />
      </ReferenceField> */}
    </Datagrid>
  </List>
);
