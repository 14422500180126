import React from "react";
import { Box } from "@material-ui/core";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import ReplayIcon from "@material-ui/icons/Replay";
import {
  Button,
  ChipField,
  DateField,
  EditButton,
  EmailField,
  FunctionField,
  NumberField,
  ReferenceArrayField,
  // ReferenceField,
  SingleFieldList,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
// Local
import { BoolNumField } from "../../components";
import { QRCodeImg } from "../../components/img/QRCodeImg";
import { REACT_APP_API_URL } from "../../config";
import { fetchJson } from "../../react-admin";

const EditToolbar = props => {
  const { basePath, data /* , resource */ } = props;
  const notify = useNotify();
  const refresh = useRefresh();
  const onClickSendInvitation = React.useCallback(
    /** @param {React.SyntheticEvent<HTMLElement>} e */
    async e => {
      e.stopPropagation();
      const addMessage = data.emailConfirmed
        ? " \n\nNOTE: This user email has already been confirmed."
        : "";
      if (!window.confirm("Send email now?" + addMessage)) {
        return;
      }
      const ccEmail = window.prompt("CC email:", "");
      const endpointUrl = `${REACT_APP_API_URL}/dealer_users/${data.id}/send-invitation`;
      await fetchJson(endpointUrl, {
        method: "POST",
        body: JSON.stringify({
          ccEmail,
        }),
      })
        .then(() => {
          alert("Invitation sent.");
        })
        .catch(() => {
          alert("There was an error sending the email.\n\nPlease try again.");
        });
    },
    [data],
  );
  const onClickSendResetPassword = React.useCallback(
    /** @param {React.SyntheticEvent<HTMLElement>} e */
    async e => {
      e.stopPropagation();
      const addMessage = data.emailConfirmed
        ? " \n\nNOTE: This user email has already been confirmed."
        : "";
      if (!window.confirm("Send email now?" + addMessage)) {
        return;
      }
      const ccEmail = window.prompt("CC email:", "");
      const endpointUrl = `${REACT_APP_API_URL}/dealer_users/${data.id}/send-password-reset`;
      await fetchJson(endpointUrl, {
        method: "POST",
        body: JSON.stringify({
          ccEmail,
        }),
      })
        .then(() => {
          alert("Reset Password email sent.");
        })
        .catch(() => {
          alert("There was an error sending the email.\n\nPlease try again.");
        });
    },
    [data],
  );
  const onClickResetPassword = React.useCallback(
    /** @param {React.SyntheticEvent<HTMLElement>} e */
    async e => {
      e.stopPropagation();
      const help =
        "New password must be at least 8 characters long and should contain " +
        "at least one number.";
      const newPassword = window.prompt(`${help}\n\nNew password:`, "");
      if (!newPassword) {
        return;
      }
      if (newPassword.length < 8 || !newPassword.match(/\d/g)) {
        window.alert(`Invalid password.\n\n${help}`);
        return;
      }
      const endpointUrl = `${REACT_APP_API_URL}/dealer_users/${data.id}/reset-password`;
      await fetchJson(endpointUrl, {
        method: "POST",
        body: JSON.stringify({
          newPassword,
        }),
      })
        .then(() => {
          alert("Password reset.");
        })
        .catch(() => {
          alert(
            "There was an error resetting the password.\n\nPlease try again.",
          );
        });
    },
    [data],
  );
  const onClickRestoreUser = React.useCallback(
    /** @param {React.SyntheticEvent<HTMLElement>} e */
    async e => {
      e.stopPropagation();

      if (!window.confirm("Are you sure?")) {
        return;
      }

      const endpointUrl = `${REACT_APP_API_URL}/dealer_users/${data.id}/restore-user`;
      await fetchJson(endpointUrl, {
        method: "POST",
        body: JSON.stringify({}),
      })
        .then(() => {
          notify("User reactivated.", { type: "success" });
          refresh();
        })
        .catch(() => {
          notify(
            "There was an error reactivating the user.\n\nPlease try again.",
            { type: "error" },
          );
        });
    },
    [data, notify, refresh],
  );
  return (
    <TopToolbar>
      {data?.deletedAt && (
        <Button label="Reactivate User" onClick={onClickRestoreUser}>
          <ReplayIcon />
        </Button>
      )}
      <Button label="Send Invitation" onClick={onClickSendInvitation}>
        <ContactMailIcon />
      </Button>
      <Button label="Send Password Reset" onClick={onClickSendResetPassword}>
        <ContactMailIcon />
      </Button>
      <Button label="Reset Password" onClick={onClickResetPassword}>
        <LockOpenIcon />
      </Button>
      <EditButton basePath={basePath} record={data} />
    </TopToolbar>
  );
};

export function DealerUserShow(props) {
  return (
    <Show actions={<EditToolbar />} {...props}>
      <SimpleShowLayout>
        <ReferenceArrayField
          label="Dealers"
          source="dealerIds"
          reference="dealers"
        >
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <FunctionField
          label="Name"
          render={record => `${record.firstName} ${record.lastName}`}
        />
        <ReferenceArrayField
          label="Roles"
          source="roleIds"
          reference="lists/dealer_user_roles"
        >
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <EmailField source="email" />
        <BoolNumField source="emailConfirmed" />
        <TextField source="phoneNumber" />
        <BoolNumField source="phoneNumberConfirmed" addLabel />
        <TextField source="lockoutEnd" />
        <NumberField source="accessFailedCount" />
        <TextField source="uid" label="Unique id" />
        <DateField source="createdAt" showTime />
        <DateField source="updatedAt" showTime />
        <TextField source="deletedAt" />
        <DealerQRCodesField label="Service appraisal QR codes" addLabel />
      </SimpleShowLayout>
    </Show>
  );
}

function DealerQRCodesField(props) {
  const user = useRecordContext(props);
  // console.log("USER", user);
  if (!user?.roleIds?.some(id => [3, 12, 13].includes(id))) {
    return null;
  }
  return (
    <ReferenceArrayField source="dealerIds" reference="dealers">
      <SingleFieldList
        style={{ display: "flex", flexDirection: "column" }}
        linkType={false}
      >
        <FunctionField
          render={dealer => {
            const url = `https://turnyn.dealsmgr.com/${dealer.slug}/open/service?duid=${user.uid}`;
            return (
              <Box pt="12px">
                Service appraisal QR code for <u>{dealer.name}</u>
                <QRCodeImg text={url} />
              </Box>
            );
          }}
        />
      </SingleFieldList>
    </ReferenceArrayField>
  );
}
