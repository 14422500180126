import React from "react";
import { useHistory } from "react-router";
import {
  Button,
  DateField,
  EditButton,
  SelectField,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
} from "react-admin";
// Icons
import FileCopyIcon from "@material-ui/icons/FileCopy";
// Local
import { REACT_APP_API_URL } from "../../config";
import { fetchJson } from "../../react-admin";
import { audiences } from "./ReportEdit";

const EditToolbar = props => {
  const { basePath, data /* , resource */ } = props;
  const hist = useHistory();
  const onClickDuplicate = React.useCallback(
    /** @param {React.SyntheticEvent<HTMLElement>} e */
    async e => {
      e.stopPropagation();
      if (!window.confirm(`Duplicate this report?\n\n    ${data.name}\n`)) {
        return;
      }
      const endpointUrl = `${REACT_APP_API_URL}/reports/${data.id}/duplicate`;
      await fetchJson(endpointUrl, {
        method: "POST",
        body: JSON.stringify({ confirmed: true }),
      })
        .then(() => {
          hist.push(`/reports`);
        })
        .catch(() => {
          alert("There was an error.\n\nPlease try again.");
        });
    },
    [data, hist],
  );
  return (
    <TopToolbar>
      <Button
        label="Duplicate"
        onClick={onClickDuplicate}
        title="Duplicate this report."
      >
        <FileCopyIcon />
      </Button>
      <EditButton basePath={basePath} record={data} />
    </TopToolbar>
  );
};

export const ReportShow = props => (
  <Show actions={<EditToolbar />} {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <SelectField source="audience" choices={audiences} />
      <DateField source="updatedAt" showTime />
      <DateField source="createdAt" showTime />
    </SimpleShowLayout>
  </Show>
);
