// Local
import { makeResource } from "../../react-admin";
import {
  AutoInsuranceCompanyList,
  AutoInsuranceCompanyIcon,
} from "./AutoInsuranceCompanyList";
import { AutoInsuranceCompanyEdit } from "./AutoInsuranceCompanyEdit";
import { AutoInsuranceCompanyShow } from "./AutoInsuranceCompanyShow";
import { AutoInsuranceCompanyCreate } from "./AutoInsuranceCompanyCreate";

export const AutoInsuranceCompanies = makeResource({
  name: "auto_insurance_companies",
  icon: AutoInsuranceCompanyIcon,
  list: AutoInsuranceCompanyList,
  edit: AutoInsuranceCompanyEdit,
  show: AutoInsuranceCompanyShow,
  create: AutoInsuranceCompanyCreate,
  options: {
    label: "Auto insurance companies",
  },
});
