import React from "react";
import { TextInput } from "react-admin";
import { useFormState } from "react-final-form";

/** An array of TextInputProps property names to treat as functions. */
const functionalProps = [
  //
  "disabled",
  "helperText",
  "InputProps",
];

/**
 * @typedef {import("react-admin").TextInputProps} TextInputProps
 * @typedef {object} TextInputPropsEx
 * @property {boolean|(values:any,source:string)=>boolean} [disabled]
 * @property {string|(values:any,source:string)=>string} [helperText]
 *
 * A text input with extra functionality.
 *
 * @param {TextInputProps & TextInputPropsEx} param0
 */
export function TextInputEx({ ...props }) {
  const { values } = useFormState();
  const { source } = props;
  for (let name of functionalProps) {
    const maybeFn = props[name];
    if (maybeFn && typeof maybeFn === "function") {
      props[name] = maybeFn(values, source);
    }
  }
  return <TextInput {...props} />;
}
