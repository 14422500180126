import React from "react";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import {
  AutocompleteInput,
  Datagrid,
  DateField,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  // SelectInput,
  TextField,
  TextInput,
} from "react-admin";
// Local

export const DealerBankIcon = AccountBalanceIcon;

const DealerBankFilter = props => (
  <Filter {...props}>
    <ReferenceInput source="dealerId" reference="dealers" alwaysOn>
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <TextInput source="name" alwaysOn />
  </Filter>
);

const DealerBankList = props => (
  <List
    {...props}
    filters={<DealerBankFilter />}
    // filterDefaultValues={{ field: "value" }}
    sort={{ field: "id", order: "DESC" }}
    bulkActionButtons={false}
    perPage={25}
  >
    <Datagrid optimized rowClick="edit">
      <TextField source="id" />
      <ReferenceField source="dealerId" reference="dealers">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="name" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);

export default DealerBankList;
