import React from "react";
import {
  // AutocompleteInput,
  Edit,
  // ReferenceInput,
  // SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";

export const DealerMsgVersionEdit = props => (
  <Edit mutationMode="pessimistic" {...props}>
    <SimpleForm redirect="show">
      <TextInput
        source="summary"
        label="Summary of changes made in this version"
        autoFocus
        fullWidth
        isRequired
      />
    </SimpleForm>
  </Edit>
);
