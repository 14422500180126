import React from "react";
import {
  //
  DateField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";

export const TwilioMessageShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="to" />
      <TextField source="body" />
      <TextField source="error_code" />
      <TextField source="error_message" />
      <TextField source="status" />
      <DateField source="date_created" showTime />
      <DateField source="date_sent" showTime />
      <DateField source="date_updated" showTime />
      <TextField source="from" />
    </SimpleShowLayout>
  </Show>
);
