import React from "react";
import { BooleanInput } from "react-admin";

/** @type {import("react-admin").BooleanInput} */
function _BoolNumInput({ record = {}, source, ...props }) {
  return (
    <BooleanInput
      record={{
        ...record,
        [source]: record[source] ? true : false,
      }}
      source={source}
      {...props}
    />
  );
}

export const BoolNumInput = React.memo(_BoolNumInput);
