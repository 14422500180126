import React from "react";
import {
  //
  Create,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
} from "react-admin";
// Local
import { caption } from "../../components";

const EditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const DealerCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="list" toolbar={<EditToolbar />}>
      {caption(
        "Enter the default common name of the dealership. " +
          "Example: Brooklyn Chrysler",
      )}
      <TextInput source="name" autoFocus fullWidth isRequired />
      {caption(
        "Enter the full public name of the dealership. " +
          "Example: Brooklyn Chrysler Jeep Dodge Ram",
      )}
      <TextInput source="publicName" fullWidth isRequired />
      {caption(
        "Enter the phone number without formatting or spaces. " +
          "Phone numbers will be formatted when displayed.",
      )}
      <TextInput source="phoneNumber" isRequired />
      {caption(
        "Enter the public dealership address. " +
          "The state MUST be abbreviated.",
      )}
      <TextInput source="address1" fullWidth isRequired />
      <TextInput source="address2" fullWidth />
      <TextInput source="city" isRequired />
      <TextInput source="state" isRequired />
      <TextInput source="zip" isRequired />
      {caption(
        "Enter the URL of the dealership logo. " +
          "It MUST start with https://",
      )}
      <TextInput
        source="logoUrl"
        fullWidth
        isRequired
        defaultValue="https://cdn.dealsmgr.com/assets/logo-placeholder.png"
      />
    </SimpleForm>
  </Create>
);
