import React from "react";
import {
  //
  Box,
} from "@material-ui/core";
// Local
import {
  //
  BoolShowField,
} from "../../components";

export function MsgRuleFields({ record }) {
  const { isDefault, keys, rules } = React.useMemo(() => {
    const isDefault = record?.isDefault;
    const rules = record?.typeInfo?.rules ?? {};
    return {
      isDefault,
      keys: Object.keys(rules),
      rules,
    };
  }, [record]);
  return (
    !!isDefault && (
      <>
        <Box color="rgba(0, 0, 0, 0.54)">
          <small>
            This message template is set as default for the following
          </small>
        </Box>
        {keys.length < 1 && "(none)"}
        {keys.map(key => {
          const rule = rules[key];
          if (rule.type !== "boolean") return null;
          return (
            <BoolShowField
              key={key}
              source={`spec.rules.${key}`}
              label={rule.name}
            />
          );
        })}
      </>
    )
  );
}
