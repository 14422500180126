// Local
import { makeResource } from "../../react-admin";
import { SaasStatList, SaasStatIcon } from "./SaasStatList";
// import { SaasStatEdit } from "./SaasStatEdit";
import { SaasStatShow } from "./SaasStatShow";

export const SaasStats = makeResource({
  name: "saas_stats",
  icon: SaasStatIcon,
  list: SaasStatList,
  // edit: SaasStatEdit,
  show: SaasStatShow,
  options: {
    category: "saas",
    label: "SaaS Stats",
  },
});
