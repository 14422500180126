import React from "react";
import {
  AutocompleteArrayInput,
  Edit,
  // NumberInput,
  // ReferenceInput,
  ReferenceArrayInput,
  SelectArrayInput,
  // SelectInput,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";

export const DealerUserEdit = props => (
  <Edit mutationMode="pessimistic" {...props}>
    <SimpleForm redirect={false}>
      <ReferenceArrayInput
        fullWidth
        label="Dealers"
        source="dealerIds"
        reference="dealers"
        validate={required()}
      >
        <AutocompleteArrayInput optionText="name" fullWidth />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        fullWidth
        label="Roles for all dealers"
        source="roleIds"
        reference="lists/dealer_user_roles"
        // helperText="Remember to add the Dealer Admin or Appraiser role if you want them to see Appraisals."
        validate={required()}
      >
        <SelectArrayInput optionText="name" fullWidth />
      </ReferenceArrayInput>
      <TextInput source="email" validate={required()} fullWidth />
      <TextInput source="firstName" validate={required()} />
      <TextInput source="lastName" validate={required()} />
      <TextInput source="phoneNumber" />
    </SimpleForm>
  </Edit>
);
