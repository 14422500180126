// Local
import { makeResource } from "../../react-admin";
import { EmailTemplateList, EmailTemplateIcon } from "./EmailTemplateList";
import { EmailTemplateShow } from "./EmailTemplateShow";

export const EmailTemplates = makeResource({
  name: "email_templates",
  icon: EmailTemplateIcon,
  list: EmailTemplateList,
  show: EmailTemplateShow,
  options: {
    label: "Email Templates",
  },
});
