import React from "react";
import {
  DateField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  UrlField,
} from "react-admin";
// Local
import { JsonField } from "../../components";
export const NotificationShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <ReferenceField source="dealerId" reference="dealers" link="show">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="userId" reference="dealer_users">
        <TextField source="userName" />
      </ReferenceField>
      <TextField source="message" />
      <UrlField source="link" />
      <JsonField source="emailVars" />
      <DateField source="createdAt" showTime />
    </SimpleShowLayout>
  </Show>
);
