import React from "react";
import {
  Edit,
  // NumberInput,
  // ReferenceInput,
  // ReferenceArrayInput,
  // SelectArrayInput,
  // SelectInput,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";

export const AdminUserEdit = props => (
  <Edit mutationMode="pessimistic" {...props}>
    <SimpleForm redirect={false}>
      {/* <ReferenceArrayInput
        fullWidth
        label="Roles for all dealers"
        source="roleIds"
        reference="lists/dealer_user_roles"
        helperText="Remember to add the Dealer Admin or Appraiser role if you want them to see Appraisals."
        validate={required()}
      >
        <SelectArrayInput optionText="name" fullWidth />
      </ReferenceArrayInput> */}
      <TextInput source="firstName" validate={required()} />
      <TextInput source="lastName" validate={required()} />
      <TextInput source="email" validate={required()} />
      <TextInput source="phoneNumber" />
    </SimpleForm>
  </Edit>
);
