// Local
import { makeResource } from "../../react-admin";
import {
  // MsgPartyList,
  MsgPartyIcon,
} from "./MsgPartyList";
import { MsgPartyEdit } from "./MsgPartyEdit";
import { MsgPartyShow } from "./MsgPartyShow";

export const MsgParties = makeResource({
  name: "msg_parties",
  icon: MsgPartyIcon,
  // list: MsgPartyList,
  edit: MsgPartyEdit,
  show: MsgPartyShow,
  options: {
    hidden: true,
    label: "Message parties",
  },
});
