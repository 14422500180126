import React from "react";
import {
  AutocompleteInput,
  Edit,
  Labeled,
  ReferenceField,
  ReferenceInput,
  // SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";
// Local
import {
  //
  BoolNumInput,
  // NonInput,
} from "../../components";
import { selectAllTarget } from "../../lib";
import { MsgRuleInputs } from "../msg_templates/MsgRuleInputs";

export const DealerMsgTemplateEdit = props => {
  // console.log("PROPS", props);
  return (
    <Edit mutationMode="pessimistic" {...props}>
      <SimpleForm redirect="show">
        <Labeled label="Dealer">
          <ReferenceField source="dealerId" reference="dealers" label="Dealer">
            <TextField source="name" />
          </ReferenceField>
        </Labeled>
        <TextInput
          source="name"
          label="Template name"
          autoFocus
          fullWidth
          isRequired
          defaultValue="New template"
          onFocus={selectAllTarget}
        />
        <TextInput
          source="description"
          label="Template description"
          fullWidth
          defaultValue=""
        />
        <ReferenceInput
          source="msgTypeId"
          reference="msg_types"
          isRequired
          label="Message type"
          fullWidth
          editable={false}
          disabled
        >
          <AutocompleteInput optionText="name" readOnly />
        </ReferenceInput>
        <BoolNumInput
          source="isDefault"
          defaultValue={0}
          label="Use this template as the default for 'Message type'"
          fullWidth
        />
        <MsgRuleInputs />
      </SimpleForm>
    </Edit>
  );
};
