import React from "react";
import { Typography } from "@material-ui/core";

export const NonInput = React.memo(
  /**
   * Removes react-admin input props, so you can add non-input items inside of
   * a `SimpleForm` component.
   */
  function NonInput(props) {
    const { children } = props;
    return children;
  },
);

// Some common NonInput form sections...

export function caption(text) {
  return (
    <NonInput>
      <Typography variant="caption">
        <em>{text}</em>
      </Typography>
    </NonInput>
  );
}
