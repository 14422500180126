import React from "react";
import { TextField } from "react-admin";

/**
 * @param {import("react-admin").TextFieldProps} props
 */
export function FullNameField(props) {
  // console.log("FN", props);
  const { record, source = "fullName", ...rest } = props;
  return (
    <TextField
      source={source}
      record={{
        [source]: record
          ? `${record.firstName ?? ""} ${record.lastName ?? ""}`.trim() || null
          : null,
        ...record,
      }}
      {...rest}
    />
  );
}
FullNameField.defaultProps = { ...FullNameField.defaultProps };
