import React from "react";
import { Show, SimpleShowLayout, TextField, ReferenceField } from "react-admin";

export const TenantShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <ReferenceField source="tenantTypeId" reference="lists/tenant_types">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="tenantTypeId" />
      <ReferenceField source="dealerId" reference="dealers">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="origin" />
    </SimpleShowLayout>
  </Show>
);
