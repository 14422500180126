import { ListGuesser, EditGuesser, ShowGuesser } from "react-admin";
// Local

/**
 * @param {ExtendedResourceProps} props
 * @returns {ExtendedResourceProps}
 */
export function guessResource(props) {
  return {
    list: ListGuesser,
    show: ShowGuesser,
    edit: EditGuesser,
    ...props,
  };
}

/** Common sort expressions for use by, e.g. `<ReferenceInput sort={...} />` */
export const sortBy = {
  idAsc: { field: "id", order: "ASC" },
  idDesc: { field: "id", order: "DESC" },
  nameAsc: { field: "name", order: "ASC" },
  nameDesc: { field: "name", order: "DESC" },
};

/**
 * @param {ExtendedResourceProps} props
 * @returns {ExtendedResourceProps}
 */
export function makeResource(props) {
  return props;
}
