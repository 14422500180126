import React from "react";
import {
  //
  List,
  Datagrid,
  DateField,
  Filter,
  ReferenceField,
  TextField,
  TextInput,
} from "react-admin";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
// Local
import { clearFilterButton } from "../../components";

export const SaasLeadIcon = AccountBoxIcon;

const ListFilters = props => (
  <Filter {...props}>
    <TextInput source="firstName" alwaysOn />
    <TextInput source="lastName" alwaysOn />
    <TextInput source="email" alwaysOn />
    {clearFilterButton()}
  </Filter>
);

export const SaasLeadList = props => (
  <List
    {...props}
    filters={<ListFilters />}
    perPage={25}
    sort={{ field: "id", order: "DESC" }}
  >
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="email" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <DateField source="createdAt" showTime />
      <ReferenceField source="statusId" reference="lists/saas_lead_statuses">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="ipAddr" />
      <TextField source="recaptchaScore" />
    </Datagrid>
  </List>
);
