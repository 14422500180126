// Local
import { makeResource } from "../../react-admin";
import { MsgTypeList, MsgTypeIcon } from "./MsgTypeList";
import { MsgTypeEdit } from "./MsgTypeEdit";
import { MsgTypeShow } from "./MsgTypeShow";

export const MsgTypes = makeResource({
  name: "msg_types",
  icon: MsgTypeIcon,
  list: MsgTypeList,
  edit: MsgTypeEdit,
  show: MsgTypeShow,
  options: {
    // hidden: true,
    label: "Message types",
  },
});
