import React from "react";
import {
  ArrayInput,
  // AutocompleteInput,
  Edit,
  // ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  required,
  useNotify,
} from "react-admin";
// Local
import {
  AddItemButton,
  RemoveItemButton,
  SqlEditorInput,
} from "../../components";
import { returnBlankString } from "../../lib";
import { useHorizontalArrayFormStyle } from "../../react-admin";

export const audiences = [
  { id: "admin", name: "Admin" },
  { id: "dealer", name: "Dealer" },
];

const fieldFormats = [
  { id: "date", name: "Date" },
  { id: "datetime", name: "Date+Time" },
  { id: "decimal", name: "Decimal" },
  { id: "dollar", name: "Dollar" },
  { id: "phone", name: "Phone" },
];

const resultNameHelperText =
  "A single word used to refer to this query " +
  "result, to merge it into an email or file... " +
  "Use camelCase or under_scores for multiple words.";

const sqlHelperText =
  "Enter a query to select data for the report. " +
  "End with `LIMIT 1` to render a single row... Example: " +
  "SELECT email, created_at FROM dealer_users WHERE id = {{params.id}} LIMIT 1;";

const styles = {
  textContent: {
    width: "45vw",
  },
};

export function ReportEdit(props) {
  const notify = useNotify();
  const paramsArrayClasses = useHorizontalArrayFormStyle();

  const onSuccess = React.useCallback(() => {
    notify("Report saved.", { type: "success" });
  }, [notify]);

  return (
    <Edit mutationMode="pessimistic" onSuccess={onSuccess} {...props}>
      <SimpleForm>
        <TextInput
          source="name"
          fullWidth
          autoFocus
          helperText={
            "NOTE: Report job or report names are used as " +
            "notification identifiers. Do not change once in use!"
          }
          validate={required()}
        />
        <SelectInput
          source="audience"
          choices={audiences}
          validate={required()}
        />
        <ArrayInput source="spec.queries" label="Queries">
          <SimpleFormIterator
            addButton={<AddItemButton label="Add query" />}
            removeButton={<RemoveItemButton />}
          >
            <TextInput
              source="name"
              label="Result name"
              defaultValue="results"
              placeholder="results"
              helperText={resultNameHelperText}
              fullWidth
              validate={required()}
            />
            <SqlEditorInput
              // defaultValue="SELECT COUNT(*) `count` FROM dealer_users LIMIT 1;"
              label="Query"
              source="sql"
              validate={required()}
              helperText={sqlHelperText}
            />

            <ArrayInput source="fields" label="Fields">
              <SimpleFormIterator
                classes={paramsArrayClasses}
                getItemLabel={returnBlankString}
              >
                <TextInput
                  source="name"
                  label="Field name"
                  // helperText="Field selected by query."
                  validate={required()}
                />
                <SelectInput
                  source="format"
                  label="Format"
                  allowEmpty
                  choices={fieldFormats}
                />
              </SimpleFormIterator>
            </ArrayInput>
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="spec.text" label="Text variables">
          <SimpleFormIterator
            addButton={<AddItemButton label="Add text" />}
            removeButton={<RemoveItemButton />}
            classes={paramsArrayClasses}
            getItemLabel={returnBlankString}
          >
            <TextInput source="name" label="Name" validate={required()} />
            <TextInput
              source="content"
              label="Content"
              validate={required()}
              fullWidth
              style={styles.textContent}
              multiline
              minRows={1}
              maxRows={6}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
}
