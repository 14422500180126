import React from "react";
import DonutSmallIcon from "@material-ui/icons/DonutSmall";
import {
  AutocompleteInput,
  Datagrid,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  // SelectInput,
  TextField,
  TextInput,
} from "react-admin";
// Local

export const AppraisalDealTypeIcon = DonutSmallIcon;

const AppraisalDealTypeFilter = props => (
  <Filter {...props}>
    <ReferenceInput source="dealerId" reference="dealers" alwaysOn>
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <TextInput source="name" alwaysOn />
  </Filter>
);

const AppraisalDealTypeList = props => (
  <List
    {...props}
    filters={<AppraisalDealTypeFilter />}
    // filterDefaultValues={{ field: "value" }}
    sort={{ field: "id", order: "ASC" }}
    bulkActionButtons={false}
    perPage={25}
  >
    <Datagrid optimized rowClick="show">
      <TextField source="id" />
      <ReferenceField source="dealerId" reference="dealers">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="name" />
      <ReferenceField
        source="appraisalTypeId"
        reference="lists/appraisal_types"
      >
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
);

export default AppraisalDealTypeList;
