// Local
import DealerLinkageCreate from "./DealerLinkageCreate";
import DealerLinkageEdit from "./DealerLinkageEdit";
import DealerLinkageList, { DealerLinkageIcon } from "./DealerLinkageList";

export const DealerLinkages = {
  name: "dealer_linkages",
  create: DealerLinkageCreate,
  list: DealerLinkageList,
  edit: DealerLinkageEdit,
  icon: DealerLinkageIcon,
  options: {
    label: "Dealer linkages",
  },
};
