import React from "react";
import {
  //
  AutocompleteInput,
  Datagrid,
  DateField,
  DateInput,
  Filter,
  List,
  ReferenceInput,
  ReferenceField,
  TextField,
} from "react-admin";
import NotificationsActiveOutlinedIcon from "@material-ui/icons/NotificationsActiveOutlined"; // Local
import { clearFilterButton } from "../../components";

export const NotificationIcon = NotificationsActiveOutlinedIcon;

const Filters = props => (
  <Filter {...props}>
    <ReferenceInput source="dealerId" reference="dealers" alwaysOn>
      <AutocompleteInput optionText="name" />
    </ReferenceInput>

    <DateInput source="createdAt_gte" label="From date" alwaysOn />
    <DateInput source="createdAt_lte" label="To date" alwaysOn />
    {clearFilterButton()}
  </Filter>
);

export const NotificationList = props => (
  <List
    {...props}
    filters={<Filters />}
    perPage={25}
    sort={{ field: "id", order: "DESC" }}
    bulkActionButtons={false}
  >
    <Datagrid rowClick="show">
      <TextField source="id" />
      <ReferenceField source="dealerId" reference="dealers">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="userId" reference="dealer_users">
        <TextField source="userName" />
      </ReferenceField>

      <TextField source="message" />
      <DateField source="createdAt" showTime />
    </Datagrid>
  </List>
);
