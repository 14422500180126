import { HttpError, fetchUtils } from "react-admin";
import simpleRestProvider from "ra-data-simple-rest";

/**
 *
 * @param {string} url
 * @param {import("ra-core").fetchUtils.Options} options
 */
export function fetchJson(url, options = {}) {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  // const token = getAuthToken();
  // options.headers.set("Authorization", `Bearer ${token}`);
  if (!options.credentials) {
    options.credentials = "include";
  }
  return fetchUtils.fetchJson(url, options);
}
/**
 *
 * @param {string} url
 * @param {import("ra-core").fetchUtils.Options} options
 */
export function fetchText(url, options = {}) {
  // if (!options.headers) {
  //   options.headers = new Headers({ Accept: "text/html" });
  // }
  // const token = getAuthToken();
  // options.headers.set("Authorization", `Bearer ${token}`);
  if (!options.credentials) {
    options.credentials = "include";
  }
  return fetch(url, options)
    .then(res =>
      res.text().then(text => ({
        status: res.status,
        statusText: res.statusText,
        headers: res.headers,
        body: text,
      })),
    )
    .then(({ body, headers, status, statusText }) => {
      if (status < 200 || status >= 300) {
        return Promise.reject(new HttpError(statusText, status, body));
      }
      return Promise.resolve({ status, headers, body });
    });
}

const baseProvider = simpleRestProvider(
  process.env.REACT_APP_API_URL,
  fetchJson,
  // See https://github.com/marmelab/react-admin/tree/master/packages/ra-data-simple-rest#note-about-content-range
  "X-Total-Count",
);

// NOTE: Not sure why this is necessary...
// const delayedDataProvider = new Proxy(baseProvider, {
//   get: (target, name, self) =>
//     name === "then" // as we await for the dataProvider, JS calls then on it. We must trap that call or else the dataProvider will be called with the then method
//       ? self
//       : (resource, params) =>
//           new Promise((resolve) =>
//             setTimeout(
//               () => resolve(baseProvider[name](resource, params)),
//               500,
//             ),
//           ),
// });

export const dataProvider = baseProvider;
