import React from "react";
import { BooleanInput, NumberInput, TextInput } from "react-admin";

export const SettingInput = React.memo(
  /**
   * @typedef {import("react-admin").TextInputProps} TextInputProps
   * @typedef {import("react-admin").NumberInputProps} NumberInputProps
   * @typedef {object} SettingInputProps
   * @property {Setting} record
   *
   * @param {SettingInputProps & NumberInputProps & TextInputProps} param0
   */
  function SettingInput({ record = {}, source, ...props }) {
    const { type } = record;
    return type === "boolean" ? (
      <BooleanInput
        record={{
          ...record,
          [source]: record[source] ? true : false,
        }}
        source={source}
        {...props}
      />
    ) : type === "number" ? (
      <NumberInput record={record} source={source} {...props} />
    ) : (
      <TextInput record={record} source={source} {...props} />
    );
  },
);
