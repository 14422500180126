import React from "react";
import { Box } from "@material-ui/core";
import { BooleanInput } from "react-admin";
import { useFormState } from "react-final-form";

export function MsgRuleInputs({ record }) {
  const { values } = useFormState();
  const rules = record?.typeInfo?.rules ?? {};
  if (!values.isDefault) {
    return null;
  }
  return (
    <div
      title={
        "When any of the enabled rules are matched, this template will be " +
        "used as the default..."
      }
    >
      <Box fontSize="16px" p="16px" pt="0" pl="0" color="rgba(0, 0, 0, 0.54)">
        <small>
          Use this template as default for the following. Enable them all to use
          a single template for all conditions.
        </small>
      </Box>
      <Box border="1px solid lightgrey" borderRadius="6px" p="16px">
        {Object.keys(rules).map(key => {
          const rule = rules[key];
          if (rule.type !== "boolean") return null;
          return (
            <BooleanInput
              key={key}
              source={`spec.rules.${key}`}
              label={rule.name}
              helperText={rule.help}
              defaultValue={true}
            />
          );
        })}
      </Box>
    </div>
  );
}
