// Local
import { makeResource } from "../../react-admin";
import {
  AppraisalApiResultList,
  AppraisalApiResultIcon,
} from "./AppraisalApiResultList";
// import { AppraisalApiResultEdit } from "./AppraisalApiResultEdit";
import { AppraisalApiResultShow } from "./AppraisalApiResultShow";
// import { AppraisalApiResultCreate } from "./AppraisalApiResultCreate";

export const AppraisalApiResults = makeResource({
  name: "appraisal_api_results",
  icon: AppraisalApiResultIcon,
  list: AppraisalApiResultList,
  // edit: AppraisalApiResultEdit,
  show: AppraisalApiResultShow,
  // create: AppraisalApiResultCreate,
  options: {
    label: "Appraisal results",
  },
});
