import React from "react";
import { Edit, SaveButton, SimpleForm, TextField, Toolbar } from "react-admin";
// Local
import { SettingInput } from "../../components";

const EditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const SettingEdit = props => (
  <Edit mutationMode="pessimistic" {...props}>
    <SimpleForm redirect="list" toolbar={<EditToolbar />}>
      <TextField source="name" />
      <TextField source="description" />
      <SettingInput source="value" fullWidth />
    </SimpleForm>
  </Edit>
);

export default SettingEdit;
