import React from "react";
import {
  //
  Create,
  // ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
// Local
import { saasBlockFields } from "./shared";

export const SaasBlockCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <SelectInput source="field" choices={saasBlockFields} />
      <TextInput source="value" />
    </SimpleForm>
  </Create>
);
