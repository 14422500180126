import { ErrorSentry } from "../components/errors/ErrorSentry";

const { REACT_APP_API_URL } = process.env;

/** LocalStorage key for auth info. */
const AUTH_KEY = "auth";

/** @type {{user:{id:number,email:string,roles:string[],firstName?:string,lastName?:string}}} */
let auth = undefined;

export const authState = {
  get user() {
    return auth?.user;
  },
};

/** @type {import("ra-core").AuthProvider} */
export const authProvider = {
  async login({ username, password }) {
    // console.log("login", username);
    const req = new Request(`${REACT_APP_API_URL}/auth/login`, {
      method: "POST",
      body: JSON.stringify({ email: username, password }),
      headers: new Headers({ "Content-Type": "application/json" }),
      credentials: "include",
    });
    const res = await fetch(req);
    if (res.status < 200 || res.status >= 300) {
      throw new Error(res.statusText);
    }
    auth = await res.json();
    ErrorSentry.setUser(auth);
    localStorage.setItem(AUTH_KEY, JSON.stringify(auth));
  },
  async logout() {
    // console.log("logout");
    auth = undefined;
    localStorage.removeItem(AUTH_KEY);
    const req = new Request(`${REACT_APP_API_URL}/auth/logout`, {
      method: "GET",
      headers: new Headers({ "Content-Type": "application/json" }),
      credentials: "include",
    });
    await fetch(req).catch(ex => {
      console.error("LOGOUT", "" + ex, ex);
    });
  },
  async checkError({ status }) {
    // console.log("checkError", status);
    return status === 401 || status === 403
      ? Promise.reject()
      : Promise.resolve();
  },
  async checkAuth() {
    // console.log("checkAuth");
    return auth ? Promise.resolve() : Promise.reject();
  },
  async getPermissions() {
    // console.log("getPermissions");
    return auth ? Promise.resolve(auth.user.roles) : Promise.reject();
  },
};

export async function authGet(url) {
  const req = new Request(`${REACT_APP_API_URL}${url}`, {
    method: "GET",
    headers: new Headers({ "Content-Type": "application/json" }),
    credentials: "include",
  });
  const res = await fetch(req);
  if (res.status < 200 || res.status >= 300) {
    throw new Error(res.statusText);
  }
  return res.json();
}

export async function authPost(url, data) {
  const req = new Request(`${REACT_APP_API_URL}${url}`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: new Headers({ "Content-Type": "application/json" }),
    credentials: "include",
  });
  return fetch(req);
}

export function isAuthenticated() {
  return !!auth;
}

const InvalidTokenErrorCode = "InvalidToken";
export function hasInvalidTokenError(error) {
  if (!error) {
    return false;
  }
  if (error.code === InvalidTokenErrorCode) {
    return true;
  }
  const { errors } = error;
  if (!Array.isArray(errors)) {
    return false;
  }
  return errors.filter(err => err.code === InvalidTokenErrorCode).length > 0;
}

function loadAuth() {
  const item = localStorage.getItem(AUTH_KEY);
  // console.log("loadAuth", item);
  if (item) {
    auth = JSON.parse(item);
    ErrorSentry.setUser(auth);
  } else {
    auth = undefined;
  }
}

loadAuth();
