import { defaultTheme } from "react-admin";

const SIDE_WIDTH = 240;
const SIDE_WIDTH_CLOSED = 55;

/** @type {import("@material-ui/core").ThemeOptions} */
export const defaultAppTheme = {
  ...defaultTheme,
  /** Custom theme vars for our app. */
  menu: {
    width: SIDE_WIDTH,
    closedWidth: SIDE_WIDTH_CLOSED,
  },
  sidebar: {
    ...defaultTheme.sidebar,
    width: SIDE_WIDTH,
    closedWidth: SIDE_WIDTH_CLOSED,
  },
  overrides: {
    ...defaultTheme.overrides,
    // MuiButton: {
    //   root: {
    //     textTransform: "none",
    //   },
    // },
    MuiFormControlLabel: {
      root: {
        userSelect: "none",
      },
    },
  },
};
