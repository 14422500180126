import React from "react";
import {
  AutocompleteInput,
  DeleteWithConfirmButton,
  Edit,
  ReferenceInput,
  SelectInput,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
} from "react-admin";
// Local
import { caption, NonInput } from "../../components";

const EditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
    <NonInput>
      <div style={{ flex: 1 }} />
    </NonInput>
    <DeleteWithConfirmButton />
  </Toolbar>
);

const AppraisalDealTypeEdit = props => (
  <Edit mutationMode="pessimistic" {...props}>
    <SimpleForm redirect="list" toolbar={<EditToolbar />}>
      {/* <TextInput source="uid" disabled /> */}
      <ReferenceInput source="dealerId" reference="dealers" fullWidth>
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <TextInput source="name" autoFocus fullWidth />
      <ReferenceInput
        source="appraisalTypeId"
        reference="lists/appraisal_types"
        allowEmpty
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      {caption(`Appraisal type is not in use at this time.`)}
    </SimpleForm>
  </Edit>
);

export default AppraisalDealTypeEdit;
