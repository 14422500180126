import React from "react";
import { Create, SaveButton, Toolbar } from "react-admin";
// Local
import { DealerCampaignForm } from "./DealerCampaignEdit";

const EditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export function DealerCampaignCreate(props) {
  return (
    <Create {...props}>
      <DealerCampaignForm type="create" toolbar={<EditToolbar />} />
    </Create>
  );
}
