import React from "react";
import {
  //
  Box,
  ButtonGroup,
  Divider,
  makeStyles,
} from "@material-ui/core";
import {
  Button,
  BooleanField,
  DateField,
  EditButton,
  Labeled,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  useRefresh,
} from "react-admin";
import { Link } from "react-router-dom";
// Icons
import EditEmailIcon from "@material-ui/icons/Email";
import EditSmsIcon from "@material-ui/icons/Textsms";
import EditPushIcon from "@material-ui/icons/Notifications";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import PublishIcon from "@material-ui/icons/Publish";
import HistoryIcon from "@material-ui/icons/Restore";
// Local
import {
  //
  BoolShowField,
  FullNameField,
  LinkButton,
  NonField,
  useBasicDialog,
} from "../../components";
import { formatThousands } from "../../lib";
import { history } from "../../react-admin";
import { duplicateTemplate, publishTemplate } from "../msg_templates/api";
import { MsgRuleFields } from "../msg_templates/MsgRuleFields";

const useToolStyles = makeStyles(
  theme => ({
    filler: {
      flexGrow: 1,
    },
    spacer: {
      minWidth: 8,
    },
  }),
  {
    classNamePrefix: "DealerMsgTemplateTools",
  },
);
/** @param {import("react-admin").ToolbarProps} props */
function DealerMsgTemplateTools(props) {
  // console.log("TB", props);
  const { basePath, resource } = props;
  /** @type {import("../msg_templates/types").DealerMsgTemplate} */
  const data = props.data ?? {};
  const templateId = data.id;

  const classes = useToolStyles();
  const filler = <div className={classes.filler}></div>;
  const spacer = <div className={classes.spacer}></div>;

  const refresh = useRefresh();

  const {
    // alertAsync,
    basicDialog,
    confirmAsync,
    promptAsync,
    // showDialogAsync,
  } = useBasicDialog();

  const onClickDuplicate = React.useCallback(
    /** @param {React.SyntheticEvent<HTMLButtonElement>} e */
    async e => {
      if (
        !(await confirmAsync({
          title: "Duplicate message template?",
          text: "Are you sure that you want to make a copy of this template?",
        }))
      ) {
        return;
      }
      const newTemplateId = await duplicateTemplate(data);
      history.push(`/dealer_msg_templates/${newTemplateId}/edit`);
    },
    [data, confirmAsync],
  );

  const onClickPublish = React.useCallback(
    /** @param {React.SyntheticEvent<HTMLButtonElement>} e */
    async e => {
      const summary = await promptAsync({
        title: "Publish message template",
        mulitline: true,
        text: "Summarize your changes. Press OK or Enter when done.",
      });
      console.log("Summary:", summary);
      if (!summary) {
        return;
      }
      const result = await publishTemplate(data, {
        summary,
      });
      console.log("Published", data.id, result);
      refresh();
    },
    [data, promptAsync, refresh],
  );
  return (
    <TopToolbar>
      {basicDialog}

      <LinkButton
        list="dealer_msg_versions"
        // filter={record => ({ templateId: record.id })}
        filter={{ dealerId: data?.dealerId, templateId: data?.id }}
        label={`History (${formatThousands(data?.pubCount ?? 0)})`}
        title="Show version history of this template."
        variant="outlined"
        startIcon={<HistoryIcon />}
      />
      {spacer}
      <ButtonGroup
        title={
          "Publish changes made to any and all template contents.\n" +
          "Requires at least one of email, sms or push to be enabled."
        }
      >
        <Button
          label="Publish"
          onClick={onClickPublish}
          disabled={
            (!data.email?.enabled &&
              !data.sms?.enabled &&
              !data.push?.enabled) ||
            !data?.hasChanges
          }
        >
          <PublishIcon />
        </Button>
      </ButtonGroup>
      {filler}
      <ButtonGroup>
        <Button
          label="Edit email"
          title="Edit the email content of this template"
          // Use the react-router-dom Link to NOT reload when opening editor...
          component={Link}
          to={`/dealer_msg_templates/${templateId}/email`}
          // Use a regular "a href" to RELOAD the app when opening the editor.
          // component="a"
          // href={`/dealer_msg_templates/${templateId}/email`}
          // We could also FORCE it to open in a new tab...
          // target="_blank"
        >
          <EditEmailIcon />
        </Button>
        <Button
          label="Edit SMS"
          title="Edit the SMS/Text message content of this template"
          component={Link}
          to={`/dealer_msg_template_sms/${templateId}/edit`}
        >
          <EditSmsIcon />
        </Button>
        <Button
          label="Edit Push"
          title="Edit the Push Notification content of this template"
          component={Link}
          to={`/dealer_msg_template_push/${templateId}/edit`}
        >
          <EditPushIcon />
        </Button>
      </ButtonGroup>
      {filler}
      <ButtonGroup title="Make a copy of this template.">
        <Button label="Duplicate" onClick={onClickDuplicate}>
          <FileCopyIcon />
        </Button>
      </ButtonGroup>
      {spacer}
      <EditButton
        basePath={basePath}
        record={data}
        resource={resource}
        label="Edit details"
        title="Edit template details. Name, type, description, enabled, etc."
      />
    </TopToolbar>
  );
}

const useStyles = makeStyles(
  theme => ({
    root: {
      // margin: 0,
    },
    hfields: {
      display: "flex",
      alignItems: "flex-start",
      "& > .MuiFormControl-root": {
        marginRight: 16,
      },
    },
  }),
  {
    classNamePrefix: "DealerMsgTemplateShow",
  },
);

export function DealerMsgTemplateShow(props) {
  const classes = useStyles();
  return (
    <Show actions={<DealerMsgTemplateTools />} {...props}>
      <SimpleShowLayout className={classes.root}>
        <Labeled label="Dealer">
          <ReferenceField source="dealerId" reference="dealers" label="Dealer">
            <TextField source="name" />
          </ReferenceField>
        </Labeled>
        <div className={classes.hfields}>
          <Labeled label="Name">
            <TextField source="name" />
          </Labeled>
          <div style={{ width: 96 }} />
          <Labeled label="Description">
            <TextField source="description" />
          </Labeled>
        </div>
        <NonField children={<Divider />} />
        <div className={classes.hfields}>
          <Labeled label="Created">
            <DateField source="createdAt" showTime label="Created" />
          </Labeled>
          <Labeled label="Last updated">
            <DateField source="updatedAt" showTime label="Last updated" />
          </Labeled>
          <Labeled label="Has unpublished changes">
            <BooleanField
              source="hasChanges"
              label="Has changes to publish"
              looseValue
            />
          </Labeled>
          <Labeled label="Last published">
            <DateField
              source="publishedAt"
              label="Last published"
              showTime
              emptyText="Not published."
            />
          </Labeled>
          <Labeled label="by">
            <ReferenceField
              source="publishedBy"
              reference="admin_users"
              label="Published by"
            >
              <FullNameField />
            </ReferenceField>
          </Labeled>
        </div>
        <NonField children={<Divider />} />
        <NonField>
          <Box color="rgba(0, 0, 0, 0.54)">
            <small>Contents</small>
          </Box>
        </NonField>
        <BoolShowField source="email.enabled" label="Email" />
        <BoolShowField source="sms.enabled" label="SMS" />
        <BoolShowField source="push.enabled" label="Push" />
        <NonField children={<Divider />} />
        <div className={classes.hfields}>
          <Labeled label="Type of message">
            <ReferenceField
              source="msgTypeId"
              reference="msg_types"
              label="Message type"
              link="show"
            >
              <TextField source="name" />
            </ReferenceField>
          </Labeled>
          <Labeled label="This message template is a default for the type of message">
            <BooleanField
              source="isDefault"
              label="This message template is a default for the type of message"
              looseValue
            />
          </Labeled>
        </div>
        <MsgRuleFields />
      </SimpleShowLayout>
    </Show>
  );
}
