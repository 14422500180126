import React from "react";
import { FormHelperText, FormLabel } from "@material-ui/core";
import { FieldTitle, useInput, useTranslate } from "react-admin";
import { Controlled as CodeMirror } from "react-codemirror2";
import "codemirror/mode/sql/sql";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";

/** @type {import("codemirror").EditorConfiguration} */
const options = {
  mode: "sql",
  theme: "material",
  lineNumbers: true,
};

const styles = {
  /** @type {React.CSSProperties} */
  errorLine: {
    borderWidth: 2,
    borderColor: "#f44336",
    margin: 0,
  },
  /** @type {React.CSSProperties} */
  helperText: {
    marginLeft: 14,
    marginRight: 14,
  },
  /** @type {React.CSSProperties} */
  label: {
    lineHeight: 2,
  },
};

const templateTokenRegex = /{{(.*?)}}/g;

function getSqlTemplateVars(sql) {
  const vars = Array.from(sql.matchAll(templateTokenRegex), it => it[1]);
  const uniqueVars = [...new Set(vars)];
  const sortedVars = uniqueVars.sort(function (a, b) {
    return a.localeCompare(b);
  });
  return sortedVars;
}

export const SqlEditorInput = React.memo(
  /**
   * @param {import("react-admin").InputProps & {helperText:string}} props
   */
  function SqlEditorInput(props) {
    const {
      input: {
        name: _name,
        onChange: onChanged,
        value,
        type: _type,
        checked: _checked,
        multiple: _multiple,
        ...rest
      },
      meta: { touched, error },
      isRequired,
    } = useInput(props);
    const translate = useTranslate();

    const onChange = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      (editor, data, value) => {
        onChanged({
          currentTarget: { value: value ?? "" },
          target: { value: value ?? "" },
        });
      },
      [onChanged],
    );

    const varsHelper = React.useMemo(() => {
      const vars = getSqlTemplateVars("" + value);
      if (vars.length < 1) {
        return null;
      }
      return (
        <div>
          <strong>Vars detected:</strong>
          <ul>
            {vars.map(v => (
              <li key={v}>{v}</li>
            ))}
          </ul>
        </div>
      );
    }, [value]);

    const showError = !!(touched && error);

    return (
      <>
        {props.label && (
          <FormLabel error={showError} style={styles.label}>
            <FieldTitle
              label={props.label}
              source={props.source}
              resource={props.resource}
              isRequired={isRequired}
            />
          </FormLabel>
        )}
        <CodeMirror
          value={value ?? ""}
          options={options}
          onBeforeChange={onChange}
          {...rest}
        />
        {showError ? (
          <>
            <hr style={styles.errorLine} />
            <FormHelperText component="div" error style={styles.helperText}>
              {translate(error)}
            </FormHelperText>
          </>
        ) : (
          <FormHelperText component="div" style={styles.helperText}>
            {props.helperText}
            {varsHelper}
          </FormHelperText>
        )}
      </>
    );
  },
);
