import React from "react";
import { List, Datagrid, TextField } from "react-admin";
import ListIcon from "@material-ui/icons/List";
// Local
import {
  //
  // guessResource,
  makeResource,
} from "../../react-admin";

const ActivityStatusList = props => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="description" />
    </Datagrid>
  </List>
);
export const ActivityStatuses = makeResource({
  name: "lists/activity_statuses",
  icon: ListIcon,
  list: ActivityStatusList,
  options: {
    label: "Activity statuses",
  },
});

const ActivityTypeList = props => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
    </Datagrid>
  </List>
);
export const ActivityTypes = makeResource({
  name: "lists/activity_types",
  icon: ListIcon,
  list: ActivityTypeList,
  options: {
    label: "Activity types",
  },
});

// Hidden

export const AppraisalApiTypes = makeResource({
  name: "lists/appraisal_api_types",
});

export const AppraisalStatuses = makeResource({
  name: "lists/appraisal_statuses",
});

export const AppraisalTypes = makeResource({
  name: "lists/appraisal_types",
});

export const DealStatuses = makeResource({
  name: "lists/deal_statuses",
});

export const DealerCampaignTypes = makeResource({
  name: "lists/dealer_campaign_types",
});

export const DealerUserRoles = makeResource({
  name: "lists/dealer_user_roles",
});

export const TenantTypes = makeResource({
  name: "lists/tenant_types",
});

export const Roles = makeResource({
  name: "lists/roles",
});

export const SaasLeadStatuses = makeResource({
  name: "lists/saas_lead_statuses",
});

export default [
  //
  // Order in this array determines position in the side menu.
  //
  ActivityTypes,
  ActivityStatuses,

  // Hidden
  AppraisalApiTypes,
  AppraisalStatuses,
  AppraisalTypes,
  DealStatuses,
  DealerCampaignTypes,
  DealerUserRoles,
  Roles,
  SaasLeadStatuses,
  TenantTypes,
];
