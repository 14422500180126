import React from "react";
import {
  //
  List,
  Datagrid,
  // DateField,
  Filter,
  // ReferenceField,
  SelectInput,
  TextField,
  TextInput,
} from "react-admin";
import BlockIcon from "@material-ui/icons/Block";
// Local
import { clearFilterButton } from "../../components";
import { saasBlockFields } from "./shared";

export const SaasBlockIcon = BlockIcon;

const ListFilters = props => (
  <Filter {...props}>
    <SelectInput source="field" choices={saasBlockFields} alwaysOn />
    <TextInput source="value" alwaysOn />
    {clearFilterButton()}
  </Filter>
);

export const SaasBlockList = props => (
  <List
    {...props}
    filters={<ListFilters />}
    perPage={25}
    sort={{ field: "id", order: "DESC" }}
  >
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="field" />
      <TextField source="value" />
    </Datagrid>
  </List>
);
