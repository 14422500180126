import React from "react";
import { Show, SimpleShowLayout, TextField } from "react-admin";
import { DollarField, JsonField } from "../../components";

export const AppraisalApiResultShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="dealerId" />
      <TextField source="appraisalId" />
      <TextField source="apiTypeId" />
      <TextField source="vin" />
      <TextField source="action" />
      <TextField source="sessionKey" />
      <DollarField source="value" />
      <JsonField source="selection" />
      <JsonField source="request" />
      <JsonField source="data" />
    </SimpleShowLayout>
  </Show>
);
