import React from "react";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import {
  BooleanField,
  Button,
  // ChipField,
  DateField,
  EditButton,
  EmailField,
  FunctionField,
  NumberField,
  // ReferenceArrayField,
  // ReferenceField,
  // SingleFieldList,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
} from "react-admin";
// Local
import { REACT_APP_API_URL } from "../../config";
import { fetchJson } from "../../react-admin";

const EditToolbar = props => {
  const { basePath, data /* , resource */ } = props;
  const onClickSendInvitation = React.useCallback(
    /** @param {React.SyntheticEvent<HTMLElement>} e */
    async e => {
      e.stopPropagation();
      const addMessage = data.emailConfirmed
        ? " \n\nNOTE: This user email has already been confirmed."
        : "";
      if (!window.confirm("Send invitation now?" + addMessage)) {
        return;
      }
      const ccEmail = window.prompt("CC email:", "");
      const endpointUrl = `${REACT_APP_API_URL}/admin_users/${data.id}/send-invitation`;
      await fetchJson(endpointUrl, {
        method: "POST",
        body: JSON.stringify({
          ccEmail,
        }),
      })
        .then(() => {
          alert("Invitation sent.");
        })
        .catch(() => {
          alert(
            "There was an error sending the invitation.\n\nPlease try again.",
          );
        });
    },
    [data],
  );
  return (
    <TopToolbar>
      <Button label="Send Invitation" onClick={onClickSendInvitation}>
        <ContactMailIcon />
      </Button>
      <EditButton basePath={basePath} record={data} />
    </TopToolbar>
  );
};

export const AdminUserShow = props => (
  <Show actions={<EditToolbar />} {...props}>
    <SimpleShowLayout>
      <FunctionField
        label="Name"
        render={record => `${record.firstName} ${record.lastName}`}
      />
      {/* <ReferenceArrayField
        label="Roles"
        source="roleIds"
        reference="lists/admin_user_roles"
      >
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField> */}
      <EmailField source="email" />
      <BooleanField source="emailConfirmed" addLabel looseValue />
      <TextField source="phoneNumber" />
      <BooleanField source="phoneNumberConfirmed" addLabel looseValue />
      <TextField source="lockoutEnd" />
      <NumberField source="accessFailedCount" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
      <TextField source="deletedAt" />
    </SimpleShowLayout>
  </Show>
);
