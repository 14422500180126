import React from "react";
import { List, Datagrid, DateField, TextField } from "react-admin";
import EmailIcon from "@material-ui/icons/Email";

export const EmailTemplateIcon = EmailIcon;

export const EmailTemplateList = props => (
  <List
    {...props}
    perPage={25}
    sort={{ field: "id", order: "DESC" }}
    bulkActionButtons={false}
  >
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="description" />
      <DateField source="last_updated_at" showTime />
      <DateField source="created_at" showTime />
    </Datagrid>
  </List>
);
