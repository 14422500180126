// Local
import { makeResource } from "../../react-admin";
import { SaasLeadList, SaasLeadIcon } from "./SaasLeadList";
import { SaasLeadEdit } from "./SaasLeadEdit";
// import { SaasLeadShow } from "./SaasLeadShow";

export const SaasLeads = makeResource({
  name: "saas_leads",
  icon: SaasLeadIcon,
  list: SaasLeadList,
  edit: SaasLeadEdit,
  // show: SaasLeadShow,
  options: {
    category: "saas",
    label: "SaaS Leads",
  },
});
