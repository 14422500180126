// Local

import PaveCredCreate from "./DealerPaveCredCreate";
import PaveCredList, { PaveCredIcon } from "./DealerPaveCredList";

export const DealerPaveCredentials = {
  name: "dealer_pave_credentials",
  list: PaveCredList,
  create: PaveCredCreate,
  icon: PaveCredIcon,
  options: {
    label: "PAVE Credentials",
  },
};
