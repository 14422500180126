import React from "react";
import { TextField } from "react-admin";
// Local
import { formatPhone } from "../../lib";

/**
 * Formatted phone number field without a clickable link.
 * @param {import("react-admin").TextFieldProps} props
 */
export function PhoneTextField(props) {
  const { record = {}, source, ...rest } = props;
  return (
    <TextField
      record={{
        ...record,
        [source]: formatPhone(record[source]),
      }}
      source={source}
      {...rest}
    />
  );
}
PhoneTextField.defaultProps = { ...TextField.defaultProps };
