import React from "react";
import { ReferenceField, Show, SimpleShowLayout, TextField } from "react-admin";

export const MsgTypeShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="description" />
      <ReferenceField
        source="tenantTypeId"
        reference="lists/tenant_types"
        label="Available to"
      >
        <TextField source="name" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
);
