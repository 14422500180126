import React from "react";
import {
  //
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  TextField,
  TextInput,
} from "react-admin";
import { makeStyles } from "@material-ui/core";
// Icons
import MessageIcon from "@material-ui/icons/Message";
// Local
import { FullNameField, clearFilterButton } from "../../components";

export const MsgTemplateIcon = MessageIcon;

const defaultSort = { field: "isDefault", order: "desc" };

export const useStyles = makeStyles(
  theme => ({
    wtext: {
      minWidth: 346,
    },
    binput: {
      marginBottom: 8,
    },
  }),
  {
    classNamePrefix: "MsgTemplateFilter",
  },
);

const MsgTemplateFilter = props => {
  const classes = useStyles();
  return (
    <Filter {...props}>
      <ReferenceInput
        source="msgTypeId"
        reference="msg_types"
        alwaysOn
        label="Type"
        className={classes.wtext}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <TextInput fullWidth source="name" alwaysOn />
      <BooleanInput
        className={classes.binput}
        source="isDefault"
        label="Show defaults only"
        defaultChecked={true}
        defaultValue={true}
      />
      {clearFilterButton()}
    </Filter>
  );
};

export const MsgTemplateList = props => (
  <List
    {...props}
    bulkActionButtons={false}
    exporter={false}
    filters={<MsgTemplateFilter />}
    perPage={25}
    sort={defaultSort}
    title="Message templates"
  >
    <Datagrid rowClick="show">
      <TextField source="name" />
      <TextField source="description" />
      <BooleanField source="isDefault" looseValue label="Default" />
      <ReferenceField label="Type" source="msgTypeId" reference="msg_types">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="publishedAt" label="Last published at" showTime />
      <ReferenceField source="publishedBy" reference="admin_users">
        <FullNameField />
      </ReferenceField>
      <BooleanField
        source="hasChanges"
        looseValue
        label="Has changes to publish"
      />
      <TextField source="id" />
    </Datagrid>
  </List>
);
