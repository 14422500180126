import React from "react";
import {
  EditButton,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  DateField,
} from "react-admin";
// Icon
import EditIcon from "@material-ui/icons/Edit";
// Local
import { ImportDealerDemoRecordsButton } from "./ImportDealerDemoRecords";
import { DeactivateButton, LinkButton } from "../../components";

const EditToolbar = props => {
  const { basePath, data, resource } = props;
  return (
    <TopToolbar>
      <ImportDealerDemoRecordsButton
        basePath={basePath}
        record={data}
        resource={resource}
        style={{ marginRight: "auto" }}
      />
      <DeactivateButton style={{ marginRight: "16px" }} record={data} />
      <LinkButton
        list="dealer_pave_credentials"
        // filter={record => ({ appraisalId: record.id })}
        filter={{ dealerId: data?.id }}
        label="Edit PAVE Credentials"
        startIcon={<EditIcon />}
      />
      <EditButton basePath={basePath} record={data} />
    </TopToolbar>
  );
};

export const DealerShow = props => (
  <Show actions={<EditToolbar />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="publicName" />
      <TextField source="phoneNumber" />
      <TextField source="address1" />
      <TextField source="address2" />
      <TextField source="city" />
      <TextField source="state" />
      <TextField source="zip" />
      <TextField source="logoUrl" />
      <DateField source="deactivatedAt" showTime />
    </SimpleShowLayout>
  </Show>
);
