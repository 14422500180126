import React from "react";
import { List, Datagrid, DateField, TextField } from "react-admin";
import AssessmentIcon from "@material-ui/icons/Assessment";

export const ReportIcon = AssessmentIcon;

export const ReportList = props => (
  <List perPage={25} {...props}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="audience" />
      <DateField source="updatedAt" />
      <DateField source="createdAt" />
    </Datagrid>
  </List>
);
