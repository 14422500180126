import React from "react";
import {
  //
  Edit,
  // ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
// Local
import { saasBlockFields } from "./shared";

export const SaasBlockEdit = props => (
  <Edit mutationMode="pessimistic" {...props}>
    <SimpleForm>
      <SelectInput source="field" choices={saasBlockFields} />
      <TextInput source="value" />
    </SimpleForm>
  </Edit>
);
