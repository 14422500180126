// Local
import { makeResource } from "../../react-admin";
// import { MsgTemplateSmsCreate } from "./MsgTemplateSmsCreate";
// import { MsgTemplateSmsList, MsgTemplateSmsIcon } from "./MsgTemplateSmsList";
import { MsgTemplateIcon } from "../msg_templates/MsgTemplateList";
import { MsgTemplateSmsEdit } from "./MsgTemplateSmsEdit";
// import { MsgTemplateSmsShow } from "./MsgTemplateSmsShow";

export const MsgTemplateSms = makeResource({
  name: "msg_template_sms",
  icon: MsgTemplateIcon,
  // list: MsgTemplateSmsList,
  edit: MsgTemplateSmsEdit,
  // show: MsgTemplateSmsShow,
  // create: MsgTemplateSmsCreate,
  options: {
    label: "Message template SMS",
  },
});
