import React from "react";
import {
  List,
  Datagrid,
  DateField,
  ReferenceField,
  TextField,
} from "react-admin";
import AlarmOnIcon from "@material-ui/icons/AlarmOn";

export const ReportJobIcon = AlarmOnIcon;

export const ReportJobList = props => (
  <List perPage={25} sort={{ field: "id", order: "DESC" }} {...props}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <ReferenceField source="reportId" reference="reports">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="dealerId"
        reference="dealers"
        emptyText="All Dealers"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="name" label="Job name" />
      <TextField source="schedule" />
      <DateField source="updatedAt" label="Updated" />
      <DateField source="createdAt" label="Created" />
    </Datagrid>
  </List>
);
