import React from "react";
import { Typography } from "@material-ui/core";
import {
  AutocompleteArrayInput,
  BooleanInput,
  Create,
  // NumberInput,
  ReferenceArrayInput,
  SelectArrayInput,
  // SelectInput,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";
// Local
import { NonInput } from "../../components";

export const DealerUserCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="show">
      <ReferenceArrayInput
        fullWidth
        label="Dealers"
        source="dealerIds"
        reference="dealers"
        validate={required()}
      >
        <AutocompleteArrayInput optionText="name" fullWidth />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        fullWidth
        label="Roles for all dealers"
        source="roleIds"
        reference="lists/dealer_user_roles"
        // helperText="Remember to add the Dealer Admin or Appraiser role if you want them to see Appraisals."
        validate={required()}
      >
        <SelectArrayInput optionText="name" fullWidth />
      </ReferenceArrayInput>
      <TextInput source="email" validate={required()} fullWidth />
      <TextInput source="firstName" validate={required()} />
      <TextInput source="lastName" validate={required()} />
      <TextInput source="phoneNumber" />
      {/* <NonInput>
        <Typography>
          <em>
            <small>
              Remember to add the <strong>Dealer Admin</strong> or{" "}
              <strong>Appraiser</strong> role if you want them to see
              Appraisals.
            </small>
          </em>
        </Typography>
      </NonInput> */}
      <NonInput>
        <Typography>
          <em>
            <small>
              If you want to CC yourself, don't send the invitation now. You can
              do that on the next screen...
            </small>
          </em>
        </Typography>
      </NonInput>
      <BooleanInput
        source="sendInvitation"
        label="Send invitation now"
        defaultValue={true}
      />
    </SimpleForm>
  </Create>
);
