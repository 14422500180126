import React from "react";
// Local
import "./StripoEditor.css";
import { useStripoApi, StripoEditorOptions } from "./useStripoApi";

export interface StripoEditorProps {
  options: StripoEditorOptions;
}

export const StripoEditor = React.memo(
  /**
   *
   */
  function StripoEditor({ options }: StripoEditorProps) {
    useStripoApi(options);
    return (
      <div className="stripo">
        <div className="stripo__header"></div>
        <div className="stripo__content">
          <div id="stripoSettingsContainer">Loading...</div>
          <div id="stripoPreviewContainer"></div>
        </div>
      </div>
    );
  },
);
/*
<div className="stripo__header">
  <button id="stripoUndoBtn">&lt; undo</button>
  <button id="stripoRedoBtn">redo &gt;</button>
  <button id="stripoCodeBtn">code</button>
</div>
*/
