import React from "react";
import { NumberField } from "react-admin";

/** @type {Intl.NumberFormatOptions} */
const options = { useGrouping: true };
/**
 * @param {import("react-admin").NumberFieldProps} props
 */
export function ThousandsField(props) {
  return <NumberField options={options} {...props} />;
}
ThousandsField.defaultProps = { ...NumberField.defaultProps };
