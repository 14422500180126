import type { FileSaverOptions } from "file-saver";

declare global {
  interface Window {
    saveAs: typeof import("file-saver").saveAs;
    JSZip: typeof import("jszip");
  }
}

const sources = {
  filesaver:
    "https://cdn.jsdelivr.net/npm/file-saver@2.0.5/dist/FileSaver.min.js",
  jszip: "https://cdn.jsdelivr.net/npm/jszip@3.10.1/dist/jszip.min.js",
} as const;

type ScriptSrc = keyof typeof sources;

const loaded: Record<ScriptSrc, boolean> = {
  filesaver: !!window.saveAs,
  jszip: !!window.JSZip,
};

const loading: Partial<Record<ScriptSrc, Promise<boolean>>> = {};

export namespace FileSaver {
  export type Data = Blob | File | DataUrl;
  export type DataUrl = string;
  export interface ZipData {
    /** Name or path of file or folder. */
    name: string;
    /** The file data or url to save. */
    data?: Data;
    /** Files to zip into the folder/path indicated by `name`. */
    files?: ZipData[];
  }
  /**
   * Loads FileSaver.js (https://www.jsdelivr.com/package/npm/file-saver)
   * and uses it to save your file.
   * @see https://github.com/eligrey/FileSaver.js
   * @param file A `Blob/File` to save or a URL `string` to download and save.
   * @param name Name of the file. If omitted, the name used in any `File` data
   * will be used. If none is provided "download" will be used.
   * @param opts Optional FileSaver.js config. Default is `{ autoBom: false }`
   */
  export async function saveAs(
    file: Data,
    name?: string,
    opts?: FileSaverOptions,
  ) {
    await loadScript("filesaver");
    window.saveAs(file, name, opts);
    // CONSIDER: Pass 4th arg above, popup?:Window if it's needed. See code at
    // https://github.com/eligrey/FileSaver.js/blob/cea522bc41bfadc364837293d0c4dc585a65ac46/src/FileSaver.js#L131
  }
  /**
   * Loads JSZip (https://github.com/Stuk/jszip) and uses it to bundle your
   * files and save them as a zip file.
   */
  export async function saveAsZip(files: ZipData[], name?: string) {
    await Promise.all([loadScript("filesaver"), loadScript("jszip")]);
    const zip = new window.JSZip();
    const { length } = files;
    for (let i = 0; i < length; i++) {
      const { name, data, files: children } = files[i];
      if (!children) {
        if (data) {
          zip.file(name, data);
        }
      } else {
        const folder = zip.folder(name) ?? zip;
        const { length } = children;
        for (let j = 0; j < length; j++) {
          const { name, data } = children[j];
          if (data) {
            folder.file(name, data);
          }
        }
      }
    }
    const blob = await zip.generateAsync({ type: "blob" });
    // console.log("Saving zip file...", name);
    window.saveAs(blob, name);
  }
}

async function loadScript(scriptSrc: ScriptSrc) {
  if (loaded[scriptSrc]) {
    return Promise.resolve(true);
  }
  const promise =
    loading[scriptSrc] ??
    (loading[scriptSrc] = new Promise(function loadScriptAsync(
      resolve,
      reject,
    ) {
      try {
        const script = document.createElement("script");
        script.id = `${scriptSrc}Script`;
        script.type = "text/javascript";
        script.src = sources[scriptSrc];
        script.onload = function scriptLoaded() {
          // console.log("Loaded", scriptSrc);
          loaded[scriptSrc] = true;
          delete loading[scriptSrc];
          resolve(true);
        };
        script.onerror = function scriptErr(
          _event: string | Event,
          _source?: string | undefined,
          _lineno?: number | undefined,
          _colno?: number | undefined,
          error?: Error | undefined,
        ) {
          console.error(scriptSrc, error);
          delete loading[scriptSrc];
          reject(error);
        };
        document.body.appendChild(script);
      } catch (ex) {
        delete loading[scriptSrc];
        reject(ex);
      }
    }));
  return promise;
}
