import React from "react";
// import {
//   SimpleShowLayout,
//   FunctionField,
//   getFieldLabelTranslationArgs,
//   useShowContext,
//   useTranslate,
// } from "react-admin";

export const JsonField = React.memo(
  /**
   * @typedef {import("react-admin").TextInputProps} TextInputProps
   * @typedef {object} JsonInputProps
   * @property {any} emptyValue
   *
   * @param {JsonInputProps & TextInputProps} param0
   */
  function JsonField({ record = {}, source, ...props }) {
    // const { resource } = useShowContext();
    const srcVal = record[source];
    if (!srcVal) {
      return "null";
    }
    const varObj = JSON.parse(record[source], null, 2);
    // const translate = useTranslate();
    // const jsonObject = Object.keys(varObj).map(key => {
    //   return (
    //     <FunctionField
    //       key={key}
    //       label={translate(
    //         ...getFieldLabelTranslationArgs({ resource, source: key }),
    //       )}
    //       render={record => `${varObj[key]} `}
    //     />
    //   );
    // });
    // return <SimpleShowLayout>{jsonObject}</SimpleShowLayout>;
    return (
      <pre>
        {varObj === null ? "null" : JSON.stringify(varObj, undefined, 2)}
      </pre>
    );
  },
);
JsonField.defaultProps = {
  addLabel: true,
};
