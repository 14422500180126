import React from "react";
import { List, Datagrid, TextField } from "react-admin";
import BusinessIcon from "@material-ui/icons/Business";

export const BankIcon = BusinessIcon;

export const BankList = props => (
  <List perPage={25} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
    </Datagrid>
  </List>
);
