import React from "react";
// import { MenuItemLink } from "react-admin";
// import { useSelector } from "react-redux";
import {
  //
  Menu,
  // getResources,
} from "react-admin";
// Local
// import { categories, noCategory } from "./categories";

export function AppMenu(props) {
  // /** @type {ExtendedResourceProps[]} */
  // const resources = useSelector(getResources);

  // TODO: Render menu items inside of their categories.
  // See notes at top of ./categories.ts for reference material.

  // return (
  //   <Menu {...props}>
  //     {categories.map(it => {
  //       const { icon: ItemIcon } = it;
  //       return (
  //         <MenuItemLink
  //           key={it.name}
  //           to={it.url ?? ""}
  //           primaryText={it.label}
  //           leftIcon={<ItemIcon />}
  //           onClick={props.onClick}
  //         />
  //       );
  //     })}
  //     {noCategory.menuItems.map(it => {
  //       const { icon: ItemIcon } = it;
  //       return (
  //         <MenuItemLink
  //           key={it.name}
  //           to={it.url}
  //           primaryText={it.label}
  //           leftIcon={<ItemIcon />}
  //           onClick={props.onClick}
  //         />
  //       );
  //     })}
  //   </Menu>
  // );
  return <Menu {...props} />;
}
