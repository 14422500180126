import React from "react";
import {
  AutocompleteInput,
  Create,
  ReferenceInput,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";
// Local
import { caption } from "../../components";

export const ReportJobCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="edit">
      <ReferenceInput
        source="reportId"
        reference="reports"
        filterToQuery={searchText => ({ name: searchText })}
        validate={required()}
        fullWidth
      >
        <AutocompleteInput
          optionText="name"
          helperText="Choose the report to run for this job."
        />
      </ReferenceInput>
      <ReferenceInput
        source="dealerId"
        reference="dealers"
        filterToQuery={searchText => ({ name: searchText })}
        fullWidth
        allowEmpty
        emptyText="All Dealers"
      >
        <AutocompleteInput
          optionText="name"
          resettable
          clearAlwaysVisible
          allowEmpty
          helperText="Choose a specific dealer to run the report for or leave blank to run for all dealers."
        />
      </ReferenceInput>
      <TextInput
        source="name"
        fullWidth
        helperText="An optional name for this report job."
      />
      {caption(
        "Save the job first, in order to add parameters and distributions...",
      )}
    </SimpleForm>
  </Create>
);
