import React from "react";
import {
  ArrayInput,
  AutocompleteInput,
  Edit,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  required,
  useNotify,
} from "react-admin";
import {
  //
  Box,
  // Button,
  Link,
  Typography,
} from "@material-ui/core";
// Icons
import ShowExamplesIcon from "@material-ui/icons/KeyboardArrowDown";
import HideExamplesIcon from "@material-ui/icons/KeyboardArrowUp";
// Local
import { returnBlankString } from "../../lib";
import { AddItemButton, NonInput, RemoveItemButton } from "../../components";
import { useHorizontalArrayFormStyle } from "../../react-admin";
import { ReportParamInput } from "./ReportParamInput";

export const distributionTypes = [
  { id: "email", name: "Email" },
  { id: "email_file", name: "Email attachment" },
  // { id: "ftp", name: "FTP" },
];

const cronExamples = `0 8 * * *        8am daily.
5 0 * * *        12:05am daily.
15 14 1 * *      2:15pm on the 1st of each month.
59 23 L * *      11:59pm at the end of each month.
0 9 * * mon-fri  9:00am weekdays.
*/30 * * * *     Every 30 minutes.`;
const cronPositionsHelp = `
minute   hour   day-of-month       month           weekday
(0-59)  (0-23)   (1-31, L)    (1-12, jan-dec)   (0-7, sun-sat)`.trim();

const styles = {
  pre: {
    display: "inline-block",
    margin: 0,
    marginTop: 4,
  },
  showExamples: {
    display: "inline-flex",
    alignItems: "center",
  },
};

export function ReportJobEdit(props) {
  const notify = useNotify();
  const paramsArrayClasses = useHorizontalArrayFormStyle();

  const onSuccess = React.useCallback(() => {
    notify("ReportJob saved.", { type: "success" });
  }, [notify]);

  const [showingScheduleExamples, showScheduleExamples] = React.useState(false);

  const onClickShowScheduleExamples = React.useCallback(
    /** @param {React.SyntheticEvent<HTMLAnchorElement>} e */
    e => {
      e.preventDefault();
      showScheduleExamples(value => !value);
    },
    [],
  );

  return (
    <Edit mutationMode="pessimistic" onSuccess={onSuccess} {...props}>
      <SimpleForm>
        <ReferenceInput
          source="reportId"
          reference="reports"
          filterToQuery={searchText => ({ name: searchText })}
          validate={required()}
          fullWidth
        >
          <AutocompleteInput
            optionText="name"
            helperText="If you change the report, save immediately to see that report's params! Matching param values will be preserved."
          />
        </ReferenceInput>
        <ReferenceInput
          source="dealerId"
          reference="dealers"
          filterToQuery={searchText => ({ name: searchText })}
          fullWidth
          allowEmpty
          emptyText="All Dealers"
        >
          <AutocompleteInput
            optionText="name"
            resettable
            clearAlwaysVisible
            allowEmpty
            helperText="Choose a specific dealer to run the report for or leave blank to run for all dealers."
          />
        </ReferenceInput>
        <TextInput
          source="name"
          fullWidth
          helperText={
            "NOTE: Report job or report names are used as " +
            "notification identifiers. Do not change once in use!"
          }
        />
        <TextInput
          source="schedule"
          fullWidth
          helperText="Enter minute, hour, day-of-month, month and weekday values in Eastern time."
        />
        <NonInput>
          <Box
            bgcolor="#feffe8"
            border="1px solid lightgrey"
            br="3px"
            mt="-6px"
            p="6px 12px"
          >
            <Typography component="div" variant="body2" color="textSecondary">
              Above: Enter a value or asterisk (*) for each position shown,
              separated by a space.{" "}
              <em>
                Names may be used for <strong>month</strong> and{" "}
                <strong>weekday</strong> e.g. <code>(jan-dec, sun-sat)</code>.
                Use <code>L</code> for last <strong>day-of-month</strong>. See
                also:{" "}
              </em>
              <Link
                href="https://github.com/node-cron/node-cron#cron-syntax"
                rel="noopener noreferrer"
                // style={{ marginLeft: 14 }}
                target="blank"
                variant="inherit"
                color="textSecondary"
                underline="always"
              >
                Advanced scheduling.
              </Link>
            </Typography>
            <pre style={styles.pre}>{cronPositionsHelp}</pre>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Link
              href="#"
              onClick={onClickShowScheduleExamples}
              color="textSecondary"
              variant="body2"
              style={styles.showExamples}
              underline="always"
            >
              Examples{" "}
              {showingScheduleExamples ? (
                <HideExamplesIcon />
              ) : (
                <ShowExamplesIcon />
              )}
            </Link>
            {showingScheduleExamples && (
              <>
                <hr />
                <pre style={styles.pre}>{cronExamples}</pre>
              </>
            )}
          </Box>
        </NonInput>
        <ArrayInput source="spec.params" label="Report params">
          <SimpleFormIterator
            classes={paramsArrayClasses}
            disableAdd
            disableRemove
            disableReordering
            getItemLabel={returnBlankString}
          >
            <ReportParamInput source="value" />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="spec.distributions" label="Distributions">
          <SimpleFormIterator
            addButton={<AddItemButton label="Add distribution" />}
            removeButton={<RemoveItemButton />}
            getItemLabel={returnBlankString}
          >
            <SelectInput
              source="type"
              label="Type"
              choices={distributionTypes}
              validate={required()}
            />
            <ReferenceInput
              source="mailTemplateId"
              label="Email template"
              reference="email_templates"
              filterToQuery={searchText => ({ nameId: searchText })}
              fullWidth
              allowEmpty
            >
              <AutocompleteInput
                optionText="nameId"
                resettable
                clearAlwaysVisible
                allowEmpty
                // handleHomeEndKeys={false}
              />
            </ReferenceInput>
            <ReferenceArrayInput
              source="mailToRoleIds"
              label="Roles to email"
              reference="lists/roles"
              fullWidth
              allowEmpty
            >
              <SelectArrayInput optionText="name" fullWidth />
            </ReferenceArrayInput>
            <TextInput
              source="mailTo"
              label="Email addresses"
              placeholder="the@email.com, CC:your@email.com, BCC:logs@email.com"
              helperText={
                "Optional comma-separated list of email addresses. " +
                "Each email may optionally be prefixed with CC: or BCC:"
              }
              fullWidth
            />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
}
