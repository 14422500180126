import React from "react";
import { UrlField } from "react-admin";
// Local
import { formatPhone } from "../../lib";

/**
 * Formatted phone number field with a clickable `tel:` link.
 * @param {import("react-admin").UrlFieldProps} props
 */
export function PhoneField(props) {
  const { record = {}, source, ...rest } = props;
  const phoneNum = record[source];
  return (
    <UrlField
      record={{
        ...record,
        [source]: formatPhone(phoneNum),
      }}
      source={source}
      href={`tel:${phoneNum}`}
      {...rest}
    />
  );
}
PhoneField.defaultProps = { ...UrlField.defaultProps };
