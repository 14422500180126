// Local
import DealerSettingEdit from "./DealerSettingEdit";
import DealerSettingList, { DealerSettingIcon } from "./DealerSettingList";

export const DealerSettings = {
  name: "dealer_settings",
  list: DealerSettingList,
  edit: DealerSettingEdit,
  icon: DealerSettingIcon,
  options: {
    label: "Dealer settings",
  },
};
