import React from "react";
import { Button } from "react-admin";
import { Link } from "react-router-dom";

export const LinkButton = React.memo(
  /**
   * @typedef {object} LinkButtonProps
   * @property {string} [list] List resource name
   * @property {any}  [filter] Filter for list resource
   * @property {string} [show] Show resource name
   * @property {any}  [showId] Show resource id
   *
   * @param {import("react-admin").ButtonProps & LinkButtonProps} param0 */
  function LinkButton({
    children,
    record,
    list,
    filter,
    show,
    showId,
    ...rest
  }) {
    if (list) {
      const isFn = typeof filter === "function";
      if (!filter || (isFn && !record)) {
        return null;
      }
      return (
        <Button
          color="primary"
          component={Link}
          to={{
            pathname: `/${list}`,
            search: `filter=${JSON.stringify(isFn ? filter(record) : filter)}`,
          }}
          {...rest}
        >
          {children}
        </Button>
      );
    }
    return (
      <Button
        color="primary"
        component={Link}
        onClick={stopPropagation}
        to={{
          pathname: `/${show}/${showId}/show`,
        }}
        {...rest}
      >
        {children}
      </Button>
    );
  },
);

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = e => e.stopPropagation();
