import React from "react";
import { Edit, SimpleForm, TextInput } from "react-admin";

export const AutoInsuranceCompanyEdit = props => (
  <Edit mutationMode="pessimistic" {...props}>
    <SimpleForm>
      <TextInput source="name" autoFocus />
    </SimpleForm>
  </Edit>
);
