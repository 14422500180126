import { AdminUsers } from "./admin_users";
import { AppraisalDealTypes } from "./appraisal_deal_types";
import { Appraisals } from "./appraisals";
import { AppraisalApiResults } from "./appraisal_api_results";
import { AutoInsuranceCompanies } from "./auto_insurance_companies";
import { Banks } from "./banks";
// import { Customers } from "./customers";
// import { CustomerUsers } from "./customer_users";
// import { DealActivity } from "./deal_activity";
import { DealerCampaigns } from "./dealer_campaigns";
import { DealerBanks } from "./dealer_banks";
import { DealerBidders } from "./dealer_bidders";
import { Dealers } from "./dealers";
// import { Deals } from "./deals";
import { DealerLinkages } from "./dealer_linkages";
import { DealerSettings } from "./dealer_settings";
// import { DealerStock } from "./dealer_stock";
// import { DealerStockSources } from "./dealer_stock_sources";
import { DealerUsers } from "./dealer_users";

import { MsgParties } from "./msg_parties";
import { MsgTemplatePush } from "./msg_template_push";
import { MsgTemplateSms } from "./msg_template_sms";
import { MsgTemplates } from "./msg_templates";
import { MsgTypes } from "./msg_types";
import { MsgVersions } from "./msg_versions";

import { DealerMsgTemplatePush } from "./dealer_msg_template_push";
import { DealerMsgTemplateSms } from "./dealer_msg_template_sms";
import { DealerMsgTemplates } from "./dealer_msg_templates";
import { DealerMsgVersions } from "./dealer_msg_versions";

import { EmailTemplates } from "./email_templates";
import { Notifications } from "./notifications";
// import { PaveWebhooks } from "./pave_webhooks";
import { Reminders } from "./reminders";
import { ReportJobs } from "./report_jobs";
import { Reports } from "./reports";
import { SaasBlocks } from "./saas_blocklist";
import { SaasLeads } from "./saas_leads";
import { SaasStats } from "./saas_stats";
import { Settings } from "./settings";
import { Tenants } from "./tenants";
import { TwilioMessages } from "./twilio_messages";
// Lists:
import { default as lists } from "./lists";
import { DealerPaveCredentials } from "./dealer_pave_credentials";

export const resources = [
  //
  // In order of appearance on the menu.
  // The first item will be the default.
  //
  SaasStats,
  SaasLeads,
  SaasBlocks,
  Appraisals,
  AppraisalApiResults,
  AppraisalDealTypes,
  Dealers,
  Notifications,
  Reminders,
  DealerBanks,
  DealerBidders,
  DealerCampaigns,
  DealerLinkages,
  DealerSettings,
  DealerUsers,

  // DealerStock,
  // DealerStockSources,
  // Deals,
  // DealActivity,

  // Customers,
  // CustomerUsers,

  // Msg
  MsgParties,
  MsgTypes,
  MsgTemplates,
  MsgTemplateSms,
  MsgTemplatePush,
  MsgVersions,
  // Dealer Msg
  DealerMsgTemplates,
  DealerMsgTemplatePush,
  DealerMsgTemplateSms,
  DealerMsgVersions,

  AdminUsers,
  Settings,
  Tenants,
  TwilioMessages,
  EmailTemplates,
  Reports,
  ReportJobs,

  AutoInsuranceCompanies,
  Banks,
  // PaveWebhooks,
  DealerPaveCredentials,
  ...lists,

  // Hidden:
] as ExtendedResourceProps[];
export default resources;
