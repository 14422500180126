import React from "react";
import {
  //
  Box,
  ButtonGroup,
  Divider,
  makeStyles,
} from "@material-ui/core";
import {
  BooleanField,
  Button,
  DateField,
  EditButton,
  Labeled,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  useRefresh,
} from "react-admin";
// Icons
import NextIcon from "@material-ui/icons/SkipNext";
import PrevIcon from "@material-ui/icons/SkipPrevious";
import PublishIcon from "@material-ui/icons/Publish";
import RestoreIcon from "@material-ui/icons/Restore";
// Local
import {
  //
  BoolShowField,
  FullNameField,
  LinkButton,
  NonField,
  useBasicDialog,
} from "../../components";
import { formatThousands } from "../../lib";
import { republishVersion, restoreVersion } from "../msg_templates/api";
import { MsgRuleFields } from "../msg_templates/MsgRuleFields";

const useToolStyles = makeStyles(
  theme => ({
    tbarleft: { display: "flex", marginRight: "auto" },
    disabled: {
      borderColor: "lightgrey",
      color: "lightgrey",
    },
    filler: {
      flexGrow: 1,
    },
    spacer: {
      minWidth: 8,
    },
  }),
  {
    classNamePrefix: "MsgVersionTools",
  },
);
/** @param {import("react-admin").ToolbarProps} props */
function MsgVersionTools(props) {
  // console.log("TB", props);
  const { basePath, resource } = props;
  /** @type {import("../msg_templates/types").MsgVersion} */
  const data = props.data ?? {};

  const classes = useToolStyles();
  // const filler = <div className={classes.filler}></div>;
  const spacer = <div className={classes.spacer}></div>;

  const {
    alertAsync,
    basicDialog,
    confirmAsync,
    // promptAsync,
    // showDialogAsync,
  } = useBasicDialog();
  const refresh = useRefresh();

  const onClickRestore = React.useCallback(
    /** @param {React.SyntheticEvent<HTMLButtonElement>} e */
    async e => {
      if (
        !(await confirmAsync({
          title: "Restore message template?",
          text: (
            <>
              Do you want to restore the message template to this version?
              <br />
              <br />
              This will allow you to edit this version of the template, but it
              will <strong>NOT</strong> republish this version of the template.
            </>
          ),
        }))
      ) {
        return;
      }
      await restoreVersion(data);
      refresh();
    },
    [data, confirmAsync, refresh],
  );

  const onClickPublish = React.useCallback(
    /** @param {React.SyntheticEvent<HTMLButtonElement>} e */
    async e => {
      if (data.isCurrentPub) {
        await alertAsync({
          title: "Already published",
          text: "This version is already the currently published version.",
        });
        return;
      }
      if (
        !(await confirmAsync({
          title: "Republish message version?",
          text: (
            <>
              Do you want to republish this version of the message?
              <br />
              <br />
              Messages sent with this template will use this version after you
              confirm.
            </>
          ),
        }))
      ) {
        return;
      }
      await republishVersion(data);
      refresh();
    },
    [data, alertAsync, confirmAsync, refresh],
  );

  return (
    <TopToolbar>
      {basicDialog}
      <div className={classes.tbarleft}>
        <LinkButton
          list="msg_versions"
          filter={{ templateId: data?.templateId }}
          label={`History (${formatThousands(data?.pubCount ?? 0)})`}
          title="Show version history of this template."
          variant="outlined"
          style={{ marginRight: "auto" }}
          startIcon={<RestoreIcon />}
          size="small"
        />
        {spacer}
        <ButtonGroup color="primary" size="small">
          <LinkButton
            label="Previous version"
            title="Go to the previous version"
            show="msg_versions"
            showId={data?.prevId}
            variant="outlined"
            startIcon={<PrevIcon />}
            disabled={!data?.prevId}
          />
          <LinkButton
            label="Next version"
            title="Go to the next version"
            show="msg_versions"
            showId={data?.nextId}
            variant="outlined"
            startIcon={<NextIcon />}
            disabled={!data?.nextId}
          />
        </ButtonGroup>
        {spacer}
        <ButtonGroup color="primary" size="small">
          <Button
            label="Restore"
            onClick={onClickRestore}
            title="Restore the template contents to this version for editing."
          >
            <RestoreIcon />
          </Button>
          <Button
            className={data?.isCurrentPub ? classes.disabled : undefined}
            label="Republish"
            title={
              data?.isCurrentPub
                ? "This version is already the currently published version!"
                : "Make this version of the message the current published version."
            }
            onClick={onClickPublish}
          >
            <PublishIcon />
          </Button>
        </ButtonGroup>
      </div>
      <EditButton
        basePath={basePath}
        record={data}
        resource={resource}
        label="Edit summary"
        title="Edit the summary of changes made in this version."
      />
    </TopToolbar>
  );
}

const useStyles = makeStyles(
  theme => ({
    root: {
      // margin: 0,
    },
    hfields: {
      display: "flex",
      alignItems: "flex-start",
      "& > .MuiFormControl-root": {
        marginRight: 16,
      },
    },
  }),
  {
    classNamePrefix: "MsgVersionShow",
  },
);

export function MsgVersionShow(props) {
  const classes = useStyles();
  return (
    <Show {...props} actions={<MsgVersionTools />}>
      <SimpleShowLayout>
        <div className={classes.hfields}>
          <Labeled label="Template">
            <ReferenceField
              source="templateId"
              reference="msg_templates"
              label="Template"
              link="show"
            >
              <TextField source="name" />
            </ReferenceField>
          </Labeled>
          <Labeled label="Version">
            <TextField source="id" label="Version" />
          </Labeled>
          <Labeled label="Current">
            <BooleanField source="isCurrentPub" label="Current" looseValue />
          </Labeled>
          <Labeled label="Published">
            <DateField source="publishedAt" showTime />
          </Labeled>
          <Labeled label="by">
            <ReferenceField source="publishedBy" reference="admin_users">
              <FullNameField />
            </ReferenceField>
          </Labeled>
          <Labeled label="Summary">
            <TextField source="summary" />
          </Labeled>
        </div>
        <NonField children={<Divider />} />
        <NonField>
          <Box color="rgba(0, 0, 0, 0.54)">
            <small>Contents</small>
          </Box>
        </NonField>
        <BoolShowField source="email.enabled" label="Email" />
        <BoolShowField source="sms.enabled" label="SMS" />
        <BoolShowField source="push.enabled" label="Push" />
        <NonField children={<Divider />} />
        <div className={classes.hfields}>
          <Labeled label="Type of message">
            <ReferenceField
              source="msgTypeId"
              reference="msg_types"
              label="Message type"
              link="show"
            >
              <TextField source="name" />
            </ReferenceField>
          </Labeled>
          <Labeled label="This message template is a default for the type of message">
            <BooleanField
              source="isDefault"
              label="This message template is a default for the type of message"
              looseValue
            />
          </Labeled>
        </div>
        <MsgRuleFields />
      </SimpleShowLayout>
    </Show>
  );
}
