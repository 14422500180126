import React from "react";
import {
  //
  // Box,
  Button,
  ButtonGroup,
  // Checkbox,
  // Chip,
  // Card,
  // CardContent,
  Divider,
  // FormControlLabel,
  // IconButton,
  Menu,
  MenuItem,
  Toolbar,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import {
  //
  Title,
  setSidebarVisibility,
  // useQuery,
} from "react-admin";
import { useDispatch } from "react-redux";
import { Link, Prompt, useParams } from "react-router-dom";
// Icons
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CodeIcon from "@material-ui/icons/Code";
import CheckedIcon from "@material-ui/icons/CheckBox";
import DiscardIcon from "@material-ui/icons/DeleteForever";
import HelpIcon from "@material-ui/icons/HelpOutline";
import MenuIcon from "@material-ui/icons/MoreHoriz";
// import EditIcon from "@material-ui/icons/Edit";
// import HistoryIcon from "@material-ui/icons/History";
import PreviewIcon from "@material-ui/icons/Devices";
import RedoIcon from "@material-ui/icons/Redo";
import SaveIcon from "@material-ui/icons/Save";
import SendIcon from "@material-ui/icons/Send";
import UndoIcon from "@material-ui/icons/Undo";
import UncheckedIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import VariablesIcon from "@material-ui/icons/ViewStream";
// Local
import { useBasicDialog } from "../../components";
import {
  authState,
  // goBackOrReplace,
  reloadPage,
} from "../../react-admin";
import {
  //
  useOnMount,
  useOnMountAsync,
  useKeyDown,
  // useInputCheck,
} from "../../lib";
import { FileSaver } from "../../lib/files/FileSaver";
import { StripoEditor } from "../../lib/stripo-editor";
import {
  //
  LiquidError,
  // buildMsgValues,
  connectStripo,
  initEmailEdit,
  replaceMsgTokens,
  saveEmailEdit,
  sendPreviewEmail,
} from "../../resources/msg_templates/api";
import { MsgTemplateEditEmailForm } from "./MsgTemplateEditEmailForm";
import { MsgTemplateEmailPreview } from "./MsgTemplateEmailPreview";
import { MsgTemplateEditVars } from "./MsgTemplateEditVars";
/**
 * Types
 * @typedef {import("../../resources/msg_templates/types").MsgTemplate} MsgTemplate
 * @typedef {import("../../resources/msg_templates/types").MsgVar} MsgVar
 * @typedef {import("../../resources/msg_templates/api").ReplaceMsgTokensOptions} ReplaceMsgTokensOptions
 */

/** @type {MsgTemplate["email"]} */
const defaultEmailInfo = {
  enabled: false,
  subject: "Your subject here...",
};
const defaultEmailSenderName = "Turnyn";
/** @type {MsgTemplate["editor"]} */
const defaultEditorInfo = {};
/** @type {MsgTemplate["spec"]} */
const defaultSpecInfo = {
  vars: {},
};

/**
 * @typedef {import("../../lib/stripo-editor").StripoEditorOptions} StripoEditorOptions
 *
 * @param {MsgTemplate} template
 * @param {StripoEditorOptions} [options]
 */
async function loadEmailEditor(templateId, options) {
  const isDealer = options.isDealer ?? !!options.dealerId;
  const { template, html, css, conditionCategories, mergeTags } =
    await initEmailEdit(templateId, isDealer);
  if (isDealer) {
    options.isDealer = true; // in case it was set via !!options.dealerId above.
    options.dealerId = template.dealerId;
  }

  /** @type {StripoEditorOptions} */
  const editorOptions = {
    // #region Required
    connectStripo,
    // debugInit: true,
    ownerType: isDealer ? "dealer" : "admins",
    templateUid: template.uid,
    user: authState.user,
    // #endregion
    // #region UI Integration
    // NOTE: codeEditorButtonId should match a button id in your own UI.
    codeEditorButtonId: "stripoCodeBtn",
    redoButtonId: "stripoRedoBtn",
    undoButtonId: "stripoUndoBtn",
    defaultImgForMergeTagSrc: "/msg-placeholder.jpg",
    editorFonts: {
      showDefaultNotStandardFonts: true,
      showDefaultStandardFonts: true,
      customFonts: [
        {
          name: "Poppins",
          fontFamily: "'Poppins',sans-serif",
          url: "https://fonts.googleapis.com/css?family=Poppins",
        },
      ],
    },
    // #endregion
    // #region Merge tags
    mergeTags,
    // mergeTagsOptions: {
    //   useIcon: true,
    //   title: "Merge tags",
    // },
    // #endregion
    // #region Display conditions
    conditionsEnabled: true,
    customConditionsEnabled: true,
    conditionCategories,
    // #endregion
    // #region Experimental
    // versionHistory: {
    //   // changeHistoryLinkId: "stripoHistBtn",
    //   onInitialized(info) {
    //     console.log("init hist", info);
    //   },
    // },
    // #endregion
    ...options,
  };
  // console.log("LOADED", {
  //   templateId,
  //   data,
  //   html,
  //   css,
  // });
  editorOptions.html = html;
  editorOptions.css = css;
  return {
    template,
    editorOptions,
  };
}

/** @param {Event} e */
function notifyUnsavedChanges(e) {
  // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
  if (e.preventDefault) e.preventDefault();
  const returnValue = "You have unsaved changes...";
  e.returnValue = returnValue;
  return returnValue;
}

const useStyles = makeStyles(
  theme => ({
    body: {
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    root: {
      minWidth: 1100,
    },
    ibtn: {
      // padding: 4,
      minWidth: 48,
    },
    mcat: {
      backgroundColor: "lightgrey",
      cursor: "default",
    },
    check: {
      color: "#3f51b5",
      marginRight: 8,
      "& .MuiCheckbox-root": {
        color: "#3f51b5",
        paddingRight: 0,
      },
      "& .MuiFormControlLabel-label": {
        color: "#3f51b5",
      },
      "& .MuiSvgIcon-root": {
        color: "#3f51b5",
      },
    },
    saving: {
      minWidth: 177,
      color: "#3f51b5",
    },
    filler: {
      flexGrow: 1,
    },
    spacer: {
      minWidth: 8,
    },
    stripoBtn: {
      "&[disabled]": {
        // Necessary to show the button as disabled when Stripo disables it,
        // (Since it's not our React code doing it..)
        color: "lightgrey",
      },
    },
    details: {
      display: "inline-flex",
      alignItems: "center",
      borderColor: theme.palette.primary.main,
      border: "1px solid",
      borderRadius: 4,
      "&[disabled]": {
        borderColor: "lightgrey",
      },
    },
    detail: {
      // border: "1px solid #3f51b5",
      // borderRadius: 4,
      padding: "0 9px",
      // marginLeft: 4,
      // marginRight: 4,
      cursor: "pointer",

      // For SUBJECT and FROM detail mainly...
      maxWidth: 400,
      textOverflow: "ellipsis",
      overflowX: "hidden",
      whiteSpace: "nowrap",

      "&:not(:last-child)": {
        borderRight: "1px solid",
        borderRightColor: theme.palette.primary.main,
      },

      "&[disabled]": {
        borderRightColor: "lightgrey",
        color: "lightgrey",
      },

      "& > button": {
        minWidth: 0,
      },
    },
    // editorContent: {},
  }),
  {
    classNamePrefix: "MsgTemplateEditEmailPage",
  },
);

/** @type {import("@material-ui/core").PopoverOrigin} */
const dropdownOrigin = {
  vertical: "bottom",
  horizontal: "left",
};
/** @type {import("@material-ui/core").PopoverOrigin} */
const dropdownTransformOrigin = {
  vertical: "top",
  horizontal: "left",
};

export const MsgTemplateEditEmailPage = React.memo(
  /**
   * @param {import("react-router-dom").RouteChildrenProps}
   */
  function MsgTemplateEditEmailPage({ location }) {
    const isDealer = !!location?.pathname?.startsWith("/dealer_");
    const classes = useStyles();
    const wide = useMediaQuery("(min-width:1532px)");
    const filler = <div className={classes.filler}></div>;
    const spacer = <div className={classes.spacer}></div>;

    const {
      alertAsync,
      basicDialog,
      confirmAsync,
      promptAsync,
      showDialogAsync,
    } = useBasicDialog();

    const dispatch = useDispatch();
    const { id: idParam } = useParams();

    /** @type {[MsgTemplate | undefined, (s?:MsgTemplate=>MsgTemplate | undefined)]} */
    const [template, setTemplate] = React.useState();
    const [emailInfo, setEmailInfo] = React.useState(defaultEmailInfo);
    const [editorInfo, setEditorInfo] = React.useState(defaultEditorInfo);
    const [specInfo, setSpecInfo] = React.useState(defaultSpecInfo);
    /** @type {[{title:string,code?:string}[]]} */
    const [varItems, setVarItems] = React.useState([]);
    const [editorOptions, setEditorOptions] = React.useState();

    // const [loaded, setLoaded] = React.useState(false);
    const [ready, setReady] = React.useState(false);
    const [changed, setChanged] = React.useState(false);
    // const [codeVisible, setCodeVisible] = React.useState(false);

    const { templateId, exportLocalFiles, save, saveAndReload } =
      React.useMemo(() => {
        const templateId = parseInt(idParam);
        /**
         * @param {MsgTemplate} template
         * @param {{
         * emailInfo:MsgTemplate["email"],
         * editorInfo:MsgTemplate["editor"]
         * specInfo:MsgTemplate["spec"]
         * }} data
         */
        function exportLocalFiles(template, data) {
          // console.log("Exporting local files...");
          setReady(false);
          const {
            email: origEmail,
            editor: origEditor,
            spec: origSpec,
          } = template;
          const { emailInfo, editorInfo, specInfo } = data;
          // Merge template originals with edited data to preserve all fields.
          const email = { ...origEmail, ...emailInfo };
          const editor = { ...origEditor, ...editorInfo };
          const spec = { ...origSpec, ...specInfo };
          return new Promise((resolve, _reject) => {
            // console.log("Getting template html for local export...");
            window.StripoApi.getTemplate(function expTemplate(
              emailEditHtml,
              emailEditCss,
              width,
              height,
            ) {
              email.height = height;
              email.width = width;
              const minimizeCompiledEmail = true;
              window.StripoApi.compileEmail(async function compileExpTemplate(
                error,
                emailHtml,
                emailAmp,
                // ampErrors,
              ) {
                if (error) {
                  console.error("Error compiling email", error);
                  await alertAsync({
                    title: "Error",
                    text:
                      "There was an error exporting the files. " +
                      "Please try again or contact support.",
                  });
                  // setSaving(false);
                  setReady(true);
                  resolve(false);
                  return;
                }
                // console.log("Adding files to local export...");
                let success = true;
                /** @type {FileSaver.ZipData[]} */
                const files = [
                  {
                    name: "email.json",
                    data: Buffer.from(JSON.stringify(email)),
                  },
                  {
                    name: "editor.json",
                    data: Buffer.from(JSON.stringify(editor)),
                  },
                  {
                    name: "spec.json",
                    data: Buffer.from(JSON.stringify(spec)),
                  },
                ];
                if (emailHtml) {
                  files.push({
                    name: "email.html",
                    data: Buffer.from(emailHtml),
                  });
                }
                if (emailAmp) {
                  files.push({
                    name: "email.amp",
                    data: Buffer.from(emailAmp),
                  });
                }
                if (emailEditHtml) {
                  files.push({
                    name: "emailEdit.html",
                    data: Buffer.from(emailEditHtml),
                  });
                }
                if (emailEditCss) {
                  files.push({
                    name: "emailEdit.css",
                    data: Buffer.from(emailEditCss),
                  });
                }
                // console.log("Saving zipped files for local export...");
                await FileSaver.saveAsZip(
                  files,
                  `template_${template.uid}.zip`,
                ).catch(async error => {
                  console.error("Error exporting local files", error);
                  success = false;
                  await alertAsync({
                    title: "Error",
                    text:
                      "There was an error exporting the files. " +
                      "Please try again or contact support.",
                  });
                });
                // console.log("Exported files.");
                setReady(true);
                resolve(success);
              }, minimizeCompiledEmail);
            });
          });
        }
        /**
         * @param {MsgTemplate} template
         * @param {{
         * emailInfo:MsgTemplate["email"],
         * editorInfo:MsgTemplate["editor"]
         * specInfo:MsgTemplate["spec"]
         * }} data
         */
        function save(template, data) {
          // setSaving(true);
          setReady(false);
          const {
            email: origEmail,
            editor: origEditor,
            spec: origSpec,
          } = template;
          const { emailInfo, editorInfo, specInfo } = data;
          // Merge template originals with edited data to preserve all fields.
          const email = { ...origEmail, ...emailInfo };
          const editor = { ...origEditor, ...editorInfo };
          const spec = { ...origSpec, ...specInfo };
          return new Promise((resolve, _reject) => {
            window.StripoApi.getTemplate(function saveTemplate(
              emailEditHtml,
              emailEditCss,
              width,
              height,
            ) {
              email.height = height;
              email.width = width;
              const minimizeCompiledEmail = true;
              window.StripoApi.compileEmail(async function compileTemplate(
                error,
                emailHtml,
                emailAmp,
                // ampErrors,
              ) {
                if (error) {
                  console.error("Error compiling email", error);
                  await alertAsync({
                    title: "Error",
                    text:
                      "There was an error saving the email. " +
                      "Please try again or contact support.",
                  });
                  // setSaving(false);
                  setReady(true);
                  resolve(false);
                  return;
                }
                let success = true;
                await saveEmailEdit(template, {
                  email,
                  emailHtml,
                  emailAmp,
                  emailEditHtml,
                  emailEditCss,
                  editor,
                  spec,
                })
                  .then(() => {
                    setChanged(false);
                  })
                  .catch(async error => {
                    console.error("Error saving email", error);
                    success = false;
                    await alertAsync({
                      title: "Error",
                      text:
                        "There was an error saving the email. " +
                        "Please try again or contact support.",
                    });
                  });
                // console.log("Saved email", result);
                // setSaving(false);
                setReady(true);
                resolve(success);
              }, minimizeCompiledEmail);
            });
          });
        }
        /**
         * @param {MsgTemplate} template
         * @param {{
         * emailInfo:MsgTemplate["email"],
         * editorInfo:MsgTemplate["editor"]
         * specInfo:MsgTemplate["spec"]
         * }} data
         */
        async function saveAndReload(template, data) {
          const saved = await save(template, data);
          if (!saved) return;
          requestIdleCallback(() => {
            reloadPage();
          });
        }
        return {
          templateId,
          exportLocalFiles,
          save,
          saveAndReload,
        };
      }, [idParam, alertAsync]);

    useOnMount(() => {
      // Close the sidebar immediately.
      dispatch(setSidebarVisibility(false));
      // Hide body scrollbar so the only visible scrollbar is for StripoEditor.
      document.body.classList.add(classes.body);
      return () => {
        // Restore everything when we leave.
        document.body.classList.remove(classes.body);
        dispatch(setSidebarVisibility(true));
      };
    });

    useOnMountAsync(async () => {
      try {
        console.log("Editing dealer template:", isDealer);
        const { template, editorOptions } = await loadEmailEditor(templateId, {
          isDealer,
          onDataChanged() {
            setChanged(true);
          },
          onTemplateLoaded() {
            // setLoaded(true);
            setReady(true);
          },
          // onToggleCodeEditor(codeVisible) {
          //   setCodeVisible(codeVisible)
          // },
        });
        const emailInfo = {
          ...defaultEmailInfo,
          ...template.email,
        };
        const editorInfo = {
          ...defaultEditorInfo,
          ...template.editor,
        };
        const specInfo = {
          ...defaultSpecInfo,
          ...template.spec,
        };
        const vars = template.typeInfo.vars ?? {};
        const varItems = [];
        for (const recordKey in vars) {
          const rec = vars[recordKey];
          varItems.push({ title: rec.name });
          for (const fieldKey in rec.field) {
            const fld = rec.field[fieldKey];
            varItems.push({
              title: `${fld.name} of ${rec.name}`,
              code: `${recordKey}.${fld.alias ?? fieldKey}`,
            });
          }
        }
        setTemplate(template);
        setEmailInfo(emailInfo);
        setEditorInfo(editorInfo);
        setSpecInfo(specInfo);
        setVarItems(varItems);
        setEditorOptions(editorOptions);
      } catch (err) {
        window.alert(
          "There was an error loading the editor." +
            " Please try again or contact support.",
        );
        console.log("" + err, { ...err });
      }
    });

    React.useEffect(() => {
      if (changed) {
        window.addEventListener("beforeunload", notifyUnsavedChanges);
      } else {
        window.removeEventListener("beforeunload", notifyUnsavedChanges);
      }
      return () => {
        window.removeEventListener("beforeunload", notifyUnsavedChanges);
      };
    }, [changed]);

    const onClickDiscard = React.useCallback(async () => {
      console.log("Discard changes", templateId);
      if (!(await confirmAsync("Are you sure?"))) {
        return;
      }
      setChanged(false);
      requestIdleCallback(() => {
        // goBackOrReplace(
        //   `/${
        //     isDealer ? "dealer_msg_templates" : "msg_templates"
        //   }/${templateId}/show`,
        // );
        reloadPage();
      });
    }, [templateId, confirmAsync]);

    const onClickSave = React.useCallback(() => {
      save(template, {
        emailInfo,
        editorInfo,
        specInfo,
      });
    }, [template, emailInfo, editorInfo, specInfo, save]);
    useKeyDown("Ctrl+KeyS", onClickSave);

    // const onClickHist = React.useCallback(() => {
    //   console.log("Show history", templateId);
    //   window.StripoApi.toggleChangeHistoryPanel();
    // }, [templateId]);

    const onClickEditDetails = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      async e => {
        const data = { ...emailInfo };
        const values = await showDialogAsync({
          title: "Email message details",
          content: <MsgTemplateEditEmailForm data={data} />,
        });
        if (!values) {
          return;
        }
        setEmailInfo({
          ...values,
        });
        setChanged(true);
      },
      [emailInfo, showDialogAsync],
    );
    const onClickPreview = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      e => {
        console.log("Previewing email...");
        window.StripoApi.compileEmail(async function (
          error,
          html,
          // ampHtml,
          // ampErrors,
        ) {
          if (error) {
            alertAsync({
              title: "Error",
              text: error,
            });
            return;
          }
          function renderText(text, defaultValue = undefined) {
            return text
              ? replaceMsgTokens(template, text, {
                  previewValues: editorInfo.previewValues,
                })
              : defaultValue;
          }
          try {
            await showDialogAsync({
              fullScreen: true,
              content: (
                <MsgTemplateEmailPreview
                  template={template}
                  emailInfo={{
                    ...emailInfo,
                    enabled: emailInfo.enabled,
                    from: { name: renderText(emailInfo.from?.name) },
                    subject: renderText(emailInfo.subject),
                  }}
                  html={renderText(html)}
                />
              ),
            });
          } catch (err) {
            console.error(err);
            alertAsync({
              title: "Error",
              text:
                err && err instanceof LiquidError
                  ? `Error, ${err.message}`
                  : `There was an error showing the preview.`,
            });
          }
        });
      },
      [template, emailInfo, editorInfo, alertAsync, showDialogAsync],
    );
    useKeyDown("Ctrl+KeyP", onClickPreview);
    const onClickVarsEdit = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      async e => {
        const data = { editorInfo, specInfo };
        // console.log("EDITING VARS", data);
        const values = await showDialogAsync({
          // title: "Edit Variables",
          // TODO: Change title back to "Edit Variables when Custom vars done.
          title: "Preview values",
          content: <MsgTemplateEditVars template={template} data={data} />,
          // maxWidth: "md",
        });
        if (!values) {
          return;
        }
        // console.log("CHANGED", values);
        if (!values.saveAndReload) {
          setEditorInfo({
            ...values.editorInfo,
          });
          setSpecInfo({
            ...values.specInfo,
          });
          setChanged(true);
        } else {
          saveAndReload(template, {
            editorInfo: values.editorInfo,
            specInfo: values.specInfo,
            emailInfo,
          });
        }
      },
      [
        emailInfo,
        editorInfo,
        specInfo,
        template,
        saveAndReload,
        showDialogAsync,
      ],
    );
    const onClickSend = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      async e => {
        console.log("Sending...");
        const toEmail = await promptAsync({
          title: "Send test email",
          text: "Please enter an email address to send a test email to.",
          // TODO: defaultValue: currentUser.email,
          textFieldProps: { type: "email", required: true },
        });
        if (!toEmail) return;
        window.StripoApi.compileEmail(async function compiledEmail(
          error,
          html,
          // ampHtml,
          // ampErrors,
        ) {
          if (error) {
            alertAsync({
              title: "Error",
              text: error,
            });
            return;
          }
          function renderText(text, defaultValue = undefined) {
            return text
              ? replaceMsgTokens(template, text, {
                  previewValues: editorInfo.previewValues,
                })
              : defaultValue;
          }
          let sent = false;
          try {
            sent = await sendPreviewEmail(template, {
              to: { email: toEmail },
              from: { name: renderText(emailInfo.from?.name) },
              subject: renderText(emailInfo.subject),
              emailHtml: renderText(html),
            });
          } catch (err) {
            error = err;
            console.error(err);
          }
          alertAsync({
            title: sent ? "Sent" : "Error",
            text: sent
              ? `Test email sent to "${toEmail}".`
              : error && error instanceof LiquidError
              ? `Error, ${error.message}`
              : `There was an error sending a test email to "${toEmail}".`,
          });
        });
      },
      [emailInfo, editorInfo, template, alertAsync, promptAsync],
    );
    // Handle hotkeys for Stripo iframe.
    React.useEffect(() => {
      if (!ready) return;
      /** @type {HTMLIFrameElement} */
      const iframe = document.getElementsByClassName("stripo-preview-frame")[0];
      if (!iframe) return;
      const doc = iframe.contentDocument;
      /** @param {KeyboardEvent} e */
      function handle(e) {
        if (e.ctrlKey) {
          const code = e.code;
          if (code === "KeyP") {
            e.preventDefault();
            onClickPreview();
          } else if (code === "KeyS") {
            e.preventDefault();
            // const focusEl = doc.activeElement;
            onClickSave();
            // BUG: The Stripo editor blurs and loses focus during save...
            // .then(() => {
            //   iframe.focus();
            //   // console.log("f", focusEl);  // <div class="esd-cke-text"></div>
            //   if (focusEl?.focus) {
            //     // The following never works because the input disappears on save...
            //     // focusEl.focus();
            //   }
            // });
          }
        }
      }
      doc.addEventListener("keydown", handle);
      return () => {
        doc.removeEventListener("keydown", handle);
      };
    }, [ready, onClickSave, onClickPreview]);

    const [varsMenuAnchor, setVarsMenuAnchor] = React.useState(null);

    const closeVarsMenu = React.useCallback(() => {
      setVarsMenuAnchor(null);
    }, []);
    const openVarsMenu = React.useCallback(e => {
      setVarsMenuAnchor(e.currentTarget);
    }, []);
    const onClickVarCopy = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      e => {
        const text = e.currentTarget.innerText;
        if (window.navigator?.clipboard?.writeText) {
          window.navigator.clipboard.writeText(text);
        }
        setVarsMenuAnchor(null);
      },
      [],
    );

    const [editMenuAnchor, setEditMenuAnchor] = React.useState(null);
    const closeEditMenu = React.useCallback(() => {
      setEditMenuAnchor(null);
    }, []);
    const openEditMenu = React.useCallback(e => {
      setEditMenuAnchor(e.currentTarget);
    }, []);

    const onExportLocalFiles = React.useCallback(() => {
      setEditMenuAnchor(null);
      requestIdleCallback(() => {
        exportLocalFiles(template, {
          emailInfo,
          editorInfo,
          specInfo,
        });
      });
    }, [template, emailInfo, editorInfo, specInfo, exportLocalFiles]);

    const notReady = !ready;
    return (
      <div className={classes.root}>
        <Prompt when={changed} message="You have unsaved changes..." />
        <Title title={template?.name ?? "Loading email message template..."} />
        {basicDialog}
        <Toolbar variant="dense" disableGutters>
          <Button
            className={classes.ibtn}
            title="Go back to the message template details."
            color="primary"
            size="small"
            variant="outlined"
            // startIcon={<ArrowBackIcon />}
            component={Link}
            to={
              isDealer
                ? `/dealer_msg_templates/${templateId}/show`
                : `/msg_templates/${templateId}/show`
            }
          >
            <ArrowBackIcon fontSize="small" />
          </Button>
          {spacer}
          <ButtonGroup disabled={!changed || notReady}>
            <Button
              title="Save the email template. (Ctrl+S)"
              color="primary"
              size="small"
              onClick={onClickSave}
              startIcon={wide && <SaveIcon />}
            >
              {wide ? "Save" : <SaveIcon />}
            </Button>
            {/* <Button
              // id="stripoHistBtn"
              // className={classes.stripoBtn}
              title="Show history of changes saved to this template."
              color="primary"
              size="small"
              onClick={onClickHist}
              startIcon={<HistoryIcon />}
            >
              History
            </Button> */}
            <Button
              title="Discard all unsaved changes."
              color="primary"
              size="small"
              onClick={onClickDiscard}
              startIcon={wide && <DiscardIcon />}
            >
              {wide ? "Discard" : <DiscardIcon />}
            </Button>
          </ButtonGroup>
          {spacer}
          <ButtonGroup
          // NOTE: Do NOT control disabled on these buttons otherwise they get
          // out of whack with the Stripo plugin controlling them.
          >
            <Button
              id="stripoUndoBtn"
              className={classes.stripoBtn}
              title="Undo the last edit."
              color="primary"
              size="small"
              startIcon={wide && <UndoIcon />}
            >
              {wide ? "Undo" : <UndoIcon />}
            </Button>
            <Button
              id="stripoRedoBtn"
              className={classes.stripoBtn}
              title="Redo the last edit."
              color="primary"
              size="small"
              startIcon={wide && <RedoIcon />}
            >
              {wide ? "Redo" : <RedoIcon />}
            </Button>
          </ButtonGroup>
          {spacer}
          <ButtonGroup disabled={notReady}>
            <Button
              title="Edit variables and preview values."
              color="primary"
              size="small"
              onClick={onClickVarsEdit}
              variant="outlined"
              startIcon={wide && <VariablesIcon />}
            >
              {wide ? "Variables" : <VariablesIcon />}
            </Button>
            <Button
              aria-controls="vars-menu"
              aria-haspopup="true"
              color="primary"
              size="small"
              onClick={openVarsMenu}
            >
              <ArrowDropDownIcon fontSize="small" />
            </Button>
          </ButtonGroup>
          <Menu
            id="vars-menu"
            anchorEl={varsMenuAnchor}
            keepMounted
            open={Boolean(varsMenuAnchor)}
            onClose={closeVarsMenu}
            anchorOrigin={dropdownOrigin}
            transformOrigin={dropdownTransformOrigin}
            getContentAnchorEl={null}
          >
            <MenuItem disabled divider>
              Click to copy an item below
            </MenuItem>
            {varItems.map((it, i) =>
              !it.code ? (
                <MenuItem key={i} className={classes.mcat} color="primary">
                  {it.title}
                </MenuItem>
              ) : (
                <MenuItem
                  key={i}
                  onClick={onClickVarCopy}
                  color="primary"
                  title={it.title}
                >
                  {it.code}
                </MenuItem>
              ),
            )}
          </Menu>
          {spacer}
          <ButtonGroup disabled={notReady}>
            <Button
              title="Open the preview panel. (Ctrl+P)"
              color="primary"
              size="small"
              onClick={onClickPreview}
              variant="outlined"
              startIcon={wide && <PreviewIcon />}
            >
              {wide ? "Preview" : <PreviewIcon />}
            </Button>
          </ButtonGroup>
          {filler}
          <div className={classes.details} disabled={notReady}>
            <div
              className={classes.detail}
              onClick={ready ? onClickEditDetails : undefined}
              disabled={notReady}
            >
              <Button
                color="primary"
                size="small"
                disabled={notReady}
                title={
                  "Edit if the email is enabled for sending when this " +
                  "message template is published."
                }
                endIcon={
                  emailInfo.enabled ? (
                    //
                    <CheckedIcon />
                  ) : (
                    <UncheckedIcon />
                  )
                }
              >
                Enabled
              </Button>
            </div>
            <div
              className={classes.detail}
              onClick={ready ? onClickEditDetails : undefined}
              disabled={notReady}
              title={emailInfo.subject}
            >
              <Button
                color="primary"
                size="small"
                disabled={notReady}
                title="Edit the email subject"
              >
                Subject
              </Button>
              {emailInfo.subject}
            </div>
            <div
              className={classes.detail}
              onClick={ready ? onClickEditDetails : undefined}
              disabled={notReady}
              title={emailInfo.from?.name || defaultEmailSenderName}
            >
              <Button
                color="primary"
                size="small"
                disabled={notReady}
                title="Edit the email sender"
              >
                From
              </Button>
              {emailInfo.from?.name || defaultEmailSenderName}
            </div>
          </div>
          {filler}
          <ButtonGroup disabled={notReady}>
            <Button
              title="Send a test email."
              color="primary"
              size="small"
              onClick={onClickSend}
              startIcon={wide && <SendIcon />}
            >
              {wide ? "Send test" : <SendIcon />}
            </Button>
          </ButtonGroup>
          {filler}
          <ButtonGroup disabled={notReady}>
            <Button
              id="stripoCodeBtn"
              className={classes.stripoBtn}
              title="Toggle the code editor."
              color="primary"
              size="small"
              startIcon={wide && <CodeIcon />}
            >
              {wide ? "Code" : <CodeIcon />}
            </Button>
            <Button
              aria-controls="edit-menu"
              aria-haspopup="true"
              color="primary"
              size="small"
              onClick={openEditMenu}
            >
              <MenuIcon />
            </Button>
          </ButtonGroup>
          <Menu
            id="edit-menu"
            anchorEl={editMenuAnchor}
            keepMounted
            open={Boolean(editMenuAnchor)}
            onClose={closeEditMenu}
            anchorOrigin={dropdownOrigin}
            transformOrigin={dropdownTransformOrigin}
            getContentAnchorEl={null}
          >
            <MenuItem
              title="Export all editable and compiled message content"
              onClick={onExportLocalFiles}
            >
              <SaveIcon fontSize="small" />
              &nbsp;Export all editor files
            </MenuItem>
            <Divider />
            <MenuItem
              component="a"
              href="https://liquidjs.com/tags/overview.html"
              target="_blank"
              title="Help for custom display condition scripts..."
              onClick={closeEditMenu}
            >
              <HelpIcon fontSize="small" />
              &nbsp;Scripting help
            </MenuItem>
          </Menu>
        </Toolbar>
        <div className={classes.editorContent}>
          {editorOptions && <StripoEditor options={editorOptions} />}
        </div>
      </div>
    );
  },
);
