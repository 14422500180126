import React from "react";
import {
  //
  // Box,
  // Button,
  ButtonGroup,
  Chip,
  // DialogTitle,
  Grid,
  // IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";
// Icons
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
// Local
import { GroupedIconButton } from "../../components";

const useStyles = makeStyles(
  theme => ({
    // title: {},
    topgrid: {
      display: "flex",
      alignItems: "center",
      padding: 8,
      paddingRight: 64,
    },
    name: {
      width: 400,
      textOverflow: "ellipsis",
      overflowX: "hidden",
      whiteSpace: "nowrap",
    },
    content: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      padding: "0 8px 8px 8px",
    },
    // headers: {},
    header: {
      display: "flex",
    },
    headerCenter: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    chip: {
      borderRadius: 4,
      borderColor: "lightgrey",
      backgroundColor: "#fff",
    },
    filler: {
      flex: 1,
    },
    spacer: {
      minWidth: 8,
    },
    frames: {
      flex: 1,
    },
    desktop: {
      border: "1px solid lightgrey",
      borderRadius: 6,
      height: "100%",
      width: "100%",
    },
    mobile: {
      border: "1px solid lightgrey",
      borderRadius: 6,
      height: "100%",
      width: "100%",
    },
  }),
  {
    classNamePrefix: "MsgTemplateEmailPreview",
  },
);

export const MsgTemplateEmailPreview = React.memo(
  /**
   * @typedef {import("../../resources/msg_templates/types").MsgTemplate.EmailInfo} EmailInfo
   *
   * Shows a preview of the given template html.
   * @param {{
   * template:MsgTemplate,
   * emailInfo:EmailInfo,
   * html:string,
   * }} param0
   */
  function MsgTemplateEmailPreview({ template, emailInfo, html }) {
    const classes = useStyles();
    const [cols, setCols] = React.useState([8, 4]);
    const { sizeLeft, sizeRight } = React.useMemo(() => {
      return {
        sizeLeft() {
          setCols(cols => {
            if (cols[0] > 6) {
              cols = [cols[0] - 1, cols[1] + 1];
            }
            return cols;
          });
        },
        sizeRight() {
          setCols(cols => {
            if (cols[0] < 10) {
              cols = [cols[0] + 1, cols[1] - 1];
            }
            return cols;
          });
        },
      };
    }, []);
    return (
      <>
        <div className={classes.topgrid}>
          <Chip
            label="Preview"
            variant="outlined"
            size="small"
            className={classes.chip}
          />
          <div className={classes.spacer}></div>
          <Typography variant="caption" className={classes.name}>
            {template.name}
          </Typography>
          <div className={classes.spacer}></div>
          <Chip
            label="Subject"
            variant="outlined"
            size="small"
            className={classes.chip}
          />
          <div className={classes.spacer}></div>
          <Typography variant="h6">
            {emailInfo.subject || "WARNING: No subject!"}
          </Typography>
          <div className={classes.spacer}></div>
          <Chip
            label="From"
            variant="outlined"
            size="small"
            className={classes.chip}
          />
          <div className={classes.spacer}></div>
          <Typography variant="h6">
            {emailInfo.from?.name || "Turnyn"}
          </Typography>
        </div>
        <div className={classes.content}>
          <Grid container spacing={1}>
            <Grid item xs={cols[0]} className={classes.header}>
              <Chip
                label="Desktop"
                variant="outlined"
                size="small"
                className={classes.chip}
              />
            </Grid>
            <Grid item xs={cols[1]} className={classes.header}>
              <Chip
                label="Mobile"
                variant="outlined"
                size="small"
                className={classes.chip}
              />
              <div className={classes.filler}></div>
              <ButtonGroup>
                <GroupedIconButton
                  title="Resize the mobile frame."
                  size="small"
                  onClick={sizeLeft}
                >
                  <ArrowLeftIcon />
                </GroupedIconButton>
                <GroupedIconButton
                  title="Resize the mobile frame."
                  size="small"
                  onClick={sizeRight}
                >
                  <ArrowRightIcon />
                </GroupedIconButton>
              </ButtonGroup>
              <div className={classes.spacer}></div>
            </Grid>
          </Grid>
          <Grid className={classes.frames} container spacing={1}>
            <Grid item xs={cols[0]}>
              <iframe
                className={classes.desktop}
                title="Desktop preview"
                srcDoc={html}
              ></iframe>
            </Grid>
            <Grid item xs={cols[1]}>
              <iframe
                className={classes.mobile}
                title="Mobile preview"
                srcDoc={html}
              ></iframe>
            </Grid>
          </Grid>
        </div>
      </>
    );
  },
);
