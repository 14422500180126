import React from "react";
import {
  AutocompleteInput,
  Create,
  ReferenceInput,
  SimpleForm,
} from "react-admin";
// Local
import { sortBy } from "../../react-admin";

export const DealerMsgTemplateCreate = props => {
  // Only select the type, the DealerMsgTemplateEdit form will do the rest.
  return (
    <Create {...props}>
      <SimpleForm redirect="edit">
        <ReferenceInput
          source="dealerId"
          reference="dealers"
          isRequired
          label="Dealer"
          fullWidth
          sort={sortBy.nameAsc}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          source="msgTypeId"
          reference="msg_types"
          isRequired
          label="Message type"
          fullWidth
          sort={sortBy.nameAsc}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};
