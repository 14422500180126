import React from "react";
import {
  //
  List,
  DateField,
  DateInput,
  Datagrid,
  Filter,
  TextField,
  TextInput,
} from "react-admin";
import TextsmsIcon from "@material-ui/icons/Textsms";
// Local
import { clearFilterButton } from "../../components";

export const TwilioMessageIcon = TextsmsIcon;

const ListFilters = props => (
  <Filter {...props}>
    <DateInput source="date_sent" alwaysOn />
    <TextInput source="to" alwaysOn />
    {clearFilterButton()}
  </Filter>
);

export const TwilioMessageList = props => (
  <List pagination={false} {...props} filters={<ListFilters />}>
    <Datagrid rowClick="show">
      <TextField source="to" sortable={false} />
      <TextField source="body" sortable={false} />
      <TextField source="error_code" sortable={false} />
      <TextField source="error_message" sortable={false} />
      <TextField source="status" sortable={false} />
      <DateField source="date_created" showTime sortable={false} />
      <DateField source="date_sent" showTime sortable={false} />
    </Datagrid>
  </List>
);
