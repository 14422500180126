import React from "react";
// import { Box, Button } from "@material-ui/core";
import {
  // AutocompleteInput,
  BooleanInput,
  Edit,
  // ReferenceInput,
  // SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
// Local
import {
  //
  // BoolNumInput,
  // NonInput,
  TextInputEx,
} from "../../components";
import { readonlyInputProps, selectAllTarget } from "../../lib";
// import {
//   //
//   // FormDataConsumer,
//   // sortBy,
//   useFormState,
// } from "../../react-admin";

function helperTextForGeneratedIfEnabled(values) {
  if (values?.push?.enabled) {
    return "This will be generated from the email or SMS template if left blank.";
  }
  return "";
}

function disabledIfNotEnabled(values) {
  return !values?.push?.enabled;
}

export const MsgTemplatePushEdit = props => {
  return (
    <Edit mutationMode="pessimistic" {...props}>
      <SimpleForm redirect={`/msg_templates/${props.id}/show`}>
        <TextInput
          source="name"
          label="Template name"
          fullWidth
          InputProps={readonlyInputProps}
        />
        <BooleanInput
          source="push.enabled"
          label="Push enabled"
          defaultValue={false}
        />
        <TextInputEx
          source="push.title"
          label="Notification title"
          autoFocus
          defaultValue=""
          fullWidth
          helperText={helperTextForGeneratedIfEnabled}
          disabled={disabledIfNotEnabled}
          onFocus={selectAllTarget}
        />
        <TextInputEx
          source="push.text"
          label="Notification text"
          defaultValue=""
          fullWidth
          multiline
          helperText={helperTextForGeneratedIfEnabled}
          disabled={disabledIfNotEnabled}
          // onFocus={selectAllTarget}
        />
      </SimpleForm>
    </Edit>
  );
};
