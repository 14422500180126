import React from "react";
import qs from "query-string";
import { useLocation } from "react-router-dom";
// Local
import { authProvider, isAuthenticated } from "../../react-admin";
import { LoginPage } from "./LoginPage";
import { ForgotPasswordPage } from "./ForgotPasswordPage";
import { ResetPasswordPage } from "./ResetPasswordPage";

const confirmAccount = "confirm-account";

const Pages = {
  [confirmAccount]: ResetPasswordPage,
  "forgot-password": ForgotPasswordPage,
  "reset-password": ResetPasswordPage,
};

/** Decides which `/login` page to show based on `?page=` query. */
function _AuthPages() {
  const { search } = useLocation();
  const query = React.useMemo(() => qs.parse(search), [search]);
  const confirming = query.page === confirmAccount;
  const [busy, setBusy] = React.useState(confirming && isAuthenticated());
  React.useEffect(() => {
    if (confirming && isAuthenticated()) {
      authProvider.logout().then(() => {
        setBusy(false);
      });
    }
  }, [confirming, query.page]);

  const CurrentAuthPage = busy
    ? LoadingMessage
    : Pages[query.page] || LoginPage;

  // console.log("Rendering", search, query);
  return (
    <div style={{ paddingLeft: "15%", paddingRight: "15%", paddingTop: 100 }}>
      <CurrentAuthPage query={query} />
    </div>
  );
}

export const AuthPages = React.memo(_AuthPages);

const LoadingMessage = React.memo(
  /**
   *
   */
  function LoadingMessage({ children }) {
    return <div>Loading...</div>;
  },
);
