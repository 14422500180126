import React from "react";
import { Button, useRefresh } from "react-admin";
import EjectIcon from "@material-ui/icons/Eject";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";

//local
import { REACT_APP_API_URL } from "../../config";
import { fetchJson } from "../../react-admin";

export const DeactivateButton = ({ record, ...props }) => {
  const { id, name, deactivatedAt } = record || {};
  // Boolean(null) = false; Boolean(new Date()) = true;
  const isDeactivated = Boolean(deactivatedAt);
  const refresh = useRefresh();
  const deactivateDealer = React.useCallback(
    /** @param {React.SyntheticEvent<HTMLElement>} e */
    async e => {
      if (!isDeactivated) {
        if (
          !window.confirm(
            `Users will not be able to login under "${name}" \nAre you sure you want to deactivate this dealer?`,
          )
        ) {
          return;
        }
      }
      const endpointUrl = `${REACT_APP_API_URL}/dealers/update-dealer-activation`;
      await fetchJson(endpointUrl, {
        method: "PUT",
        body: JSON.stringify({
          id,
          activeStatus: isDeactivated,
        }),
      })
        .then(() => {
          return refresh();
        })
        .catch(() => {
          alert("There was an error deactivating dealers \nPlease try again.");
        });
    },
    [id, isDeactivated, name, refresh],
  );
  return (
    <Button
      label={isDeactivated ? "Reactivate" : "Deactivate"}
      {...props}
      onClick={deactivateDealer}
    >
      {isDeactivated ? <SystemUpdateAltIcon /> : <EjectIcon />}
    </Button>
  );
};
