// Local
import { makeResource } from "../../react-admin";
import { RemindersList, ReminderIcon } from "./RemindersList";
import { ReminderShow } from "./ReminderShow";

export const Reminders = makeResource({
  name: "reminders",
  icon: ReminderIcon,
  list: RemindersList,
  show: ReminderShow,
  options: {
    label: "Reminders",
  },
});
