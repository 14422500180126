import React from "react";
import {
  //
  BooleanField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  UrlField,
} from "react-admin";
// Local
import { QRCodeField } from "../../components";

export const DealerCampaignShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <ReferenceField source="dealerId" reference="dealers" link="show">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="name" />
      <BooleanField
        source="rules.appraisalsToCRM"
        label="Add new Appraisals to CRM"
      />
      <UrlField
        source="links.tradein"
        label={
          <>
            New <strong>Trade-in</strong> link
          </>
        }
        target="blank"
        rel="noopener noreferrer"
      />
      <QRCodeField source="links.tradein" />
      <UrlField
        source="links.return"
        label={
          <>
            New <strong>Lease return</strong> link
          </>
        }
        target="blank"
        rel="noopener noreferrer"
      />
      <QRCodeField source="links.return" />
    </SimpleShowLayout>
  </Show>
);
