// Local
import { makeResource } from "../../react-admin";
import { AdminUserList, AdminUserIcon } from "./AdminUserList";
import { AdminUserEdit } from "./AdminUserEdit";
import { AdminUserShow } from "./AdminUserShow";
import { AdminUserCreate } from "./AdminUserCreate";

export const AdminUsers = makeResource({
  name: "admin_users",
  icon: AdminUserIcon,
  list: AdminUserList,
  edit: AdminUserEdit,
  show: AdminUserShow,
  create: AdminUserCreate,
  options: {
    label: "Admin users",
  },
});
