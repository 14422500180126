import React from "react";
import { IconButton } from "@material-ui/core";

export const GroupedIconButton = React.memo(
  /**
   * `IconButton` that can be used in a `ButtonGroup` without errors.
   * See https://github.com/mui/material-ui/issues/17226#issuecomment-672980848
   * @param {import("@material-ui/core").IconButtonProps} props
   */
  function GroupedIconButton(props) {
    const { disableElevation: _1, fullWidth: _2, variant: _3, ...rest } = props;
    return <IconButton {...rest} />;
  },
);
