// Local
import AppraisalDealTypeCreate from "./AppraisalDealTypeCreate";
import AppraisalDealTypeEdit from "./AppraisalDealTypeEdit";
import AppraisalDealTypeList, {
  AppraisalDealTypeIcon,
} from "./AppraisalDealTypeList";

export const AppraisalDealTypes = {
  name: "appraisal_deal_types",
  create: AppraisalDealTypeCreate,
  list: AppraisalDealTypeList,
  edit: AppraisalDealTypeEdit,
  icon: AppraisalDealTypeIcon,
  options: {
    label: "Appraisal deal types",
  },
};
