import React from "react";
import {
  AutocompleteInput,
  Datagrid,
  DateField,
  DateInput,
  Filter,
  List,
  ReferenceInput,
  ReferenceField,
  TextField,
  // SelectInput,
} from "react-admin";
import HistoryIcon from "@material-ui/icons/History";
import { clearFilterButton } from "../../components";

export const ReminderIcon = HistoryIcon;

const Filters = props => (
  <Filter {...props}>
    <ReferenceInput source="dealerId" reference="dealers" alwaysOn>
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    {/* <ReferenceInput source="reason" reference="lists/reason_types" alwaysOn>
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput source="status" reference="lists/status_types" alwaysOn>
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput source="channel" reference="lists/channel_types" alwaysOn>
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput source="to" reference="users" alwaysOn>
      <AutocompleteInput optionText="name" />
    </ReferenceInput> */}
    <DateInput source="createdAt_gte" label="From date" alwaysOn />
    <DateInput source="createdAt_lte" label="To date" alwaysOn />
    {clearFilterButton()}
  </Filter>
);

export const RemindersList = props => (
  <List
    {...props}
    filters={<Filters />}
    perPage={25}
    sort={{ field: "id", order: "DESC" }}
    bulkActionButtons={false}
  >
    <Datagrid rowClick="show">
      <TextField source="id" />
      <ReferenceField source="dealerId" reference="dealers">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="reason" />
      <TextField source="channel" />
      <TextField source="status" />
      <TextField source="to" />
      <DateField source="createdAt" showTime />
    </Datagrid>
  </List>
);
