import React from "react";
import { Typography } from "@material-ui/core";
import {
  BooleanInput,
  Create,
  // NumberInput,
  // ReferenceArrayInput,
  // SelectArrayInput,
  // SelectInput,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";
// Local
import { NonInput } from "../../components";

export const AdminUserCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="show">
      {/* <ReferenceArrayInput
        fullWidth
        label="Roles"
        source="roleIds"
        reference="lists/admin_user_roles"
        validate={required()}
      >
        <SelectArrayInput optionText="name" fullWidth />
      </ReferenceArrayInput> */}
      <TextInput source="firstName" validate={required()} />
      <TextInput source="lastName" validate={required()} />
      <TextInput source="email" validate={required()} />
      <TextInput source="phoneNumber" />
      <NonInput>
        <Typography>
          <em>
            <small>
              If you want to CC yourself, don't send the invitation now. You can
              do that on the next screen...
            </small>
          </em>
        </Typography>
      </NonInput>
      <BooleanInput
        source="sendInvitation"
        label="Send invitation now"
        defaultValue={true}
      />
    </SimpleForm>
  </Create>
);
