import React from "react";
import { Create, SimpleForm, TextInput } from "react-admin";

export const BankCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source="name" autoFocus />
    </SimpleForm>
  </Create>
);
