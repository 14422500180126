import React from "react";
import {
  AutocompleteInput,
  Edit,
  ReferenceInput,
  // SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";

export const MsgPartyEdit = props => (
  <Edit mutationMode="pessimistic" {...props}>
    <SimpleForm>
      <TextInput
        source="name"
        label="Display name"
        autoFocus
        fullWidth
        isRequired
      />
      <TextInput source="description" fullWidth />
      <ReferenceInput
        source="tenantTypeId"
        reference="lists/tenant_types"
        label="Available to"
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
