// Local
import { makeResource } from "../../react-admin";
import {
  DealerMsgVersionList,
  DealerMsgVersionIcon,
} from "./DealerMsgVersionList";
import { DealerMsgVersionEdit } from "./DealerMsgVersionEdit";
import { DealerMsgVersionShow } from "./DealerMsgVersionShow";

export const DealerMsgVersions = makeResource({
  name: "dealer_msg_versions",
  icon: DealerMsgVersionIcon,
  list: DealerMsgVersionList,
  edit: DealerMsgVersionEdit,
  show: DealerMsgVersionShow,
  options: {
    // hidden: true,
    label: "Dealer message versions",
  },
});
