import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

export function goBackOrClose() {
  if (history.length > 1) {
    // Just go back one.
    history.goBack();
  } else {
    // Most likely, they double-clicked to open this page. Close it.
    window.close();
  }
}

export function goBackOrReplace(path) {
  if (history.length > 1) {
    history.goBack();
  } else {
    history.replace(path);
  }
}
/** Reloads the react-router page without reloading the whole app. */
export function reloadPage() {
  history.go(0);
}
