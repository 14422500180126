// import React from "react";
// Local
import { useOnMount } from "../hooks";
// import * as defaultTpl from "./templates/basicEmpty";
// import * as defaultTpl from "./templates/basic1Col";
import * as defaultTpl from "./templates/empty";

/** @see https://stripo.email/releases/ */
const STRIPO_VERSION = "1.82.0";

declare global {
  interface Window {
    Stripo: Stripo;
    StripoApi: StripoApi;
  }
}

export interface StripoEditorUser {
  email: string;
  firstName?: string;
  lastName?: string;
}

export interface StripoEditorOptions
  extends Partial<Omit<StripoInitOptions, "apiRequestData" | "getAuthToken">> {
  /** Async function to provide a Stripo token from the API server. */
  connectStripo: () => Promise<{ token: string }>;
  /** True to debug the Stripo plugin initialization options, etc. */
  debugInit?: boolean;
  /** Pass if a dealer owns the template being edited. */
  dealerId?: number;
  /** True if editing a template that belongs to a dealer. */
  isDealer?: boolean;
  /** The type of owner that owns the template. */
  ownerType: TemplateOwnerType;
  /** Uid of the template from `msg_templates`, `dealer_msg_templates`, etc. */
  templateUid: number;
  /** User info for displaying in template edit version history. */
  user: StripoEditorUser;
}
/**
 * Types of template owners indicating which table they get stored in. Used to
 * encode `emailId`s.
 */
export enum TemplateOwnerTypeAbbrev {
  /** For templates owned by the system admins (in `msg_templates`). */
  admins = "a",
  /** For templates owned by dealers (in `dealer_msg_templates`). */
  dealer = "d",
  /** For templates owned by the saas system admins (in `msg_templates`). */
  saas = "s",
}
/**
 * Types of template owners indicating which table they get stored in. Used to
 * encode `emailId`s.
 */
export type TemplateOwnerType = keyof typeof TemplateOwnerTypeAbbrev;

/**
 * Creates the data that will be attached to each Stripo call for one session.
 * Includes the {@link StripoInitOptions} required `emailId` and our `dealerId`.
 *
 * **NOTE: You MUST pass ALL Folder path variables defined in the Stripo
 * plugin account's control panel. Folder paths are found in `UI Settings ->
 * Image Gallery -> Folders configuration: Folder paths`.** Folder paths that
 * do not include any variables require nothing here.
 */
function createApiRequestData({
  dealerId = 0,
  ownerType,
  templateUid,
}: Pick<
  StripoEditorOptions,
  "dealerId" | "ownerType" | "templateUid"
>): StripoInitOptions["apiRequestData"] {
  /** Abbrevation for owner type (a = admins, d = dealers, s = saas). */
  const owner = TemplateOwnerTypeAbbrev[ownerType];
  const separator = "_";
  /** Put templateUid first so lookups are faster. */
  const emailId = dealerId
    ? [templateUid, owner, dealerId].join(separator)
    : [templateUid, owner].join(separator);
  /**
   * **NOTE: You MUST pass ALL Folder path variables defined in the Stripo
   * plugin account's control panel. Folder paths are found in `UI Settings ->
   * Image Gallery -> Folders configuration: Folder paths`.** Folder paths that
   * do not include any variables require nothing here.
   */
  const apiRequestData: StripoInitOptions["apiRequestData"] = {
    emailId,
    dealerId,
  };
  return apiRequestData;
}
/** Returns a `getAuthToken` callback wrapper for the given promise. */
function getAuthTokenHandler(
  getAuthTokenAsync: () => Promise<{ token: string }>,
) {
  function getAuthToken(callback: (token: string) => void) {
    getAuthTokenAsync()
      .then(data => {
        callback(data.token);
      })
      .catch(err => {
        window.alert(
          "Error authenticating email editor. Please contact support.",
        );
      });
  }
  return getAuthToken;
}
/**
 * Loads and initializes the Stripo plugin script.
 *
 * @see https://stripo.email/plugin-api/
 * @see https://codesandbox.io/s/react-stripo-ejs-forked-x1qcvn
 */
async function loadStripoEditor(
  options: Partial<Omit<StripoInitOptions, "getAuthToken">> & {
    /** True to debug the Stripo plugin initialization options, etc. */
    debugInit?: boolean;
    /** An async version of `getAuthToken`. */
    getAuthTokenAsync: () => Promise<{ token: string }>;
  },
) {
  const { apiRequestData, debugInit, getAuthTokenAsync, html, css, ...opt } =
    options;
  if (!apiRequestData || !apiRequestData.emailId) {
    throw new Error("Missing apiRequestData.emailId");
  }
  const initOptions: StripoInitOptions = {
    apiRequestData,
    getAuthToken: getAuthTokenHandler(getAuthTokenAsync),
    settingsId: "stripoSettingsContainer",
    previewId: "stripoPreviewContainer",
    ...opt,
    html: html ?? defaultTpl.html,
    css: css ?? defaultTpl.css,
  };
  if (debugInit) {
    console.log("Initializing Stripo", {
      ...initOptions,
      html: "HTML removed",
      css: "CSS removed",
    });
  }
  if (window.Stripo && window.StripoApi) {
    if (debugInit) {
      console.log("Stripo already loaded.");
    }
    window.Stripo.init(initOptions);
    return;
  }
  const scriptSrc = `https://plugins.stripo.email/static/rev/${STRIPO_VERSION}/stripo.js`;
  if (debugInit) {
    console.log("Creating Stripo script element.", scriptSrc);
  }
  const script = document.createElement("script");
  script.id = "stripoScript";
  script.type = "text/javascript";
  script.src = scriptSrc;
  script.onload = function initStripo() {
    if (debugInit) {
      console.log("Stripo script loaded.");
    }
    if (window.Stripo) {
      window.Stripo.init(initOptions);
    } else if (debugInit) {
      console.error("Expected window.Stripo.");
    }
  };
  if (debugInit) {
    console.log("Adding Stripo script element.");
  }
  document.body.appendChild(script);
}

function stopStripoEditor() {
  try {
    if (window.StripoApi) {
      window.StripoApi.stop();
    }
  } catch (err) {
    console.error(err);
  }
}

export function useStripoApi(options: StripoEditorOptions) {
  useOnMount(() => {
    const {
      // Non-StripoInitOptions props
      connectStripo,
      dealerId,
      ownerType,
      templateUid,
      user,
      // StripoInitOptions props
      ...opts
    } = options;
    loadStripoEditor({
      getAuthTokenAsync: connectStripo,
      apiRequestData: createApiRequestData({
        dealerId,
        templateUid,
        ownerType,
      }),

      userFullName: user.firstName
        ? `${user.firstName} ${user.lastName}`.trim()
        : user.email,
      ...opts,
    });
    // When the calling component is unmounted, stop the Stripo plugin.
    return stopStripoEditor;
  });
}
