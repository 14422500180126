import React from "react";
import { List, Datagrid, TextField, ReferenceField } from "react-admin";
import HttpIcon from "@material-ui/icons/Http";

export const TenantIcon = HttpIcon;

export const TenantList = props => (
  <List perPage={25} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <ReferenceField source="tenantTypeId" reference="lists/tenant_types">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="dealerId" reference="dealers">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="origin" />
    </Datagrid>
  </List>
);
