import * as React from "react";
import { Button } from "react-admin";
// Icons
import AddIcon from "@material-ui/icons/AddCircleOutline";

/**
 * A AddItemButton with custom label.
 * @param {import("react-admin").ButtonProps} props
 */
export const AddItemButton = props => {
  return (
    <Button label="ra.action.add" {...props}>
      <AddIcon />
    </Button>
  );
};
