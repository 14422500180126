import React from "react";
import {
  //
  Edit,
  SelectInput,
  ReferenceInput,
  SimpleForm,
  TextInput,
} from "react-admin";
// Local
import { sortBy } from "../../react-admin";

export const SaasLeadEdit = props => (
  <Edit mutationMode="pessimistic" {...props}>
    <SimpleForm>
      <TextInput source="email" fullWidth />
      <TextInput source="firstName" fullWidth />
      <TextInput source="lastName" fullWidth />
      <TextInput source="message" fullWidth multiline />
      <TextInput source="notes" fullWidth multiline />
      <ReferenceInput
        source="statusId"
        reference="lists/saas_lead_statuses"
        sort={sortBy.idAsc}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput
        source="ipAddr"
        label="IP address"
        InputProps={{ readOnly: true }}
      />
      <TextInput source="recaptchaScore" InputProps={{ readOnly: true }} />
    </SimpleForm>
  </Edit>
);
