import * as React from "react";
import { Button } from "react-admin";
// Icons
import CloseIcon from "@material-ui/icons/RemoveCircleOutline";

/**
 * A RemoveItemButton with confirmation.
 * @param {import("react-admin").ButtonProps} props
 */
export const RemoveItemButton = props => {
  return (
    <Button
      label="ra.action.remove"
      {...props}
      onClick={e => {
        if (!window.confirm("Are you sure?")) {
          return;
        }
        props.onClick(e);
      }}
    >
      <CloseIcon />
    </Button>
  );
};
