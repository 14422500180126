// Local
import { makeResource } from "../../react-admin";
import { NotificationList, NotificationIcon } from "./NotificationsList";
import { NotificationShow } from "./NotificationShow";

export const Notifications = makeResource({
  name: "notifications",
  icon: NotificationIcon,
  list: NotificationList,
  show: NotificationShow,
  options: {
    label: "Notifications",
  },
});
