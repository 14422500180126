import React from "react";
import cls from "clsx";
import {
  Button,
  // Checkbox,
  DialogActions,
  DialogContent,
  // FormControlLabel,
  // Grid,
  IconButton,
  // Tab,
  // Tabs,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { get, set } from "object-path";
import { set as setImmutable } from "object-path-immutable";
// Icons
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
// Local
import { browserType } from "../../config";
import { TabPanel } from "../../components/nav/TabPanel";
import { selectAllTarget } from "../../lib";

const useStyles = makeStyles(
  theme => ({
    content: {
      paddingTop: "0 !important",
    },
    centered: {
      textAlign: "center",
    },
    panel: {
      height: 640,
      overflowY: "auto",
      "& mark": {
        color: "#686868",
        backgroundColor: "#e7e7e7",
        padding: "4px 8px",
        borderRadius: 6,
        cursor: "default",
      },
    },
    p8: {
      padding: 8,
    },
    p16: {
      padding: 16,
    },
    cvlist: {
      // paddingTop: 16,
      paddingBottom: 16,
    },
    cvbox: {
      border: "1px solid rgba(0,0,0,0.5)",
      borderRadius: 6,
      padding: "0 8px 32px 8px",
      marginTop: 16,
    },
    cvhead: {
      textAlign: "right",
      marginRight: -8,
      marginBottom: -16,
    },
    actions: {
      justifyContent: "space-around",
    },
  }),
  {
    classNamePrefix: "MsgTemplateEditVars",
  },
);

const onFocusTextField = !browserType.iOS ? selectAllTarget : undefined;

const mergeTagHelp = "The tag to merge this field into the template.";

export const MsgTemplateEditVars = React.memo(
  /**
   * @typedef {import("../../resources/msg_templates/types").MsgTemplate} MsgTemplate
   * @typedef {"preview"|"custom"} TabId
   * @param {{
   * template:MsgTemplate,
   * data:{editorInfo:MsgTemplate["editor"],specInfo:MsgTemplate["spec"]}
   * onClose:()=>void,
   * onCompleted(result:any)=>void,
   * }} param0
   */
  function MsgTemplateEditVars({ template, data, onClose, onCompleted }) {
    const classes = useStyles();

    const {
      typeVars,
      previewVals,
      defaultCustomVars,
      addCustomVar,
      // onChangeTab,
      onClickCancel,
      onSubmit,
    } = React.useMemo(() => {
      const typeVars = template.typeInfo.vars ?? {};
      const previewVals = data.editorInfo.previewValues ?? {};
      const defaultCustomVars = data.specInfo.vars ?? {};
      return {
        typeVars,
        previewVals,
        defaultCustomVars,
        addCustomVar() {
          setCustomVars(vars => {
            const { length } = Object.keys(vars);
            const i = length + 1;
            return setImmutable(vars, `field${length}`, {
              name: `Field ${i}`,
            });
          });
        },
        onChangeTab(e, value) {
          setTab(value);
        },
        /** @param {React.SyntheticEvent<HTMLButtonElement>} e */
        onClickCancel(e) {
          onCompleted(false);
        },
        /** @param {React.FormEvent<HTMLFormElement>} e */
        onSubmit(e) {
          e.preventDefault();
          const elements = e.currentTarget.elements;
          const previewValues = {};
          // Build previewValues to save from each elements[code].value.
          for (const typeCode in typeVars) {
            const { field: typeField } = typeVars[typeCode];
            // TODO: Allow users to specify a dataValue id to load for this
            // record instead of getting the preview values from the inputs like
            // we do in the next loop...
            for (const code in typeField) {
              const { alias = code, sample = "" } = typeField[code];
              // NOTE: The inputName must match the rendered input's `name`.
              const inputName = `prev|${typeCode}|${alias}`;
              const value = ("" + elements[inputName].value).trim();
              if (
                // We're building a NEW previewValues, so only save a field when
                // we have a value and no sample...
                (value && !sample) ||
                // OR if the value is different from the sample.
                value !== sample
              ) {
                // ALWAYS use field alias to set previewValues.record keys...
                set(previewValues, `${typeCode}.${alias}`, value);
              }
            }
          }
          const values = {
            // TODO: Set saveAndReload to true when customVars has changed. Also
            // show a label between CANCEL and OK to say if we'll saveAndReload.
            saveAndReload: false,
            /** @type {MsgTemplate["editor"]} */
            editorInfo: { previewValues },
            /**
             * Give back the original specInfo for now since the specInfo editor
             * (Custom variables) is disabled.
             */
            specInfo: data.specInfo,
          };
          // console.log("SUBMIT", values);
          onCompleted(values);
        },
      };
    }, [template, data, onCompleted]);

    /** @type {[TabId,(value:TabId)=>TabId]} */
    const [tab, setTab] = React.useState("preview");
    const [customVars, setCustomVars] = React.useState(defaultCustomVars);
    const customVarKeys = Object.keys(customVars);
    const typeVarKeys = Object.keys(typeVars);

    return (
      <form onSubmit={onSubmit}>
        <DialogContent id="basic-dialog-content" className={classes.content}>
          {/* <Tabs value={tab} onChange={onChangeTab} centered variant="standard">
            <Tab value="preview" label="Preview values" />
            <Tab value="custom" label="Custom variables" />
          </Tabs> */}
          <TabPanel className={classes.panel} selected={tab === "preview"}>
            {typeVarKeys
              .map(code => [code, typeVars[code]])
              .map(
                ([typeCode, { field: typeField, name: typeName }], typeIdx) => (
                  <div key={typeCode}>
                    <h5>{typeName}</h5>
                    {Object.keys(typeField)
                      .map(code => [code, typeField[code]])
                      .map(
                        (
                          [
                            code,
                            {
                              alias = code,
                              name,
                              sample,
                              summary,
                              // The _Xyz props DO NOT exist in a typeFields item...
                              // This is just an easy way to create a shared value.
                              _key = `prev|${typeCode}|${alias}`,
                            },
                          ],
                          fieldIdx,
                        ) => (
                          <div key={_key} className={classes.p8}>
                            <TextField
                              label={name}
                              name={_key}
                              defaultValue={
                                get(previewVals, `${typeCode}.${alias}`) ??
                                sample ??
                                ""
                              }
                              fullWidth
                              helperText={summary}
                              InputProps={{
                                endAdornment: (
                                  <mark title={mergeTagHelp}>
                                    {`{{${typeCode}.${alias}}}`}
                                  </mark>
                                ),
                              }}
                              autoFocus={
                                (typeIdx === 0 &&
                                  fieldIdx === 0 &&
                                  !browserType.iOS) ||
                                undefined
                              }
                              onFocus={onFocusTextField}
                            />
                          </div>
                        ),
                      )}
                  </div>
                ),
              )}
            {customVarKeys.length > 0 && (
              <div>
                <hr />
                <h5>Custom variables</h5>
                {customVarKeys
                  .map(code => [code, customVars[code]])
                  .map(
                    ([
                      code,
                      {
                        name,
                        sample,
                        summary,
                        // The _Xyz props DO NOT exist in a typeFields item...
                        // This is just an easy way to create a shared value.
                        _key = `prev|msg|${code}`,
                      },
                    ]) => (
                      <div key={_key} className={classes.p8}>
                        <TextField
                          label={name}
                          name={_key}
                          defaultValue={
                            get(previewVals, `msg.${code}`) ?? sample ?? ""
                          }
                          fullWidth
                          helperText={summary}
                          // autoFocus={!browserType.iOS}
                          onFocus={onFocusTextField}
                        />
                      </div>
                    ),
                  )}
              </div>
            )}
          </TabPanel>
          <TabPanel className={classes.panel} selected={tab === "custom"}>
            {customVars.length > 0 && (
              <div className={classes.cvlist}>
                {customVars.map(({ name, code, prompt, summary, value }, i) => (
                  <div key={i} className={classes.cvbox}>
                    <div className={classes.cvhead}>
                      <IconButton
                        // onClick={removeCustomVar}
                        color="primary"
                        size="small"
                        title="Remove this variable"
                      >
                        <RemoveIcon />
                      </IconButton>
                    </div>
                    <TextField
                      label="Name of the variable"
                      name={`cust|${i}|name`}
                      defaultValue={name ?? ""}
                      helperText="A friendly label name for the variable."
                      fullWidth
                      required
                      // autoFocus={!browserType.iOS}
                      onFocus={onFocusTextField}
                    />
                    <TextField
                      label="Code for the variable"
                      name={`cust|${i}|code`}
                      defaultValue={code ?? ""}
                      helperText="e.g. the word 'field' in {{msg.field}}. Should be lower case, all one word, no punctuation or spaces."
                      fullWidth
                      required
                      // autoFocus={!browserType.iOS}
                      onFocus={onFocusTextField}
                    />
                    <TextField
                      label="Default value"
                      name={`cust|${i}|value`}
                      defaultValue={value ?? ""}
                      helperText="A default value to use if no other value is supplied."
                      fullWidth
                      // autoFocus={!browserType.iOS}
                      onFocus={onFocusTextField}
                    />
                    <TextField
                      label="Prompt text"
                      name={`cust|${i}|prompt`}
                      defaultValue={prompt ?? ""}
                      helperText="Optional text used to prompt the sender for a value."
                      fullWidth
                      // autoFocus={!browserType.iOS}
                      onFocus={onFocusTextField}
                    />
                    <TextField
                      label="Summary"
                      name={`cust|${i}|summary`}
                      defaultValue={summary ?? ""}
                      helperText="A hint for how the variable should be used."
                      fullWidth
                      // autoFocus={!browserType.iOS}
                      onFocus={onFocusTextField}
                    />
                  </div>
                ))}
              </div>
            )}
            <div className={cls(classes.p16, classes.centered)}>
              <Button
                variant="outlined"
                onClick={addCustomVar}
                startIcon={<AddIcon />}
                color="primary"
              >
                Add a custom variable
              </Button>
            </div>
          </TabPanel>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button
            variant="contained"
            // className={classes.cancelButton}
            onClick={onClickCancel}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            variant="contained"
            // className={classes.actionButton}
            // onClick={onClickOK}
            color="secondary"
          >
            OK
          </Button>
        </DialogActions>
      </form>
    );
  },
);
