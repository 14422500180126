import React from "react";
import {
  AutocompleteInput,
  DeleteWithConfirmButton,
  Edit,
  ReferenceInput,
  // SelectInput,
  SaveButton,
  SimpleForm,
  Toolbar,
} from "react-admin";
// Local
import { NonInput } from "../../components";

const EditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
    <NonInput>
      <div style={{ flex: 1 }} />
    </NonInput>
    <DeleteWithConfirmButton />
  </Toolbar>
);

const DealerLinkageEdit = props => (
  <Edit mutationMode="pessimistic" {...props}>
    <SimpleForm redirect="list" toolbar={<EditToolbar />}>
      <ReferenceInput source="fromId" reference="dealers" fullWidth>
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput source="toId" reference="dealers" fullWidth>
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);

export default DealerLinkageEdit;
