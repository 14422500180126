import React from "react";
import {
  //
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";
// Local
import {
  //
  DollarField,
  PercentField,
  ThousandsField,
} from "../../components";

export const SaasStatShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <ReferenceField source="dealerId" reference="dealers">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="appraisalTypeId"
        reference="lists/appraisal_types"
      >
        <TextField source="name" />
      </ReferenceField>
      <ThousandsField source="totalSubmitted" />
      <ThousandsField source="totalInspected" />
      <PercentField source="totalInspectedPct" title="of submitted" />
      <ThousandsField source="totalOfferSent" />
      <PercentField source="totalOfferSentPct" title="of submitted" />
      <ThousandsField source="totalOfferAccepted" />
      <PercentField source="totalOfferAcceptedPct" title="of sent" />
      <ThousandsField source="totalBuying" />
      <PercentField source="totalBuyingPct" title="of submitted or inspected" />
      <PercentField source="totalConvertedPct" title="of submitted" />
      <ThousandsField source="totalGrounding" />
      <PercentField source="totalGroundingPct" title="of inspected" />
      <DollarField source="totalExpectedProfit" />
      <DollarField source="totalExpectedProfitAvg" />
    </SimpleShowLayout>
  </Show>
);
