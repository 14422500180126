// Local
import { makeResource } from "../../react-admin";
import { MsgTemplateIcon } from "../msg_templates/MsgTemplateList";
import { DealerMsgTemplatePushEdit } from "./MsgTemplatePushEdit";

export const DealerMsgTemplatePush = makeResource({
  name: "dealer_msg_template_push",
  icon: MsgTemplateIcon,
  edit: DealerMsgTemplatePushEdit,
  options: {
    label: "Message template Push",
  },
});
