// Local
import SettingEdit from "./SettingEdit";
import SettingList, { SettingIcon } from "./SettingList";

export const Settings = {
  name: "settings",
  list: SettingList,
  edit: SettingEdit,
  icon: SettingIcon,
  options: {
    label: "Settings",
  },
};
