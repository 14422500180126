import React from "react";
import {
  DateField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";
// Local
export const ReminderShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <ReferenceField source="dealerId" reference="dealers" link="show">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="reason" />
      <TextField source="channel" />
      <TextField source="status" />
      <TextField source="to" />
      <DateField source="createdAt" showTime />
    </SimpleShowLayout>
  </Show>
);
