import React from "react";
import get from "lodash/get";
import { Typography } from "@material-ui/core";
import {
  ImageField,
  sanitizeFieldRestProps,
  useRecordContext,
} from "react-admin";
import QRCode from "qrcode";

const styles = {
  /** @type {React.CSSProperties} */
  image: {
    margin: "0.5rem",
    maxHeight: "10rem",
  },
};

export const QRCodeField = React.memo(
  /**
   * Shows a QRCode created from the given `source` value.
   * @param {import("react-admin").ImageFieldProps} props
   */
  function QRCodeField(props) {
    const {
      className,
      emptyText,
      source,
      title = "QR Code (Right click to open image in new tab and print)",
      ...rest
    } = props;
    const record = useRecordContext(props);
    const sourceValue = get(record, source);

    const [qrcode, setQrcode] = React.useState(undefined);
    React.useEffect(() => {
      const state = { mounted: true };
      QRCode.toDataURL(sourceValue).then(qrcode => {
        if (!state.mounted) return;
        setQrcode(qrcode);
      });
      return () => {
        state.mounted = false;
      };
    }, [sourceValue]);

    if (!sourceValue) {
      return emptyText ? (
        <Typography
          component="span"
          variant="body2"
          className={className}
          {...sanitizeFieldRestProps(rest)}
        >
          {emptyText}
        </Typography>
      ) : (
        <div className={className} {...sanitizeFieldRestProps(rest)} />
      );
    }

    return (
      <div className={className} {...sanitizeFieldRestProps(rest)}>
        <img
          title={title}
          alt={title}
          src={qrcode}
          // className={classes.image}
          style={styles.image}
        />
      </div>
    );
  },
);

QRCodeField.defaultProps = { ...ImageField.defaultProps, addLabel: false };
