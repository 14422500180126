import React from "react";
import { useHistory } from "react-router";
import {
  Button,
  DateField,
  EditButton,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
} from "react-admin";
// Icons
import FileCopyIcon from "@material-ui/icons/FileCopy";
// Local
import { REACT_APP_API_URL } from "../../config";
import { fetchJson } from "../../react-admin";

const EditToolbar = props => {
  const { basePath, data /* , resource */ } = props;
  const hist = useHistory();
  const onClickDuplicate = React.useCallback(
    /** @param {React.SyntheticEvent<HTMLElement>} e */
    async e => {
      e.stopPropagation();
      if (!window.confirm(`Duplicate this job?\n\n    ${data.name ?? ""}\n`)) {
        return;
      }
      const endpointUrl = `${REACT_APP_API_URL}/report_jobs/${data.id}/duplicate`;
      await fetchJson(endpointUrl, {
        method: "POST",
        body: JSON.stringify({ confirmed: true }),
      })
        .then(() => {
          hist.push(`/report_jobs`);
        })
        .catch(() => {
          alert("There was an error.\n\nPlease try again.");
        });
    },
    [data, hist],
  );
  return (
    <TopToolbar>
      <Button
        label="Duplicate"
        onClick={onClickDuplicate}
        title="Duplicate this job."
      >
        <FileCopyIcon />
      </Button>
      <EditButton basePath={basePath} record={data} />
    </TopToolbar>
  );
};

export const ReportJobShow = props => (
  <Show actions={<EditToolbar />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField source="reportId" reference="reports" link>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="dealerId"
        reference="dealers"
        emptyText="All Dealers"
        link
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="name" label="Job name" />
      <TextField source="schedule" />
      <DateField source="updatedAt" showTime />
      <DateField source="createdAt" showTime />
    </SimpleShowLayout>
  </Show>
);
