// Local
import { makeResource } from "../../react-admin";
import { SaasBlockCreate } from "./SaasBlockCreate";
import { SaasBlockList, SaasBlockIcon } from "./SaasBlockList";
import { SaasBlockEdit } from "./SaasBlockEdit";
// import { SaasBlockShow } from "./SaasBlockShow";

export const SaasBlocks = makeResource({
  name: "saas_blocklist",
  icon: SaasBlockIcon,
  list: SaasBlockList,
  edit: SaasBlockEdit,
  // show: SaasBlockShow,
  create: SaasBlockCreate,
  options: {
    category: "saas",
    label: "SaaS Blocklist",
  },
});
