import React from "react";
// import get from "lodash/get";
import { Typography } from "@material-ui/core";
import QRCode from "qrcode";

const styles = {
  /** @type {React.CSSProperties} */
  image: {
    margin: "0.5rem",
    maxHeight: "10rem",
  },
};

export const QRCodeImg = React.memo(
  /**
   * Shows a QRCode created from the given `text` value.
   */
  function QRCodeImg(props) {
    const {
      className,
      emptyText,
      text,
      title = "QR Code (Right click to open image in new tab and print)",
      ...rest
    } = props;

    const [qrcode, setQrcode] = React.useState(undefined);
    React.useEffect(() => {
      const state = { mounted: true };
      QRCode.toDataURL(text).then(qrcode => {
        if (!state.mounted) return;
        setQrcode(qrcode);
      });
      return () => {
        state.mounted = false;
      };
    }, [text]);

    if (!text) {
      return emptyText ? (
        <Typography
          component="span"
          variant="body2"
          className={className}
          {...rest}
        >
          {emptyText}
        </Typography>
      ) : (
        <div className={className} {...rest} />
      );
    }

    return (
      <div className={className} {...rest}>
        <img
          title={title}
          alt={title}
          src={qrcode}
          // className={classes.image}
          style={styles.image}
        />
      </div>
    );
  },
);

// QRCodeImg.defaultProps = { addLabel: false };
