import React from "react";
import { makeStyles } from "@material-ui/core";
import { BooleanField } from "react-admin";

const useStyles = makeStyles(
  theme => ({
    root: {
      display: "flex",
      paddingTop: 8,
      "& > small": {
        color: "rgba(0 ,0 ,0 , 0.54)",
        paddingLeft: 16,
      },
    },
  }),
  {
    classNamePrefix: "BoolShowField",
  },
);

export const BoolShowField = React.memo(
  /**
   * @param {{label:string,source:string}} param0
   */
  function BoolShowField({ label, source }) {
    const classes = useStyles();

    return (
      <div className={classes.root}>
        <BooleanField source={source} looseValue />
        <small>{label}</small>
      </div>
    );
  },
);
