import React from "react";
import { NumberField } from "react-admin";

const options = { style: "currency", currency: "USD" };
/**
 * @param {import("react-admin").NumberFieldProps} props
 */
export function DollarField(props) {
  return <NumberField options={options} {...props} />;
}
DollarField.defaultProps = { ...NumberField.defaultProps };
