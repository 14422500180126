import React from "react";
import {
  //
  Button,
  DateField,
  EditButton,
  EmailField,
  FunctionField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  UrlField,
} from "react-admin";
// Icons
import FindReplaceIcon from "@material-ui/icons/FindReplace";
// Local
import {
  DollarField,
  LinkButton,
  PhoneField,
  ThousandsField,
} from "../../components";
import { REACT_APP_API_URL } from "../../config";
import { fetchJson } from "../../react-admin";

const EditToolbar = props => {
  const { basePath, data /* , resource */ } = props;
  const onClickPullPave = React.useCallback(
    /** @param {React.SyntheticEvent<HTMLElement>} e */
    async e => {
      e.stopPropagation();
      if (!window.confirm("Are you sure?")) {
        return;
      }
      const endpointUrl = `${REACT_APP_API_URL}/appraisals/${data.id}/pull-pave-result`;
      await fetchJson(endpointUrl, {
        method: "POST",
        body: JSON.stringify({
          // TODO: Ask the user for these with window.confirm at least...
          // notifyCustomer: true,
          // notifyDealers: true,
        }),
      })
        .then(({ json: { ok, message } = {} }) => {
          if (!ok) {
            alert(message);
            return;
          }
          alert("OK, please check the Dealer UI for results.");
        })
        .catch(() => {
          alert("There was an error.\n\nPlease try again.");
        });
    },
    [data],
  );
  return (
    <TopToolbar>
      <LinkButton
        list="appraisal_api_results"
        // filter={record => ({ appraisalId: record.id })}
        filter={{ appraisalId: data?.id }}
        label="API Results"
        variant="outlined"
      />
      <Button
        label="Pull PAVE Results"
        onClick={onClickPullPave}
        title="If we already have results, they won't be pulled again."
      >
        <FindReplaceIcon />
      </Button>
      <EditButton basePath={basePath} record={data} />
    </TopToolbar>
  );
};

export const AppraisalShow = props => (
  <Show actions={<EditToolbar />} {...props}>
    <SimpleShowLayout>
      <TextField source="uid" />
      <ReferenceField source="dealerId" reference="dealers" link="show">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="appraisalSourceTypeId" />
      <ReferenceField
        source="appraisalTypeId"
        reference="lists/appraisal_types"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="campaignId" reference="dealer_campaigns">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="originatorId" reference="dealer_users">
        <FunctionField render={it => `${it.firstName} ${it.lastName}`} />
      </ReferenceField>
      <TextField source="vin" />
      <ThousandsField source="mileage" />
      <TextField source="color" />
      <TextField source="colorInt" />
      <TextField source="year" />
      <TextField source="make" />
      <TextField source="model" />
      <TextField source="trim" />
      <TextField source="subSeries" />
      <TextField source="featureIds" />
      <TextField source="options" />
      <TextField source="packageNotes" />
      <TextField source="accidents" />
      <TextField source="ownerStatusId" />
      <TextField source="dealerBankId" />
      <TextField source="financeBankId" />
      <TextField source="financeBank" />
      <DollarField source="payoffAmount" />
      <TextField source="maturityDate" />
      <TextField source="runningStatus" />
      <TextField source="address1" />
      <TextField source="address2" />
      <TextField source="city" />
      <TextField source="state" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <EmailField source="email" />
      <PhoneField source="phoneNumber" />
      <TextField source="zip" />
      <TextField source="createdAt" />
      <TextField source="updatedAt" />
      <ReferenceField
        source="appraisalStatusId"
        reference="lists/appraisal_statuses"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="sellStrategyStatusId" />
      <TextField source="profitObjective" />
      <DollarField source="pickupFee" />
      <DollarField source="reconditioningCost" />
      <DollarField source="adjustedPayoffAmount" />
      <DollarField source="carOfferAmount" />
      <DollarField source="auctionValuation" />
      <DollarField source="retailValuation" />
      <DollarField source="carOfferFee" />
      <DollarField source="auctionFee" />
      <DollarField source="wholesalePack" />
      <DollarField source="carOfferProjectedValuation" />
      <DollarField source="auctionProjectedValuation" />
      <DollarField source="retailProjectedValuation" />
      <DollarField source="carOfferProposal" />
      <DollarField source="auctionProposal" />
      <DollarField source="retailProposal" />
      <DollarField source="carOfferTotalProfit" />
      <DollarField source="auctionTotalProfit" />
      <DollarField source="retailTotalProfit" />
      <DollarField source="carOfferProjectedEquity" />
      <DollarField source="auctionProjectedEquity" />
      <DollarField source="retailProjectedEquity" />
      <DollarField source="expectedTotalProfit" />
      <DollarField source="mmrVal" />
      <TextField source="mmrValResultId" />
      <DollarField source="jdpVal" />
      <TextField source="jdpValResultId" />
      <TextField source="paveInspectResultId" />
      <TextField source="paveInspectSessionKey" />
      <ThousandsField source="inspectedMileage" />
      <TextField source="vautoId" />
      <UrlField source="vautoUrl" />
      <TextField source="deletedAt" />
      <TextField source="deletedBy" />
      <DateField source="offerSent" showTime />
      <TextField source="offerSentBy" />
      <DollarField source="offerAmount" />
      <DateField source="offerAccepted" showTime />
      <DollarField source="galvesVal" />
      <TextField source="galvesValResultId" />
      <DollarField source="wholesalePack" />
      <TextField source="dmsId" label="DMS Id" />
      <DollarField source="actualCost" />
    </SimpleShowLayout>
  </Show>
);
