import React from "react";
import CodeIcon from "@material-ui/icons/Code";
import {
  AutocompleteInput,
  Datagrid,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  TextField,
  DateField,
  BooleanField,
} from "react-admin";
// Local
import { clearFilterButton } from "../../components";

export const PaveCredIcon = CodeIcon;

const PaveCredFilter = props => (
  <Filter {...props}>
    <ReferenceInput source="dealerId" reference="dealers" alwaysOn>
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    {clearFilterButton()}
  </Filter>
);

const PaveCredList = props => (
  <List
    {...props}
    filters={<PaveCredFilter />}
    sort={{ field: "dealerId", order: "ASC" }}
    perPage={25}
  >
    <Datagrid optimized>
      <TextField source="id" />
      <ReferenceField source="dealerId" reference="dealers">
        <TextField source="name" />
      </ReferenceField>
      <BooleanField
        source="deactivatedAt"
        looseValue
        valueLabelTrue="Dealer is active"
        valueLabelFalse="Dealer not active"
        label={"Is active"}
      />
      <DateField source="updatedAt" showTime />
    </Datagrid>
  </List>
);

export default PaveCredList;
