import { ReferenceField } from "react-admin";

// Disable edit links that cause confusion when clicking a resource list item.
ReferenceField.defaultProps.link = "";

export { Link } from "react-admin";
// Local
export * from "./buttons/AddItemButton";
export * from "./buttons/ClearFilterButton";
export * from "./buttons/GroupedIconButton";
export * from "./buttons/LinkButton";
export * from "./buttons/RemoveItemButton";
export * from "./buttons/DeactivateButton";

export * from "./errors/ErrorSentry";

export * from "./feedback/BasicDialog";
export * from "./feedback/CancelSaveDialogActions";
export * from "./feedback/SimpleDialog";

export * from "./fields/BoolNumField";
export * from "./fields/BoolShowField";
export * from "./fields/DollarField";
export * from "./fields/FullNameField";
export * from "./fields/JsonField";
export * from "./fields/PercentField";
export * from "./fields/PhoneField";
export * from "./fields/PhoneTextField";
export * from "./fields/QRCodeField";
export * from "./fields/NonField";
export * from "./fields/ThousandsField";
export * from "./forms/SaveOnlyToolbar";

export * from "./icons";

export * from "./inputs/BoolNumInput";
export * from "./inputs/InputEx";
export * from "./inputs/NonInput";
export * from "./inputs/PreferenceInput";
export * from "./inputs/SelectReferenceInput";
export * from "./inputs/SettingInput";
export * from "./inputs/SqlEditorInput";
export * from "./inputs/TextInputEx";

export * from "./layout/Divider";
