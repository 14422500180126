import React from "react";
import {
  Create,
  // ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";
// Local
import { caption } from "../../components";
import { audiences } from "./ReportEdit";

export const ReportCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="edit">
      <TextInput source="name" fullWidth autoFocus validate={required()} />
      <SelectInput
        source="audience"
        choices={audiences}
        validate={required()}
      />
      {caption("Save the report first, in order to add queries...")}
    </SimpleForm>
  </Create>
);
