import React from "react";
import {
  BooleanInput,
  Filter,
  List,
  Datagrid,
  DateField,
  TextField,
  TextInput,
} from "react-admin";
import EmojiTransportationIcon from "@material-ui/icons/EmojiTransportation";
// Local
import { clearFilterButton } from "../../components";

export const DealerIcon = EmojiTransportationIcon;

const DealerFilter = props => (
  <Filter {...props}>
    <TextInput source="name" alwaysOn />
    <TextInput source="phoneNumber" alwaysOn />
    <BooleanInput
      source="deleted"
      label="Show deactivated"
      defaultValue={true}
    />
    {clearFilterButton()}
  </Filter>
);

export const DealerList = props => (
  <List
    {...props}
    bulkActionButtons={false}
    filters={<DealerFilter />}
    perPage={25}
    sort={{ field: "id", order: "DESC" }}
  >
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="publicName" />
      <TextField source="phoneNumber" />
      <TextField source="city" />
      <TextField source="state" />
      <TextField source="zip" />
      <DateField source="deactivatedAt" showTime />
    </Datagrid>
  </List>
);
