// Local
import { makeResource } from "../../react-admin";
import { DealerMsgTemplateCreate } from "./DealerMsgTemplateCreate";
import {
  DealerMsgTemplateList,
  DealerMsgTemplateIcon,
} from "./DealerMsgTemplateList";
import { DealerMsgTemplateEdit } from "./DealerMsgTemplateEdit";
import { DealerMsgTemplateShow } from "./DealerMsgTemplateShow";

export const DealerMsgTemplates = makeResource({
  name: "dealer_msg_templates",
  icon: DealerMsgTemplateIcon,
  list: DealerMsgTemplateList,
  edit: DealerMsgTemplateEdit,
  show: DealerMsgTemplateShow,
  create: DealerMsgTemplateCreate,
  options: {
    label: "Dealer message templates",
  },
});
