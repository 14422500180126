// Local
import { makeResource } from "../../react-admin";
import { MsgTemplateIcon } from "../msg_templates/MsgTemplateList";
import { DealerMsgTemplateSmsEdit } from "./MsgTemplateSmsEdit";

export const DealerMsgTemplateSms = makeResource({
  name: "dealer_msg_template_sms",
  icon: MsgTemplateIcon,
  edit: DealerMsgTemplateSmsEdit,
  options: {
    label: "Message template SMS",
  },
});
