// Local
import { DealerCampaignCreate } from "./DealerCampaignCreate";
import { DealerCampaignEdit } from "./DealerCampaignEdit";
import { DealerCampaignList, DealerCampaignIcon } from "./DealerCampaignList";
import { DealerCampaignShow } from "./DealerCampaignShow";

export const DealerCampaigns = {
  name: "dealer_campaigns",
  create: DealerCampaignCreate,
  list: DealerCampaignList,
  edit: DealerCampaignEdit,
  icon: DealerCampaignIcon,
  show: DealerCampaignShow,
  options: {
    label: "Dealer campaigns",
  },
};
