// Local
import DealerBidderCreate from "./DealerBidderCreate";
import DealerBidderEdit from "./DealerBidderEdit";
import DealerBidderList, { DealerBidderIcon } from "./DealerBidderList";

export const DealerBidders = {
  name: "dealer_bidders",
  create: DealerBidderCreate,
  list: DealerBidderList,
  edit: DealerBidderEdit,
  icon: DealerBidderIcon,
  options: {
    label: "Dealer bidders",
  },
};
