// Local
import { makeResource } from "../../react-admin";
import { ReportJobList, ReportJobIcon } from "./ReportJobList";
import { ReportJobEdit } from "./ReportJobEdit";
import { ReportJobShow } from "./ReportJobShow";
import { ReportJobCreate } from "./ReportJobCreate";

export const ReportJobs = makeResource({
  name: "report_jobs",
  icon: ReportJobIcon,
  list: ReportJobList,
  edit: ReportJobEdit,
  show: ReportJobShow,
  create: ReportJobCreate,
  options: {
    label: "Report Jobs",
  },
});
