// Local
import { makeResource } from "../../react-admin";
import { AppraisalList, AppraisalIcon } from "./AppraisalList";
// import { AppraisalEdit } from "./AppraisalEdit";
import { AppraisalShow } from "./AppraisalShow";

export const Appraisals = makeResource({
  name: "appraisals",
  icon: AppraisalIcon,
  list: AppraisalList,
  // edit: AppraisalEdit,
  show: AppraisalShow,
  options: {
    label: "Appraisals",
  },
});
