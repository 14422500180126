import React from "react";
import {
  //
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  TextField,
} from "react-admin";
import { SvgIcon, makeStyles } from "@material-ui/core";
import ViewListIcon from "@material-ui/icons/ViewList";
// Local
import { FullNameField, clearFilterButton } from "../../components";

export const MsgVersionIcon = ViewListIcon;

const defaultSort = { field: "id", order: "desc" };

export const useStyles = makeStyles(
  theme => ({
    wtext2: {
      minWidth: 480,
    },
    binput: {
      marginBottom: 8,
    },
  }),
  {
    classNamePrefix: "MsgVersionFilter",
  },
);

function MsgVersionFilter(props) {
  const classes = useStyles();
  return (
    <Filter {...props}>
      <ReferenceInput
        source="templateId"
        reference="msg_templates"
        alwaysOn
        label="Template"
        className={classes.wtext2}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <BooleanInput
        className={classes.binput}
        source="isCurrentPub"
        label="Show currently published versions"
        defaultChecked={true}
        defaultValue={true}
      />
      {clearFilterButton()}
    </Filter>
  );
}
export const MsgVersionList = props => (
  <List
    {...props}
    bulkActionButtons={false}
    exporter={false}
    filters={<MsgVersionFilter />}
    perPage={25}
    sort={defaultSort}
    title="Message versions"
  >
    <Datagrid rowClick="show">
      <ReferenceField
        source="templateId"
        reference="msg_templates"
        label="Template"
      >
        <TextField source="name" />
      </ReferenceField>
      <BooleanField
        source="isCurrentPub"
        label="Current"
        looseValue
        FalseIcon={SvgIcon}
      />
      <TextField source="id" label="Version" />
      <TextField source="summary" />
      <DateField source="publishedAt" showTime />
      <ReferenceField source="publishedBy" reference="admin_users">
        <FullNameField />
      </ReferenceField>
    </Datagrid>
  </List>
);
