import React from "react";
import {
  AutocompleteInput,
  Create,
  ReferenceInput,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
} from "react-admin";
// Local
// import { SelectReferenceInput } from "../../components";

const EditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const DealerBankCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="list" toolbar={<EditToolbar />}>
      <ReferenceInput source="dealerId" reference="dealers" autoFocus fullWidth>
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <TextInput source="name" fullWidth />
    </SimpleForm>
  </Create>
);

export default DealerBankCreate;
