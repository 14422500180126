import React from "react";
import {
  Dialog,
  DialogTitle,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";
// Icons
import CloseIcon from "@material-ui/icons/Close";
// Local

const useStyles = makeStyles(
  theme => ({
    root: {
      "& .MuiDialogContent-root": {
        flex: 1,
        paddingTop: 24,
      },
      "& .MuiDialog-paper": {
        "& > form": {
          display: "flex",
          flexDirection: "column",
          flex: 1,
        },
      },
      [theme.breakpoints.up("sm")]: {
        "& h6": {
          fontSize: 24,
          fontWeight: 600,
        },
      },
      [theme.breakpoints.down("sm")]: {
        "& .MuiDialog-paper": {
          margin: 0,
          maxHeight: "unset",
        },
        "& .MuiDialogContent-root": {
          // padding: "28px 8px 8px 8px",
          padding: 8,
          paddingTop: 24,
        },
      },
    },
    stretchY: {
      "& .MuiDialog-paper": {
        height: "100%",
      },
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    title: {
      paddingBottom: 0,
    },
    titleText: { display: "flex", alignContent: "center" },
    titleIcon: { marginRight: 6, marginTop: 4 },
  }),
  {
    classNamePrefix: "SimpleDialog",
  },
);

export const SimpleDialog = React.memo(
  /**
   * @typedef {import("@material-ui/core").DialogProps} DialogProps
   *
   * @typedef {object} SimpleDialogProps
   * @property {React.Children} children
   * @property {React.ComponentType} [icon]
   * @property {string} [name] Name of the dialog for creating an HTML id.
   * @property {boolean} open True if dialog should be showing.
   * @property {import("@material-ui/core").ModalProps['onClose']} onClose
   * @property {boolean} [stretchY] Makes height 100%.
   * @property {React.ReactNode} title Visual title text or component.
   *
   * @param {SimpleDialogProps & DialogProps} props
   */
  function SimpleDialog(props) {
    const {
      //
      children,
      className,
      icon: DialogIcon,
      name = `sdlg-${Date.now()}`,
      open,
      onClose,
      title,
      stretchY,
      ...dialogProps
    } = props;
    const classes = useStyles();

    const titleId = `${name}-title`;
    // CONSIDER: Wrap the children of SimpleDialog with some common element(s).
    return (
      <Dialog
        className={clsx(classes.root, stretchY && classes.stretchY, className)}
        onClose={onClose}
        open={open}
        aria-labelledby={titleId}
        {...dialogProps}
      >
        <DialogTitle disableTypography id={titleId} className={classes.title}>
          {title && (
            <Typography variant="h6" className={classes.titleText}>
              {DialogIcon ? <DialogIcon className={classes.titleIcon} /> : null}
              {title}
            </Typography>
          )}
          {onClose ? (
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        {children}
      </Dialog>
    );
  },
);
