import React from "react";
import {
  AutocompleteInput,
  Datagrid,
  DateField,
  BooleanInput,
  Filter,
  List,
  ReferenceInput,
  // ReferenceField,
  // SelectInput,
  TextField,
  TextInput,
  // TopToolbar,
} from "react-admin";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
// Local
import { clearFilterButton } from "../../components";

export const DealerUserIcon = SupervisedUserCircleIcon;

const DealerUserFilter = props => (
  <Filter {...props}>
    <ReferenceInput source="dealerId" reference="dealers" alwaysOn>
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <TextInput source="firstName" alwaysOn />
    <TextInput source="lastName" alwaysOn />
    <TextInput source="email" alwaysOn />
    <BooleanInput source="deleted" label="Show deleted" defaultValue={true} />
    {clearFilterButton()}
  </Filter>
);

export const DealerUserList = props => (
  <List
    {...props}
    bulkActionButtons={false}
    filters={<DealerUserFilter />}
    perPage={25}
    sort={{ field: "id", order: "DESC" }}
    title="Dealer users"
  >
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="email" />
      <TextField source="phoneNumber" label="Phone" />
      <DateField source="createdAt" label="Created" showTime />
      <DateField source="updatedAt" label="Updated" showTime />
      <DateField source="deletedAt" label="Deleted" showTime />
    </Datagrid>
  </List>
);
