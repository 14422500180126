import React from "react";
import {
  Datagrid,
  DateField,
  BooleanInput,
  Filter,
  List,
  // ReferenceInput,
  // ReferenceField,
  // SelectInput,
  TextField,
  TextInput,
  // TopToolbar,
} from "react-admin";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
// Local
import { clearFilterButton } from "../../components";

export const AdminUserIcon = VerifiedUserIcon;

const AdminUserFilter = props => (
  <Filter {...props}>
    <TextInput source="firstName" alwaysOn />
    <TextInput source="lastName" alwaysOn />
    <TextInput source="email" alwaysOn />
    <BooleanInput source="deleted" label="Show deleted" defaultValue={true} />
    {clearFilterButton()}
  </Filter>
);

export const AdminUserList = props => (
  <List
    {...props}
    bulkActionButtons={false}
    filters={<AdminUserFilter />}
    perPage={25}
    sort={{ field: "id", order: "DESC" }}
    title="Admin users"
  >
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="email" />
      <TextField source="phoneNumber" label="Phone" />
      <DateField source="createdAt" label="Created" showTime />
      <DateField source="updatedAt" label="Updated" showTime />
      <DateField source="deletedAt" label="Deleted" showTime />
    </Datagrid>
  </List>
);
