import React from "react";
import { Button } from "react-admin";
// import {
//   //
//   DialogContent,
//   // makeStyles,
// } from "@material-ui/core";
// Icon
import ImportExportIcon from "@material-ui/icons/ImportExport";
// Local
// import {
//   //
//   SimpleDialog,
//   SelectReferenceInput,
//   CancelSaveDialogActions,
// } from "../../components";
import { REACT_APP_API_URL } from "../../config";
import { fetchJson } from "../../react-admin";

// const useStyles = makeStyles(
//   theme => ({
//     root: {
//       // margin: 0,
//     },
//   }),
//   {
//     classNamePrefix: "ImportDealerDemoRecords",
//   },
// );

// export const ImportDealerDemoRecordsDialog = React.memo(
//   /**
//    * @param {{basePath:string,data:any,resource:any}} param0
//    */
//   function ImportDealerDemoRecordsDialog({ basePath, data, resource }) {
//     const [dealers, setDealers] = React.useState([]);

//     React.useEffect(
//       /** @param {React.SyntheticEvent<HTMLElement>} e */
//       e => {
//         e.stopPropagation();

//         const endpointUrl = `${REACT_APP_API_URL}/dealers`;
//         fetchJson(endpointUrl).then(data => {
//           console.log("DATA", data);
//           setDealers(data.json);
//         });
//       },
//       [],
//     );

//     return (
//       <SimpleDialog>
//         <form>
//           <DialogContent>
//             <SelectField
//               name="fromDealer"
//               label="From dealer"
//               items={dealers}
//               // disabled={loading}
//             />
//           </DialogContent>
//           <CancelSaveDialogActions
//             // disabled={loading}
//             // onCancel={onClose}
//             saveLabel="Import"
//             topBorder
//           />
//         </form>
//       </SimpleDialog>
//     );
//   },
// );

export const ImportDealerDemoRecordsButton = React.memo(
  /**
   * @param {{
   * basePath:string,
   * children:React.ReactNode,
   * record:any,
   * resource:any
   * } & import("react-admin").ButtonProps } param0
   */
  function ImportDealerDemoRecords({
    basePath,
    children,
    record,
    resource,
    ...buttonProps
  }) {
    // const [editing, setEditing] = React.useState(false);
    const [importing, setImporting] = React.useState(false);
    const onClick = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      e => {
        if (e && e.stopPropagation) e.stopPropagation();
        if (e && e.preventDefault) e.preventDefault();
        // setEditing(true);

        const prefs = JSON.parse(
          window.localStorage.getItem("impDemoPrefs") || "{}",
        );
        function savePrefs(newPrefs) {
          Object.assign(prefs, newPrefs);
          window.localStorage.setItem("impDemoPrefs", JSON.stringify(prefs));
        }

        if (
          !window.confirm(
            `Import demo records from Brooklyn Chrysler?\n\n` +
              `NOTE: Related records such as campaigns and dealership users ` +
              `will also be imported. ` +
              `All imported email addresses and phone numbers will be replaced.`,
          )
        ) {
          return;
        }
        const days = parseInt(
          window.prompt("How many days worth?", prefs.days ?? "90"),
        );
        if (!days) {
          return;
        }
        savePrefs({ days });

        const customerEmail = (
          window.prompt(
            "Please enter a base email address for CUSTOMERs.\n\n" +
              "This email used to replace emails in records that have them.\n" +
              "NOTE: The address should accept the Gmail plus sign alias format.",
            prefs.customerEmail ?? "achester@smgec.com",
          ) ?? ""
        )
          .trim()
          .toLowerCase();
        if (
          !customerEmail ||
          !customerEmail.includes("@") ||
          !customerEmail.includes(".")
        ) {
          return;
        }
        savePrefs({ customerEmail });

        const originatorUserId = parseInt(
          window.prompt(
            "User id to replace in records that have an originator.\n\n" +
              "This defaults to user Alex Chester of Test Dealer 1.\n" +
              "To find a different user id, go to the Dealer users list.",
            prefs.originatorUserId ?? "5111",
          ),
        );
        if (!originatorUserId) {
          return;
        }
        savePrefs({ originatorUserId });

        const endpointUrl = `${REACT_APP_API_URL}/dealers/${record.id}/import-demo-records`;
        setImporting(true);
        fetchJson(endpointUrl, {
          method: "POST",
          body: JSON.stringify({
            days,
            customerEmail,
            originatorUserId,
          }),
        })
          .then(res => {
            const { json: data } = res;
            const { imported } = data;
            window.alert(`Imported ${imported} records.`);
          })
          .catch(err => {
            window.alert(`Failed to import records.`);
          })
          .finally(() => {
            setImporting(false);
          });
      },
      [record],
    );
    // const onClose = React.useCallback(
    //   /** @param {React.SyntheticEvent<HTMLElement>} e */
    //   e => {
    //     setEditing(false);
    //   },
    //   [],
    // );

    const Marquee = "marquee";
    return (
      <Button
        disabled={importing}
        label={
          importing ? (
            <Marquee>
              IMPORTING NOW...WAIT A FEW MINUTES FOR CONFIRMATION!
            </Marquee>
          ) : (
            "Import Demo Records"
          )
        }
        onClick={onClick}
        {...buttonProps}
      >
        {children || <ImportExportIcon />}
      </Button>
    );

    // See https://stackoverflow.com/questions/51306591/react-admin-create-and-edit-in-modal
    // return (
    //   <>
    //     <Button label="Import Demo Records" onClick={onClick} {...buttonProps}>
    //       {children || <ImportExportIcon />}
    //     </Button>
    //     {editing && (
    //       <ImportDealerDemoRecordsDialog
    //         onClose={onClose}
    //         basePath={basePath}
    //         record={record}
    //         resource={resource}
    //       />
    //     )}
    //   </>
    // );
  },
);
