// Local
import { makeResource } from "../../react-admin";
import { DealerList, DealerIcon } from "./DealerList";
import { DealerEdit } from "./DealerEdit";
import { DealerShow } from "./DealerShow";
import { DealerCreate } from "./DealerCreate";

export const Dealers = makeResource({
  name: "dealers",
  icon: DealerIcon,
  list: DealerList,
  edit: DealerEdit,
  show: DealerShow,
  create: DealerCreate,
  options: {
    label: "Dealers",
  },
});
