import React from "react";
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";
import { Datagrid, Filter, List, TextField, TextInput } from "react-admin";
// Local

export const SettingIcon = SettingsApplicationsIcon;

const SettingFilter = props => (
  <Filter {...props}>
    <TextInput source="name" alwaysOn />
    {/* <TextInput source="description" alwaysOn /> */}
  </Filter>
);

const SettingList = props => (
  <List
    {...props}
    filters={<SettingFilter />}
    // filterDefaultValues={{ field: "value" }}
    sort={{ field: "name", order: "ASC" }}
    bulkActionButtons={false}
    perPage={25}
  >
    <Datagrid optimized rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="value" />
      <TextField source="description" />
    </Datagrid>
  </List>
);

export default SettingList;
