// Local
import { makeResource } from "../../react-admin";
import { MsgVersionList, MsgVersionIcon } from "./MsgVersionList";
import { MsgVersionEdit } from "./MsgVersionEdit";
import { MsgVersionShow } from "./MsgVersionShow";

export const MsgVersions = makeResource({
  name: "msg_versions",
  icon: MsgVersionIcon,
  list: MsgVersionList,
  edit: MsgVersionEdit,
  show: MsgVersionShow,
  options: {
    // hidden: true,
    label: "Message versions",
  },
});
