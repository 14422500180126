import type { FilterHandler } from "liquidjs/dist/src/template/filter-impl-options";
import { Liquid, LiquidError, filters } from "liquidjs";
// Local
import { REACT_APP_API_URL } from "../../config";
import { capitalCase } from "../../lib";
import { FileUploader } from "../../lib/files/FileUploader";
import {
  // authState,
  fetchText,
  fetchJson,
} from "../../react-admin";
import {
  DealerMsgTemplate,
  MsgCondition,
  MsgRecord,
  MsgTemplate,
  MsgVersion,
} from "./types";

export { LiquidError };

const tplEngine = new Liquid();

// #region Custom Liquid filters
/** Built-in filter, use in custom filter `append.call(this,...args)` */
const append = filters["append"] as FilterHandler;
/** Built-in filter, use in custom filter `getDefault.call(this,...args)` */
const getDefault = filters["default"] as FilterHandler;
/** Built-in filter, use in custom filter `prepend.call(this,...args)` */
const prepend = filters["prepend"] as FilterHandler;
// A shorter alias for "default" - https://liquidjs.com/filters/default.html
tplEngine.registerFilter("or", getDefault);
tplEngine.registerFilter(
  "post",
  /** An "append" filter that only appends when there is a value. */
  function post(value: any, postfix: string) {
    value = getDefault.call(this, value, false);
    if (value === false) return "";
    return append.call(this, value, postfix);
  },
);
tplEngine.registerFilter(
  "pre",
  /** A "prepend" filter that only prepends when there is a value. */
  function pre(value: any, prefix: string) {
    value = getDefault.call(this, value, false);
    if (value === false) return "";
    return prepend.call(this, value, prefix);
  },
);
// console.log("FILTERS", tplEngine.filters);
// #endregion

const AssetFilenames = MsgTemplate.AssetFilenames;

/** Builds variable values needed to render a template. */
export function buildMsgValues(
  template: MsgTemplate,
  options: {
    /** Values from the database. e.g. {dealer:{name,phone,etc},cust:{...}} */
    dataValues?: MsgTemplate["dataValues"];
    /** User preview values, e.g. {cust:{fname,lname},user:{...}}. */
    previewValues?: MsgTemplate.EditorInfo["previewValues"];
  } = {},
) {
  const {
    dataValues = template.dataValues ?? {},
    previewValues = template.editor?.previewValues ?? {},
  } = options;
  const values: Record<string, any> = {};
  /** Our vars categories */
  const msgTypeVars = template.typeInfo?.vars ?? {};
  for (const code in msgTypeVars) {
    const vars = msgTypeVars[code];
    const dataRecord = dataValues[code] ?? {};
    let previewRecord = previewValues[code] ?? {}; // Could be id. Need object.
    if (typeof previewRecord !== "object") previewRecord = {};
    const record = {};
    const { field } = vars;
    for (const fldKey in field) {
      const fld = field[fldKey];
      const { alias = fldKey, sample } = fld;
      record[alias] =
        previewRecord[alias] ?? dataRecord[fldKey] ?? sample ?? "";
    }
    values[code] = record;
  }
  return values;
}

export async function connectStripo() {
  const { json: data } = await fetchJson(
    `${REACT_APP_API_URL}/user/connect-stripo`,
  );
  return data;
}

function createMergeTagsAndConditions(template: MsgTemplate) {
  const {
    typeInfo: {
      //
      conditions: msgConditions = {},
      vars: msgVars = {},
    } = {},
  } = template;
  // #region Create mergeTags
  const mergeTags: StripoMergeTags[] = [];
  for (const recordKey in msgVars) {
    const rec = msgVars[recordKey];
    const entries: StripoMergeTag[] = [];
    for (const fldKey in rec.field) {
      const fld = rec.field[fldKey];
      const { alias = fldKey } = fld;
      entries.push({
        label: fld.name,
        value: !fld.value
          ? `{{${recordKey}.${alias}}}`
          : `{{${recordKey}.${alias}|or:"${fld.value}"}}`,
        hint: fld.summary,
      });
    }
    mergeTags.push({
      category: rec.name,
      entries,
    });
  }
  // #endregion
  // #region Create conditionCategories
  const conditionCategories: Array<
    StripoExternalConditions | StripoPredefinedConditions
  > = [];
  for (const recKey in msgConditions) {
    const cnd = msgConditions[recKey];
    const rec = msgVars[recKey]; // may not exist...
    const field = rec?.field;
    const category = cnd.category ?? rec?.name ?? capitalCase(recKey);
    const conditions: StripoPredefinedCondition[] = [];
    for (const cndKey in cnd) {
      if (cndKey === "category") continue;
      let cndSpec = cnd[cndKey];
      if (Array.isArray(cndSpec)) {
        const [alias, op, operand] = cndSpec;
        const fld = findFieldByAlias(field, alias);
        const fldName = fld?.name ?? alias;
        const opLabel = MsgCondition.opLabel[op] ?? op;
        cndSpec = {
          name: `${category} ${fldName} ${opLabel} ${operand}`,
          beforeScript: `{% if ${recKey}.${alias} ${op} ${operand} %}`,
          afterScript: "{% endif %}",
        };
      }
      conditions.push({
        id: `${recKey}.${cndKey}`,
        description: cndSpec.description ?? "",
        ...cndSpec,
      });
    }
    conditionCategories.push({
      type: "PREDEFINED",
      category,
      conditions,
    });
  }
  // #endregion
  return {
    mergeTags,
    conditionCategories,
  };
}

export async function duplicateTemplate<
  T extends MsgTemplate & Partial<DealerMsgTemplate>,
>(template: T, dealerId?: number) {
  const isDealer = "dealerId" in template;
  const resource = isDealer ? "dealer_msg_templates" : "msg_templates";
  const { json: result } = await fetchJson(
    `${REACT_APP_API_URL}/${resource}/${template.id}/duplicate`,
    {
      method: "POST",
      body: dealerId ? JSON.stringify({ dealerId }) : "{}",
    },
  );
  return result?.id ?? 0;
}

function findFieldByAlias(field: MsgRecord["field"], alias: string) {
  if (field) {
    for (const fldKey in field) {
      const fld = field[fldKey];
      if (alias === fldKey || alias === fld.alias) {
        return fld;
      }
    }
  }
  return undefined;
}

export async function initEmailEdit(id: number, isDealer?: boolean) {
  const resource = isDealer ? "dealer_msg_templates" : "msg_templates";
  const { json: data } = await fetchJson(
    `${REACT_APP_API_URL}/${resource}/${id}/email-edit`,
    {
      method: "POST",
      // CONSIDER: We could pass options here...
      body: "{}",
    },
  );
  const template = data as MsgTemplate;
  // #region Assets
  const { assetUrls = {}, email } = template;
  const assets = email?.assets ?? [];
  const downloadTasks: Promise<string | undefined>[] = [];
  function downloadIfExists(fileName: MsgTemplate.AssetFilenames) {
    downloadTasks.push(
      assets.includes(fileName)
        ? fetchText(assetUrls[fileName].get, {
            credentials: "omit",
          })
            .then(r => r.body)
            .catch(err => {
              console.error(
                `Downloading ${fileName} for template ${template.id}`,
                err,
              );
              return undefined;
            })
        : Promise.resolve(undefined),
    );
  }
  downloadIfExists(AssetFilenames.emailEditCss);
  downloadIfExists(AssetFilenames.emailEditHtml);
  // #endregion
  // Conditions and merge tags
  const { conditionCategories, mergeTags } =
    createMergeTagsAndConditions(template);
  // Await download tasks before exiting the function...
  // Stripo will use the default if html or css are undefined.
  const [css, html] = await Promise.all(downloadTasks);
  return {
    template,
    css,
    html,
    conditionCategories,
    mergeTags,
  };
}

export function replaceMsgTokens(
  templateInfo: MsgTemplate,
  html: string,
  options?: ReplaceMsgTokensOptions,
) {
  return tplEngine.parseAndRenderSync(
    html,
    buildMsgValues(templateInfo, options),
  );
}

export interface ReplaceMsgTokensOptions {
  /** Values from the database. e.g. {dealer:{name,phone,etc},cust:{...}} */
  dataValues?: MsgTemplate["dataValues"];
  /** User preview values, e.g. {cust:{fname,lname},user:{...}}. */
  previewValues?: MsgTemplate.EditorInfo["previewValues"];
}

export async function saveEmailEdit<
  T extends MsgTemplate & Partial<DealerMsgTemplate>,
>(
  template: T,
  values: Partial<MsgTemplate> &
    Record<MsgTemplate.AssetName, string> & { email: MsgTemplate.EmailInfo },
) {
  // console.log("saveEmailEdit", { template, values });
  const isDealer = "dealerId" in template;
  const resource = isDealer ? "dealer_msg_templates" : "msg_templates";
  const {
    // TEXT fields to save...
    emailAmp,
    emailHtml,
    emailEditHtml,
    emailEditCss,
    // JSON fields to save...
    email,
    spec,
    editor,
  } = values;
  // #region If TEXT fields exist, push to email.assets file.
  const { assets = [] } = email;
  const uploadTasks: Promise<boolean>[] = [];
  function uploadIfExists(fileName: MsgTemplate.AssetFilenames, body: string) {
    if (!body) {
      return;
    }
    const putUrl = template.assetUrls?.[fileName].put ?? "unknown";
    uploadTasks.push(
      FileUploader.upload(new File([body], fileName, { type: "text" }), putUrl)
        .then(({ status }) => {
          if (status >= 200 && status < 300) {
            if (!assets.includes(fileName)) assets.push(fileName);
            return true;
          }
          return false;
        })
        .catch(err => {
          console.log("Upload error", err);
          return false;
        }),
    );
  }
  uploadIfExists(AssetFilenames.emailAmp, emailAmp);
  uploadIfExists(AssetFilenames.emailHtml, emailHtml);
  uploadIfExists(AssetFilenames.emailEditCss, emailEditCss);
  uploadIfExists(AssetFilenames.emailEditHtml, emailEditHtml);
  let allUploadsCompleted = uploadTasks.length === 0;
  if (!allUploadsCompleted) {
    const results = await Promise.all(uploadTasks);
    allUploadsCompleted = results.every(r => r === true);
    email.assets = assets;
  }
  // #endregion
  // Save JSON fields...
  const { json: result } = await fetchJson(
    `${REACT_APP_API_URL}/${resource}/${template.id}/email-edit`,
    {
      method: "PUT",
      body: JSON.stringify({
        // TODO: If one of these is undefined, don't send it!
        email,
        spec,
        editor,
      }),
    },
  );
  // console.log("saveEmailEdit", result);
  if (!allUploadsCompleted) {
    window.alert(
      "There was an issue saving the document.\n" +
        "Please try again or contact support.",
    );
  }
  return result;
}

export async function sendPreviewEmail<
  T extends MsgTemplate & Partial<DealerMsgTemplate>,
>(
  template: T,
  values: {
    from?: { name?: string };
    to: { email?: string; name?: string };
    subject?: string;
    emailHtml?: string;
  },
) {
  const isDealer = "dealerId" in template;
  const resource = isDealer ? "dealer_msg_templates" : "msg_templates";
  const { json: result } = await fetchJson(
    `${REACT_APP_API_URL}/${resource}/${template.id}/preview/email`,
    {
      method: "POST",
      // CONSIDER: We can ask for a specific blank template to use here...
      body: JSON.stringify(values),
    },
  );
  return !!result?.ok;
}
export async function publishTemplate<
  T extends MsgTemplate & Partial<DealerMsgTemplate>,
>(template: T, values: Pick<MsgVersion, "summary">) {
  const isDealer = "dealerId" in template;
  const resource = isDealer ? "dealer_msg_templates" : "msg_templates";
  const { json: result } = await fetchJson(
    `${REACT_APP_API_URL}/${resource}/${template.id}/version`,
    {
      method: "POST",
      body: JSON.stringify(values),
    },
  );
  return !!result?.ok;
}
export async function republishVersion<
  T extends MsgTemplate & Partial<DealerMsgTemplate>,
>(template: T) {
  const isDealer = "dealerId" in template;
  const resource = isDealer ? "dealer_msg_versions" : "msg_versions";
  const { json: result } = await fetchJson(
    `${REACT_APP_API_URL}/${resource}/${template.id}/republish`,
    {
      method: "POST",
      body: JSON.stringify({ id: template.id }),
    },
  );
  return !!result?.ok;
}
export async function restoreVersion<
  T extends MsgTemplate & Partial<DealerMsgTemplate>,
>(template: T) {
  const isDealer = "dealerId" in template;
  const resource = isDealer ? "dealer_msg_versions" : "msg_versions";
  const { json: result } = await fetchJson(
    `${REACT_APP_API_URL}/${resource}/${template.id}/restore`,
    {
      method: "POST",
      body: JSON.stringify({ id: template.id }),
    },
  );
  return !!result?.ok;
}
