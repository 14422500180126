// Local
import { makeResource } from "../../react-admin";
// import { MsgTemplatePushCreate } from "./MsgTemplatePushCreate";
// import { MsgTemplatePushList, MsgTemplatePushIcon } from "./MsgTemplatePushList";
import { MsgTemplateIcon } from "../msg_templates/MsgTemplateList";
import { MsgTemplatePushEdit } from "./MsgTemplatePushEdit";
// import { MsgTemplatePushShow } from "./MsgTemplatePushShow";

export const MsgTemplatePush = makeResource({
  name: "msg_template_push",
  icon: MsgTemplateIcon,
  // list: MsgTemplatePushList,
  edit: MsgTemplatePushEdit,
  // show: MsgTemplatePushShow,
  // create: MsgTemplatePushCreate,
  options: {
    label: "Message template Push",
  },
});
