import React from "react";
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";
import {
  AutocompleteInput,
  Datagrid,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  // SelectInput,
  TextField,
  TextInput,
} from "react-admin";
// Local

export const DealerSettingIcon = SettingsApplicationsIcon;

const DealerSettingFilter = props => (
  <Filter {...props}>
    <ReferenceInput source="dealerId" reference="dealers" alwaysOn>
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <TextInput source="name" alwaysOn />
    {/* <TextInput source="description" alwaysOn /> */}
  </Filter>
);

const DealerSettingList = props => (
  <List
    {...props}
    filters={<DealerSettingFilter />}
    // filterDefaultValues={{ field: "value" }}
    sort={{ field: "dealerId", order: "ASC" }}
    bulkActionButtons={false}
    perPage={25}
  >
    <Datagrid optimized rowClick="edit">
      <TextField source="id" />
      <ReferenceField source="dealerId" reference="dealers">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="name" />
      <TextField source="value" />
      <TextField source="description" />
    </Datagrid>
  </List>
);

export default DealerSettingList;
