import React from "react";
import { NumberField } from "react-admin";

const options = {
  style: "percent",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};
/**
 * @param {import("react-admin").NumberFieldProps} props
 */
export function PercentField(props) {
  return <NumberField options={options} {...props} />;
}
PercentField.defaultProps = { ...NumberField.defaultProps };
