import React from "react";
import { useListContext } from "react-admin";
import { Button } from "@material-ui/core";
import ResetIcon from "@material-ui/icons/Close";

const styles = {
  button: {
    marginBottom: 12,
    marginLeft: -12,
  },
};

const ClearFilterButton = React.memo(() => {
  const { setFilters } = useListContext();
  const onClick = React.useCallback(
    /** @param {React.SyntheticEvent<HTMLElement>} e */
    e => {
      setFilters({});
    },
    [setFilters],
  );
  return (
    <Button
      // size="small"
      color="primary"
      onClick={onClick}
      startIcon={<ResetIcon />}
      style={styles.button}
      title="Reset filters"
    >
      Reset
    </Button>
  );
});

export function clearFilterButton() {
  return <ClearFilterButton alwaysOn source="__clear__filter__" />;
}
