import React from "react";
import { Box, Typography } from "@material-ui/core";
import {
  AutocompleteInput,
  ArrayInput,
  BooleanInput,
  DeleteWithConfirmButton,
  Edit,
  NumberInput,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  Toolbar,
} from "react-admin";
// Local
import { returnBlankString } from "../../lib";
import {
  AddItemButton,
  NonInput,
  RemoveItemButton,
  // SelectReferenceInput,
} from "../../components";
import { useHorizontalArrayFormStyle } from "../../react-admin";

export const offerTriggerTypes = [
  { id: "appsubmit", name: "Appraisal submitted" },
];

export const valuationTypes = [
  { id: "galves", name: "Galves" },
  { id: "mmr", name: "Manheim (MMR)" },
  { id: "jdp", name: "JD Powers" },
];

const EditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
    <NonInput>
      <div style={{ flex: 1 }} />
    </NonInput>
    <DeleteWithConfirmButton />
  </Toolbar>
);

export function DealerCampaignEdit(props) {
  return (
    <Edit mutationMode="pessimistic" {...props}>
      <DealerCampaignForm type="edit" toolbar={<EditToolbar />} />
    </Edit>
  );
}

/**
 * @typedef {import("react-admin").SimpleFormProps} SimpleFormProps
 * @typedef {object} SharedFormProps
 * @property {"create"|"edit"} type
 *
 * @param {SharedFormProps & SimpleFormProps} param0
 */
export function DealerCampaignForm({ type, ...formProps }) {
  const create = type === "create";
  const edit = !create;
  const horizontalFormClasses = useHorizontalArrayFormStyle();
  return (
    <SimpleForm redirect="list" {...formProps}>
      {/* <TextInput source="uid" disabled /> */}
      <ReferenceInput
        source="dealerId"
        reference="dealers"
        autoFocus={create}
        fullWidth
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <TextInput source="name" autoFocus={edit} fullWidth />
      <ReferenceInput
        source="typeId"
        reference="lists/dealer_campaign_types"
        allowEmpty
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <BooleanInput
        source="rules.appraisalsToCRM"
        label="Add new Appraisals to CRM"
        fullWidth
        defaultValue={true}
      />
      <ArrayInput source="rules.autoOffers" label="Automated offers">
        <SimpleFormIterator
          classes={horizontalFormClasses}
          addButton={<AddItemButton label="Add an automated offer." />}
          removeButton={<RemoveItemButton />}
          getItemLabel={returnBlankString}
          disableReordering
        >
          <SelectInput
            source="on"
            label="Trigger"
            className="ctrl-nowidth"
            choices={offerTriggerTypes}
            defaultValue="appsubmit"
          />
          <NumberInput
            source="p1"
            label="Offer %"
            className="ctrl-hwidth"
            defaultValue={90}
            title="Enter a percentage from 1 - 100."
          ></NumberInput>
          <SelectInput
            source="p1v"
            label="Valuation"
            className="ctrl-nowidth"
            choices={valuationTypes}
            defaultValue="mmr"
            title="Choose a valuation type for the Offer %."
          />
          <NumberInput
            source="p2"
            label="Upper %"
            className="ctrl-hwidth"
            title="Enter a percentage from 1 - 100."
          ></NumberInput>
          <SelectInput
            source="p2v"
            label="Upper valuation"
            className="ctrl-nowidth"
            choices={valuationTypes}
            allowEmpty
            title="Choose a valuation type for the Upper %."
          />
          <BooleanInput
            source="enabled"
            label="Enabled"
            className="ctrl-nowidth"
            defaultValue={true}
            title="Only enabled rules are processed. Disabled rules are ignored."
          />
        </SimpleFormIterator>
      </ArrayInput>
      <NonInput>
        <Box
          bgcolor="#feffe8"
          border="1px solid lightgrey"
          br="3px"
          mt="-6px"
          p="6px 12px"
        >
          <Typography component="div" variant="body2" color="textSecondary">
            Above: Add one automated offer per trigger type.
          </Typography>
        </Box>
      </NonInput>
    </SimpleForm>
  );
}
