import React from "react";
import {
  //
  List,
  Datagrid,
  ReferenceField,
  TextField,
} from "react-admin";
import ViewListIcon from "@material-ui/icons/ViewList";

export const MsgPartyIcon = ViewListIcon;

const defaultSort = { field: "name", order: "asc" };

export const MsgPartyList = props => (
  <List sort={defaultSort} {...props} title="Message parties">
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="description" />
      <ReferenceField
        source="tenantTypeId"
        reference="lists/tenant_types"
        label="Available to"
      >
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
);
