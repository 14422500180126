import React from "react";
import {
  Button,
  Dialog,
  DialogProps as MuiDialogProps,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  TextField,
  TextFieldProps,
  makeStyles,
} from "@material-ui/core";
// Icons
import CloseIcon from "@material-ui/icons/Close";
// Local
import { browserType } from "../../config";
import {
  selectAllTarget,
  // selectEndTarget,
} from "../../lib";

export interface BasicDialogParams {
  /** Element to display instead of `text`... */
  content?: React.ReactNode;
  /** The default value for dialog type `"prompt"`. */
  defaultValue?: string;
  /** True to show a multiline input box for dialog type `"prompt"`. */
  mulitline?: boolean;
  /** Text content of the dialog; used as the label for a prompt dialog. */
  text?: React.ReactNode;
  /** The `text` content alignment. */
  textAlign?: "center" | "left" | "right";
  /** Any text field props to apply, for `promptAsync`. */
  textFieldProps?: Partial<TextFieldProps>;
  /** Title of the dialog. */
  title?: React.ReactNode;
  /** The type of dialog. */
  type: "alert" | "base" | "confirm" | "prompt";
}

export type BasicDialogProps = Omit<MuiDialogProps, "onClose" | "title"> &
  BasicDialogParams & {
    onCompleted?: (result: any) => void;
  };

const defaultDialogTitle: Record<BasicDialogParams["type"], string> = {
  alert: "Alert",
  base: "",
  confirm: "Please confirm",
  prompt: "Input required",
};

const useStyles = makeStyles(
  theme => ({
    // prompt: browserType.iOS
    //   ? {
    //       // Align dialog top, for iOS
    //       // https://github.com/mui/material-ui/issues/23032#issuecomment-1086897223
    //       [`& .${dialogClasses.scrollPaper}`]: {
    //         alignItems: "flex-start",
    //         pt: 8,
    //       },
    //       [`& .${dialogClasses.paperScrollBody}`]: {
    //         verticalAlign: "top",
    //       },
    //     }
    //   : undefined,
    title: {
      textAlign: "center",
      padding: "8px 24px",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
      zIndex: theme.zIndex.modal + 100,
    },
    text: (props: BasicDialogProps) => ({
      padding: 12,
      textAlign: props.textAlign ?? "center",
    }),
    actions: { justifyContent: "center" },
    actionButton: { minWidth: 90 },
    cancelButton: {
      backgroundColor: "#EFF1FB",
      color: "#141932",
      minWidth: 90,
    },
  }),
  {
    classNamePrefix: "BasicDialog",
  },
);

export function BasicDialog(props: BasicDialogProps) {
  const {
    content: _content,
    defaultValue: _defaultValue,
    textAlign: _textAlign,

    mulitline,
    type: dialogType,
    open,
    onCompleted = () => {},
    text,
    title = defaultDialogTitle[dialogType],
    textFieldProps,
    ...rest
  } = props;
  const isBaseDialog = dialogType === "base";
  const isPrompt = dialogType === "prompt";
  let content = props.content;
  const defaultValue = props.defaultValue ?? (isPrompt ? "" : false);

  const classes = useStyles(props);

  const [value, setValue] = React.useState(defaultValue);

  const { onChange, onClose, onClickCancel } = React.useMemo(() => {
    return {
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
      },
      onClose: (event: any, reason?: "backdropClick" | "escapeKeyDown") => {
        if (reason === "backdropClick") {
          return;
        }
        onCompleted(defaultValue);
      },
      onClickCancel: (e: React.SyntheticEvent<HTMLButtonElement>) => {
        onCompleted(defaultValue);
      },
    };
  }, [defaultValue, onCompleted]);

  const { onClickOK, onKeyDown } = React.useMemo(() => {
    return {
      onClickOK: (e: React.SyntheticEvent<HTMLButtonElement>) => {
        onCompleted(isPrompt ? value : true);
      },
      onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
          // Prevent this event from bubbling so that onKeyUp doesn't trigger
          // whichever element will be focused after the dialog closes...
          if (e.preventDefault) {
            e.preventDefault();
          }
          if (e.stopPropagation) {
            e.stopPropagation();
          }
          onCompleted(value);
        }
      },
    };
  }, [value, isPrompt, onCompleted]);

  if (isPrompt) {
    content = (
      <TextField
        autoFocus={!browserType.iOS}
        fullWidth
        label={text}
        value={value}
        required
        onChange={onChange}
        // onFocus={selectAllTarget}
        onFocus={!browserType.iOS ? selectAllTarget : undefined}
        // onFocus={textFieldProps?.multiline ? selectEndTarget : selectAllTarget}
        onKeyDown={onKeyDown}
        {...(!mulitline
          ? {}
          : {
              multiline: true,
              minRows: 1,
              maxRows: 6,
            })}
        {...(textFieldProps as any)}
      />
    );
  }
  const hasContent = !!content;
  return (
    <Dialog
      // className={isPrompt ? classes.prompt : undefined}
      fullWidth
      open={open}
      onClose={onClose}
      aria-labelledby="basic-dialog-title"
      aria-describedby={"basic-dialog-content"}
      {...rest}
    >
      {title ? (
        <DialogTitle id="basic-dialog-title" className={classes.title}>
          {title}
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
            size="small"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
      ) : (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="small"
        >
          <CloseIcon />
        </IconButton>
      )}

      {isBaseDialog ? (
        React.isValidElement(content) ? (
          React.cloneElement(content as React.ReactElement<any>, {
            onClose,
            onCompleted,
          })
        ) : (
          content
        )
      ) : (
        <DialogContent id="basic-dialog-content">
          {hasContent ? (
            content
          ) : (
            <DialogContentText className={classes.text} id="basic-dialog-text">
              {text}
            </DialogContentText>
          )}
        </DialogContent>
      )}
      {!isBaseDialog && (
        <DialogActions className={classes.actions}>
          {dialogType !== "alert" && (
            <Button
              variant="contained"
              className={classes.cancelButton}
              onClick={onClickCancel}
            >
              Cancel
            </Button>
          )}
          <Button
            variant="contained"
            className={classes.actionButton}
            onClick={onClickOK}
            color="secondary"
          >
            OK
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
type BasicDialogResolver<T = unknown> = (result: T) => void;
/**
 * Hook to show the basic dialog types. Returns `alertAsync`, `confirmAsync` and
 * `promptAsync` functions which render the `basicDialog` element when called.
 * @example
 * const { confirmAsync, basicDialog } = useBasicDialog();
 * // Or { alertAsync or promptAsync ...
 * const doTheThing = React.useCallback(async () => {
 *   // Pass the text string or a {title,text} object.
 *   if (await confirmAsync("Do the thing?")) {
 *     doIt();
 *   }
 * }, [confirmAsync, doIt]);
 * return (
 *   <>
 *     <Button onClick={doTheThing}>Do the thing.</Button>
 *     {basicDialog}
 *   </>
 * );
 */
export function useBasicDialog() {
  const [isOpen, setOpen] = React.useState(false);
  const [params, setParams] = React.useState({} as BasicDialogParams);
  /**
   * Resolver for promise created in the `showAsync` function.
   * Used by the `onCompleted` function supplied to `BasicDialog` here.
   */
  const resolver = React.useRef<BasicDialogResolver<any>>();

  const {
    alertAsync,
    confirmAsync,
    promptAsync,
    showDialogAsync,
    onCompleted,
  } = React.useMemo(() => {
    /** Function to show dialog. */
    function showAsync<T>(params: BasicDialogParams): Promise<T> {
      return new Promise<T>((resolve, reject) => {
        setParams(params);
        resolver.current = resolve;
        // CONSIDER: We can save `reject` ref too if we want to raise errors.
        // Setting `isOpen` to true here causes `basicDialog` to render...
        setOpen(true);
      });
    }
    return {
      /** Function to show dialog. */
      alertAsync(
        textOrParams: string | Omit<BasicDialogParams, "type">,
      ): Promise<boolean> {
        return typeof textOrParams === "object"
          ? showAsync<boolean>({ ...textOrParams, type: "alert" })
          : showAsync<boolean>({ text: textOrParams, type: "alert" });
      },
      /** Function to show dialog and return the result. */
      confirmAsync(
        textOrParams: string | Omit<BasicDialogParams, "type">,
      ): Promise<boolean> {
        return typeof textOrParams === "object"
          ? showAsync<boolean>({ ...textOrParams, type: "confirm" })
          : showAsync<boolean>({ text: textOrParams, type: "confirm" });
      },
      /** Function to show dialog and return the result. */
      promptAsync(
        textOrParams: string | Omit<BasicDialogParams, "type">,
        defaultValue?: string,
      ): Promise<string> {
        return typeof textOrParams === "object"
          ? showAsync<string>({
              defaultValue,
              ...textOrParams,
              type: "prompt",
            })
          : showAsync<string>({
              defaultValue,
              text: textOrParams,
              type: "prompt",
            });
      },
      /** Function to show a base dialog with only a close button. */
      showDialogAsync(
        params: Omit<BasicDialogParams, "type"> & {
          content: BasicDialogParams["content"];
        } & Omit<MuiDialogProps, "onClose" | "title">,
      ): Promise<any> {
        return showAsync<any>({ ...params, type: "base" });
      },
      /** The dialog completion handler. */
      onCompleted(result: any) {
        setOpen(false);
        const resolve = resolver.current;
        resolver.current = undefined;
        if (resolve) {
          resolve(result);
        }
      },
    };
  }, []);
  /** The dialog element for the caller to render. */
  const basicDialog = React.useMemo(
    () =>
      // Don't even render the dialog if it's not open. What's the point?
      !isOpen ? null : (
        <BasicDialog onCompleted={onCompleted} open={isOpen} {...params} />
      ),
    [isOpen, onCompleted, params],
  );
  return {
    /** Function to show the dialog. */
    alertAsync,
    /** Dialog element to render or **`null` when closed.** */
    basicDialog,
    /** Function to show the confirmation dialog and return the result. */
    confirmAsync,
    /** Function to show the prompt dialog and return the result. */
    promptAsync,
    /** Function to show a base dialog with only a close button. */
    showDialogAsync,
  };
}
