import React from "react";
// import { RouteWithoutLayout } from "react-admin";
import { Route } from "react-router-dom";
// Local
import { MsgTemplateEditEmailPage } from "./msg-templates/MsgTemplateEditEmailPage";

// NOTE: Using RouteWithoutLayout or noLayout causes components to load twice.

export const customRoutes = [
  <Route
    exact
    path="/dealer_msg_templates/:id/email"
    component={MsgTemplateEditEmailPage}
    // noLayout
  />,
  <Route
    exact
    path="/msg_templates/:id/email"
    component={MsgTemplateEditEmailPage}
    // noLayout
  />,
];
