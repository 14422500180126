import React from "react";
import {
  AutocompleteInput,
  Create,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";

export const TenantCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source="origin" autoFocus />
      <ReferenceInput source="tenantTypeId" reference="lists/tenant_types">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput source="dealerId" reference="dealers" allowEmpty>
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);
