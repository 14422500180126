// Local
import { makeResource } from "../../react-admin";
import { DealerUserList, DealerUserIcon } from "./DealerUserList";
import { DealerUserEdit } from "./DealerUserEdit";
import { DealerUserShow } from "./DealerUserShow";
import { DealerUserCreate } from "./DealerUserCreate";

export const DealerUsers = makeResource({
  name: "dealer_users",
  icon: DealerUserIcon,
  list: DealerUserList,
  edit: DealerUserEdit,
  show: DealerUserShow,
  create: DealerUserCreate,
  options: {
    label: "Dealer users",
  },
});
