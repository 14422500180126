// Local
import { makeResource } from "../../react-admin";
import { ReportList, ReportIcon } from "./ReportList";
import { ReportEdit } from "./ReportEdit";
import { ReportShow } from "./ReportShow";
import { ReportCreate } from "./ReportCreate";

export const Reports = makeResource({
  name: "reports",
  icon: ReportIcon,
  list: ReportList,
  edit: ReportEdit,
  show: ReportShow,
  create: ReportCreate,
  options: {
    label: "Reports",
  },
});
