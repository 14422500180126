import React from "react";
import {
  //
  AutocompleteInput,
  Datagrid,
  DateField,
  // DateInput,
  Filter,
  List,
  ReferenceInput,
  ReferenceField,
  SelectInput,
  NumberInput,
  TextField,
  TextInput,
} from "react-admin";
import InfoIcon from "@material-ui/icons/Info";
// Local
import { DollarField, clearFilterButton } from "../../components";

export const AppraisalApiResultIcon = InfoIcon;

const Filters = props => (
  <Filter {...props}>
    <ReferenceInput source="dealerId" reference="dealers" alwaysOn>
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <NumberInput source="appraisalId" alwaysOn />
    <ReferenceInput
      source="apiTypeId"
      reference="lists/appraisal_api_types"
      alwaysOn
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <TextInput source="vin" alwaysOn />
    <TextInput source="action" alwaysOn />
    {/* <DateInput source="createdAt_gte" label="From date" />
    <DateInput source="createdAt_lte" label="To date" /> */}
    {clearFilterButton()}
  </Filter>
);

export const AppraisalApiResultList = props => (
  <List
    {...props}
    filters={<Filters />}
    perPage={10}
    sort={{ field: "id", order: "DESC" }}
  >
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="dealerId" />
      <TextField source="appraisalId" />
      {/* <TextField source="apiTypeId" /> */}
      <ReferenceField source="apiTypeId" reference="lists/appraisal_api_types">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="vin" />
      <TextField source="action" />
      <TextField source="sessionKey" />
      <DollarField source="value" />

      <DateField source="createdAt" showTime />
      {/* <ReferenceField source="statusId" reference="lists/saas_lead_statuses">
        <TextField source="name" />
      </ReferenceField> */}
    </Datagrid>
  </List>
);
