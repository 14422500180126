import React from "react";
import {
  AutocompleteInput,
  Create,
  ReferenceInput,
  SaveButton,
  SimpleForm,
  Toolbar,
} from "react-admin";
// Local
// import { SelectReferenceInput } from "../../components";

const EditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const DealerLinkageCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="list" toolbar={<EditToolbar />}>
      <ReferenceInput source="fromId" reference="dealers" autoFocus fullWidth>
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput source="toId" reference="dealers" autoFocus fullWidth>
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export default DealerLinkageCreate;
