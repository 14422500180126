// Local
import { makeResource } from "../../react-admin";
import { BankList, BankIcon } from "./BankList";
import { BankEdit } from "./BankEdit";
import { BankShow } from "./BankShow";
import { BankCreate } from "./BankCreate";

export const Banks = makeResource({
  name: "banks",
  icon: BankIcon,
  list: BankList,
  edit: BankEdit,
  show: BankShow,
  create: BankCreate,
  options: {
    label: "Banks",
  },
});
