/** The type of party sending or receiving a message. */
export enum MsgParty {
  // NOTE: Always add to the END of a section! Do not sort after release!

  /** The Admin system. */
  admins = 1001,
  /** Admin user. */
  admin_user,

  // NOTE: Always add to the END of a section! Do not sort after release!

  /** The SaaS system. */
  saas = 2001,
  /** Prospective SaaS customer. */
  saas_prospect,
  /** SaaS customer (dealership). */
  saas_customer,

  // NOTE: Always add to the END of a section! Do not sort after release!

  /** The dealership. */
  dealer = 3001,
  /** Dealership user. */
  user,
  /** Prospective dealer appraisal customer. */
  appraisal_prospect,
  /** Dealer appraisal customer. */
  appraisal_customer,
  /** Dealer appraisal vehicle inspector (customer). */
  appraisal_inspector,
}
// #region Message Variables, Conditions

/** Specification for single display condition. */
export interface MsgCondition {
  /** Display name. */
  name: string;
  /** Descriptive summary. */
  description?: string;
  /** End script, e.g. `{% endif %}` */
  afterScript: string;
  /** Begin script, e.g. `{% if field.name != blank %}` */
  beforeScript: string;
}
export namespace MsgCondition {
  /** Operators we can use in our liquidjs template logic. */
  export type Op = "==" | "!=" | ">" | "<" | ">=" | "<=";

  /** Info about liquidjs operators. */
  export const opLabel: Partial<Record<Op, string>> = {
    "==": "is",
    "!=": "not",
  };
  /** Special value symbols in liquidjs, for use in conditional operations. */
  export const literalVals = [
    "blank",
    "empty",
    "null",
    "true",
    "false",
  ] as const;
  /** Type union of {@link literalVals}. */
  export type LiteralVal = typeof literalVals[number];
}
/** Type names for {@link MsgRuleValue} and {@link MsgRuleInfo.type} */
export type MsgRuleType = "boolean" | "number" | "string";
/** Specification for a rule to match a given message template to send. */
export interface MsgRuleInfo {
  /** Data type of the rule. */
  type: MsgRuleType;
  /** Display name of the rule. */
  name: string;
  /** Help text. */
  help?: string;
  /** Items that can be assigned to this rule. */
  choices?: MsgRuleValue[];
  /** A default value for the rule. */
  value?: MsgRuleValue;
}
/** Type of value chosen by message author for rule implementation. */
export type MsgRuleValue = boolean | number | string;

/** Specification for a group of message variables. */
export interface MsgRecord<F extends string = string> {
  /** {@link MsgVar} field by key. */
  field: Record<F, MsgVar>;
  /** Used to group similar vars under one object. */
  name: string;
  /** Table to lookup a record **for previews only at this time**. */
  table?: string;
}
/** Specification for a single message variable. */
export interface MsgVar {
  /** Name to expose this field as. */
  alias?: string;
  /** The text to show to users when choosing a variable. */
  name: string;
  /**
   * For use in custom vars, to indicate that the sender should be prompted
   * to fill in the value for this variable.
   */
  prompt?: string;
  /** A sample value that will be produced by the `code`. */
  sample?: any;
  /** Additional description of the variable. */
  summary?: string;
  /** A default value for the template token, e.g. `{{my.val=Default val}}` */
  value?: string;
}
// #endregion
// #region Message Types

/** Static message type details. Not stored in database. */
export interface MsgTypeInfo {
  /**
   * Predefined conditions for this type of message by record and condition e.g.
   * @example
   * // conditions:
   * {
   *   cust: {
   *     hasName: MsgConditions.cust.hasName,
   *     noName: MsgConditions.cust.noName,
   *   },
   *   dealer: MsgConditions.dealer,
   * }
   */
  conditions?: Record<
    string,
    Record<
      string,
      MsgCondition | Array<any>
      // NOTE: The following won't work with react-scripts v3:
      // Field name, operator, literal
      // | [string, MsgCondition.Op, MsgCondition.LiteralVal]
      // // Field name, operator, value
      // | [string, MsgCondition.Op, any]
    > & {
      /**
       * Reserved prop for PredefinedCategory name in Stripo.
       * Use when your conditions do not match a `vars[key]` record spec...
       */ category?: string;
    }
  >;
  /** Set to the original mailjet template id that we're replacing... */
  originalMailjetTemplateId?: string;
  /** Var specifications organized by record. */
  vars: Record<string, MsgRecord>;
}
// #endregion
// #region Message Templates

/** Editable message template. */
export interface MsgTemplate {
  // #region Fields

  id: number;
  uid: string;
  /** The type of message that this template is for. */
  msgTypeId: number;

  // Details

  /** Name of the template. */
  name: string;
  /** Summary of what the template is used for. */
  description: string;

  // Auditing, Timestamps

  createdBy: number;
  updatedBy: number;
  createdAt: Date | string | number;
  updatedAt: Date | string | number;

  // Soft delete

  deletedBy?: number;
  deletedAt?: Date | string | number;

  // Published Status

  /** True if there have been changes since the last version. */
  hasChanges?: boolean;
  /** Id of the last MsgVersion to be published. */
  lastVersionId?: number;

  // Matching: Optional

  /**
   * True if this is the default template for the `msgTypeId`. Multiple
   * templates may be the default for a `msgTypeId` and they will be chosen
   * for usage on the basis of values in
   * `fromPartyId, toPartyId, email.enamed, push.enabled and sms.enabled`.
   */
  isDefault?: boolean | number;
  fromPartyId?: number;
  toPartyId?: number;

  // Content

  /** Email info. */
  email?: MsgTemplate.EmailInfo;
  /** Push notification info. */
  push?: MsgTemplate.PushInfo;
  /** SMS info. */
  sms?: MsgTemplate.SmsInfo;

  // Metadata

  /** Custom template specifications for message variables, etc. */
  spec?: MsgTemplate.Spec;
  /** Information used by email, sms and push editors. */
  editor?: MsgTemplate.EditorInfo;

  // #endregion

  // #region Virtual Fields

  /** {@link FileStore} urls for GET and PUT. */
  assetUrls?: Record<MsgTemplate.AssetFilenames, MsgTemplate.AssetUrls>;
  /** Db values for previews, e.g. `{dealer:{name,phone,etc},cust:{...}}`. */
  dataValues?: Record<string, any>;
  /** Message variables and other type info. */
  typeInfo?: MsgTypeInfo;

  // #endregion
}
export namespace MsgTemplate {
  /**
   * Names of asset files in a `DigitalOcean-Space/msg_templates/{id}/`
   * or `Space/msg_versions/{id}/` for published versions.
   *
   * `DealerMsg*` model files are at `Space/dealers/{id}/msg_templates/{id}/`
   * or `Space/dealers/{id}/msg_versions/{id}/` for published versions.
   */
  export enum AssetFilenames {
    /** The compiled email HTML for Google AMP emails. */
    emailAmp = "email.amp",
    /** The editable email CSS. */
    emailEditCss = "editEmail.css",
    /** The editable email HTML. */
    emailEditHtml = "editEmail.html",
    /** The compiled email HTML. */
    emailHtml = "email.html",
  }
  export type AssetName = keyof typeof AssetFilenames;
  export interface AssetUrls {
    get: string;
    put: string;
  }
  /** Email info. */
  export interface EmailInfo {
    /** True if sending email for this template is enabled. */
    enabled: boolean;
    /** Text pattern including {var.tokens}. */
    subject: string;
    /** Options for the email `From` header. */
    from?: FromEmailInfo;
    /** Options for the email `To` header. */
    to?: ToEmailInfo;
    /** Height of the email in pixels. */
    height?: number;
    /** Width of the email in pixels. */
    width?: number;
    /**
     * **A filename should only exist in this array if it has already been
     * uploaded to the {@link FileStore}.**
     *
     * Names of asset files in a `DigitalOcean-Space/msg_templates/{id}/`
     * or `Space/msg_versions/{id}/` for published versions.
     *
     * `DealerMsg*` model files are at `Space/dealers/{id}/msg_templates/{id}/`
     * or `Space/dealers/{id}/msg_versions/{id}/` for published versions.
     */
    assets?: AssetFilenames[];
  }
  /** Details of email `From` header. */
  export interface FromEmailInfo {
    /** Name of the sender. // TODO: allow tokens `{{dealer.name}}`, etc. */
    name: string;
  }
  /** Details of email `To` header. */
  export interface ToEmailInfo {
    /** Name of the sender. // TODO: allow tokens `{{cust.fname}}`, etc. */
    name?: string;
  }
  /** Information used by email, sms and push editors. */
  export interface EditorInfo {
    /**
     * Record values for previews, e.g. `{dealer:{name,phone,etc},cust:{...}}`
     * OR an id (numeric/string) which can be used to lookup a record according
     * to the {@link MsgTypeInfo.vars} record specification.
     */
    previewValues?: Record<string, number | string | Record<string, any>>;
  }
  /** Push notification info. */
  export interface PushInfo {
    /** True if sending a push notification for this template is enabled. */
    enabled: boolean;
    /** Text pattern including {var.tokens}. */
    text: string;
    /** Text pattern including {var.tokens}. */
    title: string;
  }
  /** Custom template specifications for message variables, etc. */
  export interface Spec {
    /** Criteria for sending this message. */
    rules?: Record<string, MsgRuleValue>;
    /**
     * Vars by name for special record `msg`. The editor will save
     * `{ note: {name: "Note",value="Thanks!"} }` in order to enable custom
     * code in the template, e.g. `{{msg.note|or:"Thanks!"}}`.
     */
    vars: Record<string, MsgVar>;
  }
  /** SMS info. */
  export interface SmsInfo {
    /** True if sending an SMS for this template is enabled. */
    enabled: boolean;

    // from: FromSmsInfo, // { number?: string } a number reg'd with Twilio...

    /** Text pattern including {var.tokens}. */
    text: string;
  }
}
/** Editable message template. */
export interface DealerMsgTemplate {
  // #region Fields

  id: number;
  uid: string;
  dealerId: number;
  /** The type of message that this template is for. */
  msgTypeId: number;

  // Details

  /** Name of the template. */
  name: string;
  /** Summary of what the template is used for. */
  description: string;

  // Auditing, Timestamps

  createdBy: number;
  updatedBy: number;
  createdAt: Date | string | number;
  updatedAt: Date | string | number;

  // Soft delete

  deletedBy?: number;
  deletedAt?: Date | string | number;

  // Published Status

  /** True if there have been changes since the last version. */
  hasChanges?: boolean;
  /** Id of the last MsgVersion to be published. */
  lastVersionId?: number;

  // Matching: Optional

  /**
   * True if this is the default template for the `msgTypeId`. Multiple
   * templates may be the default for a `msgTypeId` and they will be chosen
   * for usage on the basis of values in
   * `fromPartyId, toPartyId, email.enamed, push.enabled and sms.enabled`.
   */
  isDefault?: boolean | number;
  fromPartyId?: number;
  toPartyId?: number;

  // Content

  /** Email info. */
  email?: MsgTemplate.EmailInfo;
  /** Push notification info. */
  push?: MsgTemplate.PushInfo;
  /** SMS info. */
  sms?: MsgTemplate.SmsInfo;

  // Metadata

  /** Custom template specifications for message variables, etc. */
  spec?: MsgTemplate.Spec;
  /** Information used by email, sms and push editors. */
  editor?: MsgTemplate.EditorInfo;

  // #endregion

  // #region Virtual Fields

  /** {@link FileStore} urls for GET and PUT. */
  assetUrls?: Record<MsgTemplate.AssetFilenames, MsgTemplate.AssetUrls>;
  /** Db values for previews, e.g. `{dealer:{name,phone,etc},cust:{...}}`. */
  dataValues?: Record<string, any>;
  /** Message variables and other type info. */
  typeInfo?: MsgTypeInfo;

  // #endregion
}
// #endregion
// #region Message Versions, Publishing

/** Published message version (current). */
export interface MsgPub {
  id: number;

  versionId: number;
  msgTypeId: number;
}

/** Published message version (historical). */
export interface MsgVersion {
  id: number;
  templateId: number;

  publishedBy: number;
  publishedAt: Date | string | number;
  summary: string;

  // #region Template Fields

  /** The type of message that this template is for. */
  msgTypeId: number;

  // Details

  /** Name of the template. */
  name: string;
  /** Summary of what the template is used for. */
  description: string;

  // Auditing, Timestamps

  createdBy: number;
  updatedBy: number;
  createdAt: Date | string | number;
  updatedAt: Date | string | number;

  // Matching: Optional

  /**
   * True if this is the default template for the `msgTypeId`. Multiple
   * templates may be the default for a `msgTypeId` and they will be chosen
   * for usage on the basis of values in
   * `fromPartyId, toPartyId, email.enamed, push.enabled and sms.enabled`.
   */
  isDefault?: boolean | number;
  fromPartyId?: number;
  toPartyId?: number;

  // Content

  /** Email info. */
  email?: MsgTemplate.EmailInfo;
  /** Push notification info. */
  push?: MsgTemplate.PushInfo;
  /** SMS info. */
  sms?: MsgTemplate.SmsInfo;

  // Metadata

  /** Custom template specifications for message variables, etc. */
  spec?: MsgTemplate.Spec;
  /** Information used by email, sms and push editors. */
  editor?: MsgTemplate.EditorInfo;

  // #endregion
}

/** Published message version (current). */
export interface DealerMsgPub {
  id: number;

  dealerId: number;
  versionId: number;
  msgTypeId: number;
}

/** Published message version (historical). */
export interface DealerMsgVersion {
  id: number;
  dealerId: number;
  templateId: number;

  publishedBy: number;
  publishedAt: Date | string | number;
  summary: string;

  // #region Template Fields

  /** The type of message that this template is for. */
  msgTypeId: number;

  // Details

  /** Name of the template. */
  name: string;
  /** Summary of what the template is used for. */
  description: string;

  // Auditing, Timestamps

  createdBy: number;
  updatedBy: number;
  createdAt: Date | string | number;
  updatedAt: Date | string | number;

  // Matching: Optional

  /**
   * True if this is the default template for the `msgTypeId`. Multiple
   * templates may be the default for a `msgTypeId` and they will be chosen
   * for usage on the basis of values in
   * `fromPartyId, toPartyId, email.enamed, push.enabled and sms.enabled`.
   */
  isDefault?: boolean | number;
  fromPartyId?: number;
  toPartyId?: number;

  // Content

  /** Email info. */
  email?: MsgTemplate.EmailInfo;
  /** Push notification info. */
  push?: MsgTemplate.PushInfo;
  /** SMS info. */
  sms?: MsgTemplate.SmsInfo;

  // Metadata

  /** Custom template specifications for message variables, etc. */
  spec?: MsgTemplate.Spec;
  /** Information used by email, sms and push editors. */
  editor?: MsgTemplate.EditorInfo;

  // #endregion
}
// #endregion
