import React from "react";

export const NonField = React.memo(
  /**
   * Removes react-admin field props, so you can add non-field items inside of
   * a `SimpleShow` component.
   */
  function NonField({ children }) {
    return children;
  },
);
