// Local
import { makeResource } from "../../react-admin";
import { MsgTemplateCreate } from "./MsgTemplateCreate";
import { MsgTemplateList, MsgTemplateIcon } from "./MsgTemplateList";
import { MsgTemplateEdit } from "./MsgTemplateEdit";
import { MsgTemplateShow } from "./MsgTemplateShow";

export const MsgTemplates = makeResource({
  name: "msg_templates",
  icon: MsgTemplateIcon,
  list: MsgTemplateList,
  edit: MsgTemplateEdit,
  show: MsgTemplateShow,
  create: MsgTemplateCreate,
  options: {
    label: "Message templates",
  },
});
