import React from "react";
import { NullableBooleanInput, NumberInput, TextInput } from "react-admin";
import { IconButton, InputAdornment } from "@material-ui/core";
// import { get } from "lodash";
import { useForm } from "react-final-form";
// Icons
import ResetIcon from "@material-ui/icons/RotateLeft";

export const reportParamTypes = [
  { id: "boolean", name: "Boolean" },
  { id: "number", name: "Number" },
  { id: "string", name: "String/Text" },
];

export const reportParamDefaultValues = {
  boolean: false,
  number: 0,
  string: "",
};

// export const ReportParamInput = React.memo(
/**
 * See also: `SettingInput` in src/components/inputs/.
 * @typedef {import("react-admin").TextInputProps} TextInputProps
 * @typedef {import("react-admin").NumberInputProps} NumberInputProps
 *
 * @param {NumberInputProps & TextInputProps} param0
 */
export function ReportParamInput({ record = {}, source, ...props }) {
  // console.log("ReportParamInput", { record, source, ...props });
  // Example source: "spec.params[0].value"

  const { defaultsTo, name, type } = record;

  const { change } = useForm();

  // #region For real-time switching when type changes...
  // const parentSource = source.substring(0, source.lastIndexOf(".value"));
  // const { change, getState } = useForm();
  // const { values } = getState();
  // // console.log("VALUES", values);
  // const type = get(values, `${parentSource}.type`);
  // React.useEffect(() => {
  //   // console.log("TYPE", type);
  //   change(source, reportParamDefaultValues[type] ?? "");
  // }, [type, source, change]);
  // #endregion

  const onClickReset = React.useCallback(
    /** @param {React.SyntheticEvent<HTMLElement>} e */
    e => {
      const value =
        type === "boolean"
          ? defaultsTo === "true"
          : type === "number"
          ? parseFloat(defaultsTo) || 0
          : defaultsTo;
      change(source, value);
    },
    [defaultsTo, type, source, change],
  );

  const paramInputProps = React.useMemo(
    /** @returns {import("@material-ui/core").InputProps | undefined} */
    () =>
      defaultsTo === undefined
        ? undefined
        : {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={onClickReset}
                  title={`Reset to default of: ${defaultsTo}`}
                >
                  <ResetIcon />
                </IconButton>
              </InputAdornment>
            ),
          },
    [defaultsTo, onClickReset],
  );

  // NOTE: Setting `defaultValue` props on the inputs below results in poor UX.
  // When you try to remove all text in a TextInput, it won't let you.

  return type === "boolean" ? (
    <NullableBooleanInput
      record={record}
      source={source}
      {...props}
      InputProps={paramInputProps}
      label={name}
    />
  ) : type === "number" ? (
    <NumberInput
      record={record}
      source={source}
      {...props}
      InputProps={paramInputProps}
      label={name}
    />
  ) : (
    <TextInput
      record={record}
      source={source}
      {...props}
      InputProps={paramInputProps}
      label={name}
    />
  );
}
//   ,
// );
