import React from "react";
import DonutSmallIcon from "@material-ui/icons/DonutSmall";
import {
  AutocompleteInput,
  Datagrid,
  DateField,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  // SelectInput,
  TextField,
  TextInput,
} from "react-admin";
// Local

export const DealerCampaignIcon = DonutSmallIcon;

const DealerCampaignFilter = props => (
  <Filter {...props}>
    <ReferenceInput source="dealerId" reference="dealers" alwaysOn>
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <TextInput source="name" alwaysOn />
  </Filter>
);

export const DealerCampaignList = props => (
  <List
    {...props}
    filters={<DealerCampaignFilter />}
    // filterDefaultValues={{ field: "value" }}
    sort={{ field: "id", order: "DESC" }}
    bulkActionButtons={false}
    perPage={25}
  >
    <Datagrid optimized rowClick="show">
      <TextField source="id" />
      <ReferenceField source="dealerId" reference="dealers">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="name" />
      <ReferenceField source="typeId" reference="lists/dealer_campaign_types">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
      <DateField source="deletedAt" showTime />
      <TextField source="uid" />
    </Datagrid>
  </List>
);
