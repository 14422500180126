// Local
import { makeResource } from "../../react-admin";
import { TenantList, TenantIcon } from "./TenantList";
import { TenantEdit } from "./TenantEdit";
import { TenantShow } from "./TenantShow";
import { TenantCreate } from "./TenantCreate";

export const Tenants = makeResource({
  name: "tenants",
  icon: TenantIcon,
  list: TenantList,
  edit: TenantEdit,
  show: TenantShow,
  create: TenantCreate,
  options: {
    label: "Tenants",
  },
});
