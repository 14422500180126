import React from "react";
import {
  //
  AutocompleteInput,
  Datagrid,
  // DateField,
  // DateInput,
  Filter,
  List,
  ReferenceInput,
  ReferenceField,
  SelectInput,
  TextField,
  BooleanInput,
  // TextInput,
} from "react-admin";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
// Local
import {
  //
  DollarField,
  PercentField,
  ThousandsField,
  clearFilterButton,
} from "../../components";

export const SaasStatIcon = TrendingUpIcon;

const Filters = props => (
  <Filter {...props}>
    <ReferenceInput source="dealerId" reference="dealers" alwaysOn>
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      source="appraisalTypeId"
      reference="lists/appraisal_types"
      alwaysOn
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      source="campaignId"
      reference="dealer_campaigns"
      filterToQuery={searchText => ({ name: searchText })}
      alwaysOn
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <SelectInput
      alwaysOn
      source="from"
      choices={[
        { id: "7d", name: "Past week" },
        { id: "30d", name: "Past 30 days" },
        { id: "60d", name: "Past 60 days" },
        { id: "90d", name: "Past 90 days" },
        { id: "183d", name: "Past 6 months" },
        { id: "365d", name: "Past year" },
      ]}
    />
    <BooleanInput source="deleted" label="Include archived" alwaysOn />
    {clearFilterButton()}
  </Filter>
);

export const SaasStatList = props => (
  <List
    {...props}
    filters={<Filters />}
    perPage={25}
    filterDefaultValues={{ from: "30d", deleted: true }}
    sort={{ field: "id", order: "ASC" }}
  >
    <Datagrid rowClick="show">
      <ReferenceField source="dealerId" reference="dealers">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="appraisalTypeId"
        reference="lists/appraisal_types"
      >
        <TextField source="name" />
      </ReferenceField>
      <ThousandsField source="totalSubmitted" />
      <ThousandsField source="totalInspected" />
      <PercentField source="totalInspectedPct" title="of submitted" />
      <ThousandsField source="totalOfferSent" />
      <PercentField source="totalOfferSentPct" title="of submitted" />
      <ThousandsField source="totalOfferAccepted" />
      <PercentField source="totalOfferAcceptedPct" title="of sent" />
      <ThousandsField source="totalBuying" />
      <PercentField source="totalBuyingPct" title="of submitted or inspected" />
      <PercentField source="totalConvertedPct" title="of submitted" />
      <ThousandsField source="totalGrounding" />
      <PercentField source="totalGroundingPct" title="of inspected" />
      <DollarField source="totalExpectedProfit" />
      <DollarField source="totalExpectedProfitAvg" />
    </Datagrid>
  </List>
);
