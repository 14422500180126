// Local
import { makeResource } from "../../react-admin";
import { TwilioMessageList, TwilioMessageIcon } from "./TwilioMessageList";
import { TwilioMessageShow } from "./TwilioMessageShow";

export const TwilioMessages = makeResource({
  name: "twilio_messages",
  icon: TwilioMessageIcon,
  list: TwilioMessageList,
  // edit: TwilioMessageEdit,
  show: TwilioMessageShow,
  // create: TwilioMessageCreate,
  options: {
    label: "Twilio Messages",
  },
});
