import React from "react";
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  TextField,
  makeStyles,
} from "@material-ui/core";
// Local
import { browserType } from "../../config";
import { selectAllTarget } from "../../lib";

const useStyles = makeStyles(
  theme => ({
    centered: {
      textAlign: "center",
    },
    actions: {
      justifyContent: "space-around",
    },
  }),
  {
    classNamePrefix: "MsgTemplateEditEmailForm",
  },
);

export const MsgTemplateEditEmailForm = React.memo(
  /**
   * @typedef {import("../../resources/msg_templates/types").MsgTemplate} MsgTemplate
   * @typedef {import("../../resources/msg_templates/types").MsgTemplate.EmailInfo} EmailInfo
   * @param {{data:EmailInfo,onClose:()=>void,onCompleted(result:any)=>void}} param0
   */
  function MsgTemplateEditEmailForm({ data, onClose, onCompleted }) {
    const classes = useStyles();

    const onClickCancel = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      e => {
        onCompleted(false);
      },
      [onCompleted],
    );

    const onSubmit = React.useCallback(
      /** @param {React.FormEvent<HTMLFormElement>} e */
      e => {
        e.preventDefault();
        const elements = e.currentTarget.elements;
        /** @type {import("../../resources/msg_templates/types").MsgTemplate.EmailInfo} */
        const values = {
          subject: ("" + elements["subject"].value).trim(),
          enabled: elements["enabled"].checked,
        };
        const senderName = ("" + elements["senderName"].value).trim();
        if (senderName) {
          values.from = { name: senderName };
        }
        // console.log("SUBMIT", values);
        onCompleted(values);
      },
      [onCompleted],
    );

    return (
      <form onSubmit={onSubmit}>
        <DialogContent id="basic-dialog-content">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Subject"
                name="subject"
                defaultValue={data?.subject ?? ""}
                fullWidth
                autoFocus={!browserType.iOS}
                onFocus={!browserType.iOS ? selectAllTarget : undefined}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="From"
                name="senderName"
                defaultValue={data?.from?.name ?? ""}
                fullWidth
                onFocus={!browserType.iOS ? selectAllTarget : undefined}
                // TODO: Also create a "To" email name input...
              />
            </Grid>
            <Grid item xs={12} className={classes.centered}>
              <FormControlLabel
                label="Enable email for sending when published."
                control={
                  <Checkbox
                    name="enabled"
                    defaultChecked={data?.enabled ?? false}
                  />
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button
            variant="contained"
            // className={classes.cancelButton}
            onClick={onClickCancel}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            variant="contained"
            // className={classes.actionButton}
            // onClick={onClickOK}
            color="secondary"
          >
            OK
          </Button>
        </DialogActions>
      </form>
    );
  },
);
