import React from "react";
import { DateField, Show, SimpleShowLayout, TextField } from "react-admin";

export const EmailTemplateShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="description" />
      <DateField source="last_updated_at" showTime />
      <DateField source="created_at" showTime />
      <TextField source="purposes" />
    </SimpleShowLayout>
  </Show>
);
