// Local
import DealerBankCreate from "./DealerBankCreate";
import DealerBankEdit from "./DealerBankEdit";
import DealerBankList, { DealerBankIcon } from "./DealerBankList";

export const DealerBanks = {
  name: "dealer_banks",
  create: DealerBankCreate,
  list: DealerBankList,
  edit: DealerBankEdit,
  icon: DealerBankIcon,
  options: {
    label: "Dealer banks",
  },
};
