import { makeStyles } from "@material-ui/core";

const fullWidthFields =
  "& .MuiTextField-root" +
  ":not(.MuiFormControl-fullWidth)" +
  ":not(.ctrl-hwidth)" +
  ":not(.ctrl-nowidth)";

/** Creates overrides for the SimpleFormIterator classes prop.  */
export const useHorizontalArrayFormStyle = makeStyles(() => ({
  form: {
    // Make the form horizontal...
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flex: "unset",
    "& .ra-input": {
      marginRight: 4,
      // display: "flex",
      // alignItems: "center",
    },
    [fullWidthFields]: {
      // Make the all MuiTextField based inputs the same width...
      width: 256,
    },
    "& .ctrl-hwidth": {
      // Half width
      width: 128,
    },
    "& .ctrl-nowidth": {
      width: "unset",
    },
  },
  action: {
    "& .button-remove": {
      // Center the remove button vertically with the inputs...
      marginTop: 10,
    },
  },
  line: {
    border: 0,
  },
}));
